import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'snap-no-internet',
  templateUrl: './no-internet.component.html',
  styleUrls: ['./no-internet.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoInternetComponent {
  constructor(private router: Router) {}

  reload() {
    /* const currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
    this.router.onSameUrlNavigation = 'ignore'; */
    window.location.reload();
  }
}
