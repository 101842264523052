<nz-row class="container" nzGutter="16" nzJustify="center">
  <nz-col nzFlex="1 1 auto">
    <div *ngIf="routeType !== 'firstPasswordSetup'">
      <div nz-row>
        <div>
          <a class="backLink" [routerLink]="['/auth/login']">
            <i nz-icon nzType="arrow-left" nzTheme="outline"></i>
            Back to Login
          </a>
        </div>
      </div>
    </div>
  </nz-col>
  <nz-col nzXXl="8" nzXl="10" nzMd="14" nzSm="24" nzXs="24" nzAlign="middle">
    <nz-card cssOverride [nzTitle]="titleTpl">
      <form
        nz-form
        [formGroup]="resetPasswordForm"
        class="resetForm"
        (ngSubmit)="resetUserPassword()">
        <ng-container *ngIf="routeType !== 'firstPasswordSetup'">
          <div nz-row class="headingDiv">
            <p class="headingDivText">Verify OTP</p>
            <span class="subHeading"
              >We have sent a one-time passcode to {{ username }}</span
            >
            <nz-form-item class="otp-form-control">
              <nz-form-control [nzErrorTip]="otpErrorTpl">
                <input
                  placeholder="Enter OTP"
                  formControlName="otp"
                  [hidden]="true" />
                <code-input
                  (codeChanged)="onCodeChanged($event)"
                  (codeCompleted)="onCodeCompleted($event)">
                </code-input>
                <ng-template #otpErrorTpl let-control>
                  <ng-container *ngIf="control.hasError('required')"
                    >Please enter OTP!</ng-container
                  >
                  <ng-container *ngIf="control.hasError('customError')">{{
                    customErrMsg
                  }}</ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
            <div nz-row [nzGutter]="16" class="otpInfoTxt">
              <div nz-col class="gutter-row colorGrey" [nzSpan]="12">
                Didn't receive the OTP?
              </div>
              <div nz-col class="gutter-row" [nzSpan]="12">
                <span class="float-right">
                  <span class="colorGrey">In</span
                  ><b> 00:{{ timeLeft | number: '2.0-0' }}</b
                  >,
                  <span class="colorGrey" *ngIf="timeLeft > 0">
                    Resend OTP</span
                  >
                  <span class="colorGrey" *ngIf="timeLeft === 0">
                    <a nz-typography (click)="resendOTP()"> Resend OTP</a>
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div nz-row class="headingDiv">
            <p class="headingDivText">Reset Password</p>
          </div>
        </ng-container>
        <ng-container *ngIf="routeType === 'firstPasswordSetup'">
          <div nz-row>
            <span class="clientName">Client Name</span>
          </div>
          <div nz-row class="headingDiv">
            <p class="headingDivText">Password Setup</p>
            <span class="subHeading"
              >You are logging in for the first time, please create a password
              to access your account.</span
            >
          </div>
        </ng-container>
        <div nz-row class="infoTxt">
          <div>To keep your account secure, make sure your password:</div>
          <div>
            <ul>
              <li [style.color]="getErrorColor('minlength', 'password')">
                <i
                  nz-icon
                  nzTheme="fill"
                  [nzType]="
                    getErrorColor('minlength', 'password') === 'green'
                      ? 'check-circle'
                      : 'close-circle'
                  "></i>
                <span>Is longer than 7 characters</span>
              </li>
              <li [style.color]="getErrorColor('pattern', 'password')">
                <i
                  nz-icon
                  nzTheme="fill"
                  [nzType]="
                    getErrorColor('pattern', 'password') === 'green'
                      ? 'check-circle'
                      : 'close-circle'
                  "></i>
                <span
                  >Has atleast 1 alphabet, 1 number and 1 special
                  character.</span
                >
              </li>
            </ul>
          </div>
        </div>
        <div>
          <nz-form-item>
            <nz-form-control>
              <nz-input-group nzPrefixIcon="lock" [nzSuffix]="suffixTemplate1">
                <input
                  [type]="passwordVisible ? 'text' : 'password'"
                  nz-input
                  (ngModelChange)="validateConfirmPassword()"
                  placeholder="Enter Password"
                  formControlName="password"
                  autocomplete="off"
                  pattern="(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d][A-Za-z\d!@#$%^&*()_+]{2,}" />
              </nz-input-group>
              <ng-template #suffixTemplate1>
                <i
                  nz-icon
                  [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
                  (click)="passwordVisible = !passwordVisible"></i>
              </ng-template>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-control [nzErrorTip]="errorTpl">
              <nz-input-group nzPrefixIcon="lock" [nzSuffix]="suffixTemplate2">
                <input
                  [type]="confirmPasswordVisible ? 'text' : 'password'"
                  nz-input
                  placeholder="Confirm Password"
                  formControlName="confirmPassword"
                  autocomplete="off" />
                <ng-template #errorTpl let-control>
                  <ng-container
                    *ngIf="control.hasError('confirm') && control.touched">
                    Passwords do not match.
                  </ng-container>
                </ng-template>
              </nz-input-group>
              <ng-template #suffixTemplate2>
                <i
                  nz-icon
                  [nzType]="confirmPasswordVisible ? 'eye-invisible' : 'eye'"
                  (click)="
                    confirmPasswordVisible = !confirmPasswordVisible
                  "></i>
              </ng-template>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-control>
              <button
                [nzLoading]="loading"
                [disabled]="!resetPasswordForm.valid"
                nz-button
                nzType="primary"
                nzSize="large"
                class="fullwidth mt-2">
                <i nz-icon nzType="check" nzTheme="outline"></i>
                {{ routeType === 'firstPasswordSetup' ? 'Confirm' : 'Verify' }}
              </button>
            </nz-form-control>
          </nz-form-item>
        </div>
      </form>
    </nz-card>
    <ng-template #titleTpl>
      <div class="title">
        <img class="logo" src="/assets/imgs/logo.svg" />
        <span>SnapTransparency</span>
      </div>
    </ng-template>
  </nz-col>
</nz-row>
