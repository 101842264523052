import { HttpRequest, HttpHandler } from '@angular/common/http';
import { ApiEndpoint } from '@snap/shared/model';
import { stringFormat } from '@snap/shared/utils';
import { NzSafeAny } from 'ng-zorro-antd/core/types';
import { ok } from './util';
import { delay } from 'rxjs';
import { Guid } from 'guid-typescript';
export class DrugsEndPt {
  /* private drugListMockData: GetDrugsListRS = {
    requestId: Guid.create(),
    transactionId: Guid.create(),
    pageInfo: {
      total: 3,
      pageIndex: 1,
      pageSize: 10
    },
    data: [
      {
        id: '1',
        ndc: '0777-3105-02',
        name: 'drug1',
        risk: RISK_TYPE.LOW,
        pendingStates: 0,
        totalStates: 50,
        deadline: 1657610801,
        latestWAC: 14.2
      },
      {
        id: '2',
        ndc: '0777-3105-03',
        name: 'drug2',
        risk: RISK_TYPE.MODERATE,
        pendingStates: 20,
        totalStates: 50,
        deadline: 1657610801,
        latestWAC: 30.1
      },
      {
        id: '3',
        ndc: '0777-3105-05',
        name: 'drug3',
        risk: RISK_TYPE.HIGH,
        pendingStates: 34,
        totalStates: 50,
        deadline: 1657610801,
        latestWAC: 20.4
      },
      {
        id: '1',
        ndc: '0777-3105-06',
        name: 'drug4',
        risk: RISK_TYPE.LOW,
        pendingStates: 5,
        totalStates: 50,
        deadline: 1657610801,
        latestWAC: 14.2
      }
    ],
    metadata: {
      total: 5
    },
    messages: []
  }; */

  constructor(
    private apiEndPt: ApiEndpoint,
    private request: HttpRequest<NzSafeAny>,
    private next: HttpHandler
  ) {}

  handleRoute() {
    const endpoint = this.apiEndPt;
    const { url, method } = this.request;
    switch (true) {
      case url.endsWith(stringFormat(endpoint.DRUGS.toString(), 'list')) &&
        method === 'POST':
        // return this.getDrugs();
        return this.next.handle(this.request);
      case url.endsWith(stringFormat(endpoint.DRUGS.toString(), '')) &&
        method === 'POST':
        return this.next.handle(this.request);
      case url.includes(stringFormat(endpoint.DRUGS.toString(), '')) &&
        method === 'GET':
        // return this.getDrugById();
        return this.next.handle(this.request);
      case url.includes(stringFormat(endpoint.DRUGS.toString(), '')) &&
        method === 'PUT':
        // return this.updateDrug();
        return this.next.handle(this.request);
      default:
        return this.next.handle(this.request);
    }
  }

  getDrugs() {
    // return ok(this.drugListMockData).pipe(delay(1000));
  }

  getDrugById() {
    return ok({
      requestId: Guid.create(),
      transactionId: Guid.create(),
      data: [
        {
          'drugInformation': {
            'tradeName': 'Ecosprin',
            'ndc': '0777-3105-02',
            'chemicalName': 'cn',
            'therapeuticCategory': 'ANESTHETICS',
            'brandIndicator': 'GENERIC',
            'drugType': 'BIOLOGICAL',
            'referenceBrandName': 'ref',
            'referenceBrandWac': 123,
            'referenceBrandNdc': 'ndc',
            'productDescription': 'prod desc',
            'commerciallyAvailableFromDate': 1659343276,
            'wacAtLaunch': 222
          },
          'dosage': {
            'strength': 'stre',
            'dosingFrom': 'dos',
            'package': 'pack',
            'dosing': 'dosi',
            'unitOfMeasure': 'TAB'
          },
          'fdaApplication': {
            'isFdaApproved': true,
            'isPatented': true,
            'hasBreakthroughDesignation': true,
            'isInPriorityReview': true,
            'acceleratedApprovalStatus': true,
            'patentExpirationDate': 1659429702,
            'pdufadate': 1659516105,
            'applicationType': 'BLA',
            'filingDate': 1659602522,
            'approvalDate': 1659688925,
            'applicationNumber': '444',
            'applicationSupplementNumber': '555',
            'regulatoryPathway': 'path'
          },
          'regulatoryChecklist': {
            'agreementToDelayGenericVersionOfProduct': true,
            'hasRareDiseaseIndication': true,
            'hasOrphanDrugStatus': true,
            'hasPediatricIndication': true,
            'hasFastTrackStatus': true,
            'hasNewMolecularEntityStatus': true,
            'drugSourceType': 'source',
            'proposedIndication': 'pro',
            'areaOfStudy': 'area',
            'orphanDesignationNumber': 'orphan',
            'routeofAdministration': 'roa',
            'clinicalComparators': 'cc'
          },
          'acquisitionDetail': {
            'isAcquiredDrug': true,
            'dateAcquired': 1659775362,
            'acquisitionPrice': 1278,
            'acquisitionPriceComment': 'apc',
            'currencyOfAcquisition': 'USD'
          },
          'marketSpace': {
            'estimatedOfPatients': 12,
            'descriptionOfMonthlyPatientVolume': 'test',
            'projectedPatientVolumeUs': 99,
            'projectedPatientVolumeCt': 88,
            'projectedRevenueUs': 77,
            'projectedRevenueCt': 66
          },
          'budgetDetail': {
            'rDcosts': 55,
            'itemizedCostforProducionandSales': 'icop',
            'clinicalTrialCosts': 55,
            'regulationCosts': 44,
            'manufacturingCosts': 33,
            'marketingCosts': 22
          }
        }
      ]
    });
  }

  updateDrug() {
    return ok({
      message: 'successfully updated the drug details'
    }).pipe(delay(1000));
  }

  // getPriceChangeHistoryForChart() {
  //   const respData: {
  //     date: number;
  //     price: number;
  //   }[] = [];

  //   const now = moment('2000-01-01');
  //   respData.push({ date: now.unix(), price: Math.random() * 300 });
  //   for (let i = 1; i < 10; i++) {
  //     respData.push({
  //       date: now.add(1, 'M').unix(),
  //       price: Math.round((Math.random() - 0.5) * 20 + respData[i - 1].price)
  //     });
  //   }

  //   return ok(<GetDrugPriceHistoryRS>{
  //     data: respData,
  //     percentageChangeStats: {
  //       FiveYearChange: 11,
  //       ThreeYearChange: -6,
  //       OneYearChange: 4
  //     }
  //   }).pipe(delay(1000));
  // }
}
