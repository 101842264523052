import { State } from './interfaces';

export const STATES: State[] = [
  {
    name: 'Alabama',
    coordinates: [-86.8073, 32.799],
    code: 'AL'
  },
  {
    name: 'Alaska',
    coordinates: [-152.2683, 61.385],
    code: 'AK'
  },
  {
    name: 'Arizona',
    coordinates: [-111.3877, 33.7712],
    code: 'AZ'
  },
  {
    name: 'Arkansas',
    coordinates: [-92.3809, 34.9513],
    code: 'AR'
  },
  {
    name: 'California',
    coordinates: [-119.7462, 36.17],
    code: 'CA'
  },
  {
    name: 'Colorado',
    coordinates: [-105.3272, 39.0646],
    code: 'CO'
  },
  {
    name: 'Connecticut',
    coordinates: [-72.7622, 41.5834],
    code: 'CT'
  },
  {
    name: 'Delaware',
    coordinates: [-75.5148, 39.3498],
    code: 'DE'
  },
  {
    name: 'District of Columbia',
    coordinates: [-77.0369, 38.9072],
    code: 'DC'
  },
  {
    name: 'Florida',
    coordinates: [-81.717, 27.8333],
    code: 'FL'
  },
  {
    name: 'Georgia',
    coordinates: [-83.6487, 32.9866],
    code: 'GA'
  },
  {
    name: 'Hawaii',
    coordinates: [-157.5311, 21.1098],
    code: 'HI'
  },
  {
    name: 'Idaho',
    coordinates: [-114.5103, 44.2394],
    code: 'ID'
  },
  {
    name: 'Illinois',
    coordinates: [-89.0022, 40.3363],
    code: 'IL'
  },
  {
    name: 'Indiana',
    coordinates: [-86.2604, 39.8647],
    code: 'IN'
  },
  {
    name: 'Iowa',
    coordinates: [-93.214, 42.0046],
    code: 'IA'
  },
  {
    name: 'Kansas',
    coordinates: [-96.8005, 38.5111],
    code: 'KS'
  },
  {
    name: 'Kentucky',
    coordinates: [-84.6514, 37.669],
    code: 'KY'
  },
  {
    name: 'Louisiana',
    coordinates: [-91.8749, 31.1801],
    code: 'LA'
  },
  {
    name: 'Maine',
    coordinates: [-69.3977, 44.6074],
    code: 'ME'
  },
  {
    name: 'Maryland',
    coordinates: [-76.7902, 39.0724],
    code: 'MD'
  },
  {
    name: 'Massachusetts',
    coordinates: [-71.5314, 42.2373],
    code: 'MA'
  },
  {
    name: 'Michigan',
    coordinates: [-84.5603, 43.3504],
    code: 'MI'
  },
  {
    name: 'Minnesota',
    coordinates: [-93.9196, 45.7326],
    code: 'MN'
  },
  {
    name: 'Mississippi',
    coordinates: [-89.6812, 32.7673],
    code: 'MS'
  },
  {
    name: 'Missouri',
    coordinates: [-92.302, 38.4623],
    code: 'MO'
  },
  {
    name: 'Montana',
    coordinates: [-110.3261, 46.9048],
    code: 'MT'
  },
  {
    name: 'Nebraska',
    coordinates: [-98.2883, 41.1289],
    code: 'NE'
  },
  {
    name: 'Nevada',
    coordinates: [-117.1219, 38.4199],
    code: 'NV'
  },
  {
    name: 'New Hampshire',
    coordinates: [-71.5653, 43.4108],
    code: 'NH'
  },
  {
    name: 'New Jersey',
    coordinates: [-74.5089, 40.314],
    code: 'NJ'
  },
  {
    name: 'New Mexico',
    coordinates: [-106.2371, 34.8375],
    code: 'NM'
  },
  {
    name: 'New York',
    coordinates: [-74.9384, 42.1497],
    code: 'NY'
  },
  {
    name: 'North Carolina',
    coordinates: [-79.8431, 35.6411],
    code: 'NC'
  },
  {
    name: 'North Dakota',
    coordinates: [-99.793, 47.5362],
    code: 'ND'
  },
  {
    name: 'Ohio',
    coordinates: [-82.7755, 40.3736],
    code: 'OH'
  },
  {
    name: 'Oklahoma',
    coordinates: [-96.9247, 35.5376],
    code: 'OK'
  },
  {
    name: 'Oregon',
    coordinates: [-122.1269, 44.5672],
    code: 'OR'
  },
  {
    name: 'Pennsylvania',
    coordinates: [-77.264, 40.5773],
    code: 'PA'
  },
  {
    name: 'Puerto Rico',
    coordinates: [-66.5901, 18.2208],
    code: 'PR'
  },
  {
    name: 'Rhode Island',
    coordinates: [-71.5101, 41.6772],
    code: 'RI'
  },
  {
    name: 'South Carolina',
    coordinates: [-80.9066, 33.8191],
    code: 'SC'
  },
  {
    name: 'South Dakota',
    coordinates: [-99.4632, 44.2853],
    code: 'SD'
  },
  {
    name: 'Tennessee',
    coordinates: [-86.7489, 35.7449],
    code: 'TN'
  },
  {
    name: 'Texas',
    coordinates: [-97.6475, 31.106],
    code: 'TX'
  },
  {
    name: 'Utah',
    coordinates: [-111.8535, 40.1135],
    code: 'UT'
  },
  {
    name: 'Virgin Islands',
    coordinates: [-64.8963, 18.3358],
    code: 'VI'
  },
  {
    name: 'Virginia',
    coordinates: [-78.2057, 37.768],
    code: 'VA'
  },
  {
    name: 'Vermont',
    coordinates: [-72.7093, 44.0407],
    code: 'VT'
  },
  {
    name: 'Washington',
    coordinates: [-121.5708, 47.3917],
    code: 'WA'
  },
  {
    name: 'West Virginia',
    coordinates: [-80.9696, 38.468],
    code: 'WV'
  },
  {
    name: 'Wisconsin',
    coordinates: [-89.6385, 44.2563],
    code: 'WI'
  },
  {
    name: 'Wyoming',
    coordinates: [-107.2085, 42.7475],
    code: 'WY'
  }
];
