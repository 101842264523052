import { NzSafeAny } from 'ng-zorro-antd/core/types';

export interface TreeNodeInterface {
  id: string | number;
  level?: number;
  expand?: boolean;
  children?: TreeNodeInterface[];
  parent?: TreeNodeInterface;

  [key: string]: NzSafeAny;
}

function convertTreeToList(root: TreeNodeInterface): TreeNodeInterface[] {
  const stack: TreeNodeInterface[] = [];
  const array: TreeNodeInterface[] = [];
  const hashMap = {};
  stack.push({ ...root, level: 0, expand: false, _checked: false });

  while (stack.length !== 0) {
    const node = stack.pop()!;
    visitNode(node, hashMap, array);
    if (node.children) {
      for (let i = node.children.length - 1; i >= 0; i--) {
        stack.push({
          ...node.children[i],
          level: node.level! + 1,
          _checked: false,
          expand: false,
          parent: node,
        });
      }
    }
  }

  return array;
}

function visitNode(
  node: TreeNodeInterface,
  hashMap: { [key: string]: boolean },
  array: TreeNodeInterface[]
): void {
  if (!hashMap[node.id]) {
    hashMap[node.id] = true;
    array.push(node);
  }
}

// Get treeData in map form, input parameter is dataList
const fnTreeDataToMap = function tableToTreeData(dataList: NzSafeAny[]) {
  const mapOfExpandedData: { [key: string]: TreeNodeInterface[] } = {};
  dataList.forEach((item) => {
    mapOfExpandedData[item.id] = convertTreeToList(item);
  });
  return mapOfExpandedData;
};

/**
 * This method is used to convert an array with parent-child relationship into a tree-structured array
 * Receives an array with a parent-child relationship as a parameter
 * Returns a tree-structured array
 */
const fnFlatDataHasParentToTree = function translateDataToTree(
  data: NzSafeAny[],
  fatherId = 'fatherId'
) {
  // There is no data for the parent node
  const parents = data.filter((value) => value[fatherId] === 0);

  // have the data of the parent node
  const children = data.filter((value) => value[fatherId] !== 0);

  //Define the specific implementation of the conversion method
  const translator = (parents: NzSafeAny[], children: NzSafeAny[]) => {
    // Traverse the parent node data
    parents.forEach((parent) => {
      // Traverse child node data
      children.forEach((current, index) => {
        // Find a child node corresponding to the parent node at this time
        if (current[fatherId] === parent.id) {
          //Deep copy of child node data, only some types of data deep copy are supported here, children's boots who don't understand deep copy can first learn about deep copy
          const temp = JSON.parse(JSON.stringify(children));
          //Let the current child node be removed from temp, and temp is used as the new child node data. This is to make the number of traversals of child nodes less during recursion. The more levels of parent-child relationship, the more favorable
          temp.splice(index, 1);
          //Let the current child node be the only parent node to recursively find its corresponding child node
          translator([current], temp);
          // Put the found child node into the children property of the parent node
          typeof parent.children !== 'undefined'
            ? parent.children.push(current)
            : (parent.children = [current]);
        }
      });
    });
  };
  //call the conversion method
  translator(parents, children);
  return parents;
};

// Add the tree structure data to the level and whether it is the mark of the root node, the root node isLeaf is true, and the level is represented by level
const fnAddTreeDataGradeAndLeaf = function AddTreeDataGradeAndLeaf(
  array: NzSafeAny[],
  levelName = 'level',
  childrenName = 'children'
) {
  const recursive = (array: NzSafeAny[], level = 0) => {
    level++;
    return array.map((v: NzSafeAny) => {
      v[levelName] = level;
      const child = v[childrenName];
      if (child && child.length > 0) {
        v.isLeaf = false;
        recursive(child, level);
      } else {
        v.isLeaf = true;
      }
      return v;
    });
  };
  return recursive(array);
};

// flattened tree data
const fnFlattenTreeDataByDataList = function flattenTreeData(
  dataList: NzSafeAny[]
) {
  const mapOfExpandedData: { [key: string]: TreeNodeInterface[] } =
    fnTreeDataToMap(dataList);
  return fnGetFlattenTreeDataByMap(mapOfExpandedData);
};

// Get the flattened tree data, the input parameter is treeData in the form of map
const fnGetFlattenTreeDataByMap =
  function getFlattenTreeData(mapOfExpandedData: {
    [key: string]: TreeNodeInterface[];
  }) {
    const targetArray: TreeNodeInterface[] = [];
    Object.values(mapOfExpandedData).forEach((item) => {
      item.forEach((item_1) => {
        targetArray.push(item_1);
      });
    });
    return targetArray;
  };

export {
  fnTreeDataToMap,
  fnAddTreeDataGradeAndLeaf,
  fnFlatDataHasParentToTree,
  fnFlattenTreeDataByDataList,
  fnGetFlattenTreeDataByMap,
};
