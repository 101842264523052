import { Injectable, Optional, SkipSelf } from '@angular/core';
//import { Logger } from '@snap/core';
import { UserInfoService } from './user-info.service';

const clientInfoKey = 'CLIENT_INFO';

//const logger = new Logger('ClientInfoService');

export interface ClientInfo {
  clientId: string;
  name: string;
}
@Injectable({
  providedIn: 'root'
})
export class ClientInfoService {
  constructor(
    @Optional() private userInfoService: UserInfoService,
    @Optional() @SkipSelf() parentService: ClientInfoService
  ) {
    //logger.info('Instance Created');
    if (parentService != null) {
      throw new Error('ClientInfoService is already created.');
    }
  }

  get ClientInfo(): ClientInfo {
    if (this.userInfoService) {
      const userInfo = this.userInfoService.UserInfo;
      if (userInfo?.context) {
        return <ClientInfo>{
          clientId: userInfo.context.clientId,
          name: userInfo.context.clientName
        };
      }
    }
    const data =
      sessionStorage.getItem(clientInfoKey) ||
      localStorage.getItem(clientInfoKey);
    return data ? JSON.parse(data) : '';
  }
}
