import { HttpRequest, HttpHandler } from '@angular/common/http';
import { ApiEndpoint } from '@snap/shared/model';
import { stringFormat } from '@snap/shared/utils';
import { NzSafeAny } from 'ng-zorro-antd/core/types';
import { ok } from './util';
import { delay } from 'rxjs';
import { PriceChangeListRS } from '@snap/shared/model/api';
import { Guid } from 'guid-typescript';

export class DrugPriceEndPt {
  /* private drugListMockData: GetAddedDrugsListRS = {
    requestId: Guid.create(),
    transactionId: Guid.create(),
    pageInfo: {
      total: 3,
      pageIndex: 1,
      pageSize: 7
    },
    data: [
      {
        id: Guid.create(),
        ndc: '0777-3105-02',
        tradeName: 'drug1',
        wac: 250,
        averageMonthlyDrugCost: 50,
        priceChange: 5,
        commerciallyAvailableFromDate: 1657610801
      },
      {
        id: Guid.create(),
        ndc: '0777-3105-03',
        tradeName: 'drug2',
        wac: 20,
        averageMonthlyDrugCost: 200,
        priceChange: 10,
        commerciallyAvailableFromDate: 1657610801
      },
      {
        id: Guid.create(),
        ndc: '0777-3105-05',
        tradeName: 'drug3',
        wac: 300,
        averageMonthlyDrugCost: 500,
        priceChange: 2.2,
        commerciallyAvailableFromDate: 1657610801
      },
      {
        id: Guid.create(),
        ndc: '0777-3105-06',
        tradeName: 'drug4',
        wac: 80,
        averageMonthlyDrugCost: 100,
        priceChange: 20,
        commerciallyAvailableFromDate: 1657610801
      }
    ],
    metadata: {
      total: 5
    },
    messages: []
  }; */

  private priceChangeMockData: PriceChangeListRS = {
    requestId: Guid.create(),
    transactionId: Guid.create(),
    data: [
      {
        id: '1',
        wac: 250,
        averageTreatmentCost: 50,
        wacChangeDate: '2020-11-11T00:00:00'
      },
      {
        id: '2',
        wac: 100,
        averageTreatmentCost: 200,
        wacChangeDate: '2020-11-11T00:00:00'
      },
      {
        id: '3',
        wac: 300,
        averageTreatmentCost: 500,
        wacChangeDate: '2020-11-11T00:00:00'
      }
    ],
    messages: []
  };

  /* private WACComplianceList1: WACComplianceStateListRS = {
    messages: [],
    requestId: Guid.create(),
    transactionId: Guid.create(),
    data: [
      {
        'id': '1',
        'state': 'Oregon',
        'type': 'Price Change',
        'reportTrigger': 'WAC Increase of 10%',
        'deadline': 1660988315
      },
      {
        'id': '2',
        'state': 'California',
        'type': 'New Drug Launch',
        'reportTrigger': 'New Product',
        'deadline': 1664962715
      },
      {
        'id': '3',
        'state': 'North Dakota',
        'type': 'Periodic',
        'reportTrigger': 'Recurring',
        'deadline': 1664530715
      }
    ]
  };

  private WACComplianceList2: WACComplianceStateListRS = {
    messages: [],
    requestId: Guid.create(),
    transactionId: Guid.create(),
    data: [
      {
        'id': '4',
        'state': 'North Dakota',
        'type': 'New Drug Launch',
        'reportTrigger': 'New Product',
        'deadline': 1663666715
      },
      {
        'id': '5',
        'state': 'Utah',
        'type': 'Price Change',
        'reportTrigger': 'WAC Increase of 5%',
        'deadline': 1665394715
      }
    ]
  }; */

  constructor(
    private apiEndPt: ApiEndpoint,
    private request: HttpRequest<NzSafeAny>,
    private next: HttpHandler
  ) {}

  handleRoute() {
    const endpoint = this.apiEndPt;
    const { url, method } = this.request;
    switch (true) {
      /* case url.endsWith(
        stringFormat(endpoint.DRUGPRICE.toString(), 'pricecalculator/list')
      ) && method === 'POST':
        //return this.getAddedDrugs();
        return this.next.handle(this.request); */
      case url.endsWith(endpoint.DRUGPRICE.CHECK_COMPLIANCE) &&
        method === 'POST':
        // return this.getWACComplianceList(url);
        return this.next.handle(this.request);
      case url.endsWith(stringFormat(endpoint.DRUGPRICE.toString(), 'list')) &&
        method === 'POST':
        // return this.getPriceChangeList();
        return this.next.handle(this.request);
      case url.endsWith(stringFormat(endpoint.DRUGPRICE.toString(), '')) &&
        method === 'POST':
        // return this.addWAC();
        return this.next.handle(this.request);
      default:
        return this.next.handle(this.request);
    }
  }

  /* getAddedDrugs() {
    return ok(this.drugListMockData).pipe(delay(1000));
  } */

  getPriceChangeList() {
    return ok(this.priceChangeMockData).pipe(delay(1000));
  }

  /* getWACComplianceList(url: string) {
    if (url.endsWith('1')) return ok(this.WACComplianceList1).pipe(delay(1000));
    else return ok(this.WACComplianceList2).pipe(delay(1000));
  } */

  addWAC() {
    return ok({
      message: 'successfully added new WAC'
    });
  }
}
