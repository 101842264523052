import {
  animateChild,
  group,
  query,
  animate,
  AnimationQueryOptions,
  style,
  transition,
  trigger,
} from '@angular/animations';

const options: AnimationQueryOptions = {
  optional: true,
};

/** Fade animation */
export const fadeAnimation = trigger('fadeAnimation', [
  transition(':enter', [
    style({
      transform: 'scale3d(1.075, 1.075, 1)',
      opacity: 0,
    }),
    animate(
      '250ms ease-out',
      style({
        transform: 'scale3d(1, 1, 1)',
        opacity: 1,
      })
    ),
  ]),
  transition(':leave', [
    animate(
      '250ms ease-out',
      style({
        transform: 'scale3d(0.95, 0.95, 1)',
        opacity: 0,
      })
    ),
  ]),
]);

/*
export const fadeRouteAnimation = trigger('fadeRouteAnimation', [
  transition('* <=> *', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'
      }),
      animateChild(),
    ], options),
    query(':enter', [
      style({ opacity: 0 })
    ], options),
    group([
      query(':leave', [
        animate('.5s ease-in', style({ opacity: 0 }))
      ], options),
      query(':enter', [
        animate('.5s ease-in', style({ opacity: 1 }))
      ], options)
    ]),
  ])
]);

*/

/** Fade out and fade out routing animation */
export const fadeRouteAnimation = trigger('fadeRouteAnimation', [
  transition('*<=>*', [
    // css styles at start of transition
    style({ opacity: 0, 'will-change': 'transform' }),

    // animation and styles at end of transition
    animate('0.3s ease-in', style({ opacity: 1, 'will-change': 'transform' })),
  ]),
]);

/** Horizontal sliding routing animation */
export const horizontalSlideInRouteAnimation = trigger(
  'horizontalSlideInRouteAnimation',
  [
    transition(':increment', [
      style({ position: 'relative' }),
      query(
        ':enter, :leave',
        [
          style({
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
          }),
          animateChild(),
        ],
        options
      ),

      query(':enter', [style({ transform: 'translate3d(100%,0,0)' })], options),

      group([
        query(
          ':leave',
          [
            animate(
              '.3s ease-out',
              style({ transform: 'translate3d(-100%,0,0)' })
            ),
          ],
          options
        ),
        query(
          ':enter',
          [animate('.3s ease-out', style({ transform: 'none' }))],
          options
        ),
      ]),
    ]),

    transition(':decrement', [
      style({ position: 'relative' }),
      query(
        ':enter, :leave',
        [
          style({
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
          }),
          animateChild(),
        ],
        options
      ),

      query(
        ':enter',
        [style({ transform: 'translate3d(-100%,0,0)' })],
        options
      ),

      group([
        query(
          ':leave',
          [
            animate(
              '.3s ease-out',
              style({ transform: 'translate3d(100%,0,0)' })
            ),
          ],
          options
        ),
        query(
          ':enter',
          [animate('.3s ease-out', style({ transform: 'none' }))],
          options
        ),
      ]),
    ]),
  ]
);

/** Swipe down to expand when entering */
export const slideDownOnEnterAnimation = trigger('slideDownOnEnterAnimation', [
  transition(':enter', [
    style({
      overflow: 'hidden',
      height: 0,
      opacity: 0,
    }),
    animate(
      '.125s ease-out',
      style({
        height: '*',
        opacity: 1,
      })
    ),
  ]),
]);

/** Swipe up when leaving */
export const slideUpOnLeaveAnimation = trigger('slideUpOnLeaveAnimation', [
  transition(':leave', [
    style({ overflow: 'hidden' }),
    animate(
      '.125s ease-out',
      style({
        height: 0,
        opacity: 0,
      })
    ),
  ]),
]);
