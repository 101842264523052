//import moment from "moment";

import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import moment from 'moment';
import { NzSafeAny } from 'ng-zorro-antd/core/types';
import { GlobalDateFormat } from '@snap/shared/model/interfaces';

export function stringFormat(data: string, ...args: string[]) {
  return data.replace(/{([0-9]+)}/g, function (match, index) {
    return typeof args[index] == 'undefined' ? match : args[index];
  });
}

export function generateArrayOfYears(numberOfYears: number) {
  const max = new Date().getFullYear();
  const min = max - numberOfYears;
  // eslint-disable-next-line prefer-const
  let years = [];

  for (let i = max; i > min; i--) {
    years.push(i);
  }
  return years;
}

/**
 *
 * @param date epoch timestamp in second
 * @returns date in format MM/DD/YY
 */
export function formatDate(date: GlobalDateFormat) {
  if (date) {
    return moment(date).format('DD MMM YYYY');
  }
  return 'date not available';
}

export function GetAccessDeniedUrl(url: string) {
  return function AccessDenied(
    _rejectedPermissionName: string,
    _activateRouteSnapshot: ActivatedRouteSnapshot,
    _routeStateSnapshot: RouterStateSnapshot
  ): NzSafeAny {
    return {
      navigationCommands: [url],
      navigationExtras: {
        skipLocationChange: true
      }
    };
  };
}
