import { Inject, Injectable, Optional, SkipSelf } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  CanLoad,
  Router,
  UrlTree,
} from '@angular/router';
import { SNAP_APP_CONFIG } from '@snap/shared/model/di-tokens';
import { IAppConfiguration } from '@snap/shared/model/interfaces';
import { Observable } from 'rxjs';
//import { Logger } from '../services/logger.service';
//const log = new Logger('AppGuard');
@Injectable({
  providedIn: 'root',
})
export class AppGuard implements CanActivate, CanLoad, CanActivateChild {
  currDate = new Date();
  constructor(
    private router: Router,
    @Inject(SNAP_APP_CONFIG) private appConfig: IAppConfiguration,
    @Optional() @SkipSelf() parentGuard: AppGuard
  ) {
    if (parentGuard) {
      throw new Error('AuthGuard is already loaded.');
    }
  }
  canLoad():
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    //log.info('canLoad', this.currDate.toString());
    return this.appConfig.init().then((x) => {
      if (!x) {
        this.redirect404();
        return false;
      }
      return true;
    });
  }

  canActivateChild():
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    //log.info('canActivate', this.currDate.toString());
    return this.appConfig.init().then((x) => {
      if (!x) {
        this.redirect404();
        return false;
      }
      return true;
    });
  }

  canActivate():
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    //log.info('canActivate', this.currDate.toString());
    return this.appConfig.init().then((x) => {
      if (!x) {
        this.redirect404();
        return false;
      }
      return true;
    });
  }

  redirect404() {
    //log.info('REDIRECT404', this.currDate.toString());
    this.router.navigateByUrl('/404');
  }
}
