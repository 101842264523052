/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { stringFormat } from '@snap/shared/utils';
import { NzSafeAny } from 'ng-zorro-antd/core/types';
import {
  ok,
  ClientEndPt,
  AuthEndPt,
  UserEndPt,
  StateEndPt,
  BillEndPt,
  RulesEndPt,
  ClientDashboardEndpts,
  DrugsEndPt,
  DrugPriceEndPt,
  FilingEndPt
} from '@snap/mocks/faker';
import {
  delay,
  dematerialize,
  EMPTY,
  materialize,
  mergeMap,
  Observable,
  of
} from 'rxjs';
//import { Logger } from '@snap/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import {
  ClientInfoService,
  UserInfoService
} from '@snap/shared/services/store';
import { IEnvironment } from '@snap/shared/model/interfaces';
import { SNAP_ENV } from '@snap/shared/model/di-tokens';

//const logger = new Logger('BackendInterceptor');
@Injectable()
export class BackendInterceptor implements HttpInterceptor {
  constructor(
    @Inject(SNAP_ENV) private enviroment: IEnvironment,
    private msgSrv: NzMessageService,
    private clientInfoService: ClientInfoService,
    private userInfoService: UserInfoService
  ) {}
  intercept(
    request: HttpRequest<NzSafeAny>,
    next: HttpHandler
  ): Observable<HttpEvent<NzSafeAny>> {
    const { url, method } = request;
    const endpoint = this.enviroment.API_ENDPOINT;
    if (!url.endsWith(endpoint.CLIENT.BASIC_INFO)) {
      try {
        let clientId = '';
        let userId = '';
        const strClientInfoId = this.clientInfoService.ClientInfo.clientId;
        if (strClientInfoId) {
          clientId = strClientInfoId;
        }

        const strUserId = this.userInfoService.UserInfo?.userId;
        if (strUserId) {
          userId = strUserId;
        }

        request = request.clone({
          headers: request.headers
            .set('clientid', clientId)
            .set('userid', userId)
        });

        if (request.body instanceof FormData) {
          request = request.clone({
            headers: request.headers.set('snapapprovedheader', 'fileupload')
          });
        }
      } catch (e) {
        //logger.debug('Unable to retrieve ClientId');
        this.msgSrv.error(
          'Something went wrong, Please refresh browser window'
        );
      }
    }

    return of(null)
      .pipe(mergeMap(handleRoute))
      .pipe(materialize())
      .pipe(delay(0))
      .pipe(dematerialize());

    function handleRoute() {
      switch (true) {
        case url.includes(endpoint.CLIENT.BASIC_INFO):
          if (
            request.params.get('domain') === 'localhost' ||
            request.params.get('domain') === '127.0.0.1'
          ) {
            return ok({
              data: [
                {
                  client: {
                    //clientId: 'b71d4c5e-cf6d-417a-9329-b206bd4f251e',
                    clientId: 'snapdragon',
                    name: 'SnapDemo'
                  }
                }
              ]
            });
          } else {
            return next.handle(request);
          }
        //return next.handle(request);

        /* Auth Route */
        case url.endsWith(endpoint.AUTH.LOGIN):
        case url.endsWith(endpoint.AUTH.LOGOUT):
        case url.endsWith(endpoint.AUTH.REFRESH_TOKEN):
        case url.endsWith(endpoint.AUTH.RESET_PASSWORD):
        case url.endsWith(endpoint.AUTH.EMAIL_PASSWORD_RESET_CODE):
          return new AuthEndPt(endpoint, request, next).handleRoute();

        /* Client Route */
        case url.startsWith(stringFormat(endpoint.CLIENT.toString(), '')):
          return new ClientEndPt(endpoint, request, next).handleRoute();

        /* User Route */
        case url.startsWith(stringFormat(endpoint.USER.toString(), '')):
          return new UserEndPt(endpoint, request, next).handleRoute();

        /*Rules Route*/
        case url.startsWith(stringFormat(endpoint.RULES.toString(), '')):
          return new RulesEndPt(endpoint, request, next).handleRoute();

        /* State Route */
        case url.startsWith(stringFormat(endpoint.STATE.toString(), '')):
          return new StateEndPt(endpoint, request, next).handleRoute();

        /* Legislation Bills Route */
        case url.startsWith(stringFormat(endpoint.BILL.toString(), '')):
          return new BillEndPt(endpoint, request, next).handleRoute();

        /* Client Dashboard Route */
        case url.startsWith(
          stringFormat(endpoint.CLIENTDASHBOARD.toString(), '')
        ):
          return new ClientDashboardEndpts(
            endpoint,
            request,
            next
          ).handleRoute();

        /* Drugs Route */
        case url.startsWith(stringFormat(endpoint.DRUGS.toString(), '')):
          return new DrugsEndPt(endpoint, request, next).handleRoute();
        /* Filing Route */
        case url.startsWith(stringFormat(endpoint.FILING.toString(), '')):
          return new FilingEndPt(endpoint, request, next).handleRoute();

        /* Drug Price Route */
        case url.startsWith(stringFormat(endpoint.DRUGPRICE.toString(), '')):
          return new DrugPriceEndPt(endpoint, request, next).handleRoute();

        default:
          // pass through any requests not handled above
          return next.handle(request);
      }
    }
  }
}

export const backendProvider = {
  // use backend in place of Http service for backend-less development
  provide: HTTP_INTERCEPTORS,
  useClass: BackendInterceptor,
  multi: true
};
