import { HttpRequest, HttpHandler } from '@angular/common/http';
import { ApiEndpoint } from '@snap/shared/model';
import { NzSafeAny } from 'ng-zorro-antd/core/types';
import { throwError } from 'rxjs';
import { fakeStore } from './store';
import { isLoggedIn, ok, unauthorized } from './util';

const userListAll = {
  pageInfo: {
    total: 5,
    pageIndex: 1,
    pageSize: 10,
  },
  data: [
    {
      id: 1,
      name: 'username',
      email: 'user@email.com',
      role: 'Adminstretor',
    },
    {
      id: 2,
      name: 'username2',
      email: 'user2@email.com',
      role: 'POP',
    },
    {
      id: 3,
      name: 'username3',
      email: 'user3@email.com',
      role: 'admin',
    },
    {
      id: 4,
      name: 'username4',
      email: 'user4@email.com',
      role: 'admin',
    },
    {
      id: 5,
      name: 'username6',
      email: 'user6@email.com',
      role: 'admin',
    },
  ],
};
const userListEnable = {
  pageInfo: {
    total: 15,
    pageIndex: 1,
    pageSize: 10,
  },
  data: [
    {
      id: 1,
      name: 'username1',
      email: 'user1@email.com',
      role: 'admin',
    },
    {
      id: 2,
      name: 'username2',
      email: 'user2@email.com',
      role: 'admin',
    },
    {
      id: 3,
      name: 'username3',
      email: 'user3@email.com',
      role: 'admin',
    },
  ],
};

const userListDisable = {
  pageInfo: {
    total: 15,
    pageIndex: 1,
    pageSize: 10,
  },
  data: [
    {
      id: 8,
      name: 'username8',
      email: 'user8@email.com',
      role: 'admin',
    },
    {
      id: 9,
      name: 'username9',
      email: 'user9@email.com',
      role: 'admin',
    },
  ],
};
const tabCount = {
  data: [
    {
      enabled: 3,
      disabled: 2,
      total: 5,
    },
  ],
};
export class UserEndPt {
  constructor(
    private apiEndPt: ApiEndpoint,
    private request: HttpRequest<NzSafeAny>,
    private next: HttpHandler
  ) {}

  handleRoute() {
  //  const endpoint = this.apiEndPt;
  //  const { url, method, body } = this.request;
    switch (true) {
      /*
      case url.includes(stringFormat(endpoint.USER.toString(), 'list')) &&
        method === 'POST': {
        return this.getUserList(body.headers.filter.userStatus);
      }
      
      case url.endsWith(
        stringFormat(endpoint.USER.toString(), 'UsersCountByStatus')
      ) && method === 'GET':
        return this.getTabsCountClient();
    
        case url.includes(stringFormat(endpoint.USER.toString(), '')) &&
        method === 'GET':
        return this.getUser();
      case url.includes(stringFormat(endpoint.USER.toString(), '')) &&
        method === 'POST':
        return this.updateUser();

      case url.endsWith(stringFormat(endpoint.USER.toString(), 'add')) &&
        method === 'POST':
        return this.addUser(body);
        */
      default:
        return this.next.handle(this.request);
    }
  }
  updateUser() {
    return ok({
      requestId: 'xyz',
      transactionId: '1234',
      data: [],
      messages: [],
    });
  }
  getTabsCountClient() {
    return ok(tabCount);
  }
  getUser() {
    return ok({
      data: [
        {
          name: 'sam forst',
          lastName: 'frost',
          role: 'Administrator',
          email: 'sam@gmail.com',
        },
      ],
    });
  }
  getUserList(filter?: string) {
    if (filter == 'ENABLED') {
      return ok(userListEnable);
    } else if (filter == 'DISABLED') {
      return ok(userListDisable);
    } else {
      return ok(userListAll);
    }
  }

  getUsers() {
    if (!isLoggedIn(this.request.headers)) return unauthorized();
    return ok(fakeStore.AuthUsers);
  }

  addUser(body: NzSafeAny) {
    if (body.email === 'abc@abc.com') {
      return throwError(() => ({
        code: 409,
        error: { message: 'Duplicate entry' },
      }));
    } else {
      return ok({
        message: 'successfully added the user',
      });
    }
  }
}
