import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'snap-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessDeniedComponent {
  backUrl: string | string[] = '/';
  constructor(private router: Router) {
    const currentNav = this.router.getCurrentNavigation();
    if (
      currentNav &&
      currentNav.previousNavigation &&
      currentNav.previousNavigation.initialUrl
    ) {
      this.backUrl = currentNav.previousNavigation.initialUrl.toString();
    }
  }
}
