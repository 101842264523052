import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { SNAP_APP_CONFIG } from '@snap/shared/model/di-tokens';
import { IAppConfiguration } from '@snap/shared/model/interfaces';
import { UserInfoService } from '@snap/shared/services/store';

@Component({
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LandingPageComponent implements OnInit {
  constructor(
    @Inject(SNAP_APP_CONFIG) private appConfig: IAppConfiguration,
    private userInfoService: UserInfoService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.appConfig
      .init()
      .then((x) => {
        if (x) {
          if (this.userInfoService && !!this.userInfoService.UserInfo) {
            const userInfo = this.userInfoService.UserInfo;
            if (userInfo.role == 'SNAP_ADMIN' && userInfo.context == null) {
              this.router.navigate(['admin']);
            } else {
              this.router.navigate(['client']);
            }
          } else {
            this.router.navigate(['auth', 'login']);
          }
        } else {
          //this.router.navigate(['404']);
          this.router.navigate(['auth', 'login']);
        }
      })
      .catch(() => {
        this.router.navigate(['404']);
      });
  }
}
