import {
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
  RouteReuseStrategy,
} from '@angular/router';
import { NzSafeAny } from 'ng-zorro-antd/core/types';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ScrollService } from './scroll.service';

/*Route multiplexing*/
// Refer to https://zhuanlan.zhihu.com/p/29823560
// https://blog.csdn.net/weixin_30561425/article/details/96985967?utm_medium=distribute.pc_relevant.none-task-blog-BlogCommendFromMachineLearnPai2-1.control&depth_1-utm_source=distribute.pc_relevant.none-task-blog- BlogCommendFromMachineLearnPai2-1.control
export class SimpleReuseStrategy implements RouteReuseStrategy {
  // cache the map of each component
  static handlers: { [key: string]: NzSafeAny } = {};
  // Cache the scroll position of each page, why not put it in the handlers, because the route reuse causes the current page as the key to be null when the route leaves
  static scrollHandlers: { [key: string]: NzSafeAny } = {};

  public static waitDelete: string | null;

  public static deleteRouteSnapshot(key: string): void {
    if (SimpleReuseStrategy.handlers[key]) {
      if (SimpleReuseStrategy.handlers[key].componentRef) {
        SimpleReuseStrategy.handlers[key].componentRef.destroy();
      }
      delete SimpleReuseStrategy.handlers[key];
      delete SimpleReuseStrategy.scrollHandlers[key];
    }
  }

  constructor(
    @Inject(DOCUMENT) private doc: Document,
    private scrollService: ScrollService
  ) {}

  getKey(route: ActivatedRouteSnapshot): string {
    return route.data['newTab'] === 'true'
      ? route.data['key'] + JSON.stringify(route.queryParams)
      : route.data['key'];
  }

  // Whether to allow multiplexing routes
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return route.data['shouldDetach'] !== 'no';
  }

  // Triggered when the route leaves, store the route
  store(route: ActivatedRouteSnapshot, handle: NzSafeAny): void {
    if (route.data['shouldDetach'] === 'no') {
      return;
    }
    const key = this.getKey(route);
    if (SimpleReuseStrategy.waitDelete === key) {
      // If the to-be-deleted route is the current route, no snapshot will be stored
      this.runHook('_onReuseDestroy', handle.componentRef);
      handle.componentRef.destroy();
      SimpleReuseStrategy.waitDelete = null;
      delete SimpleReuseStrategy.scrollHandlers[key];
      return;
    }

    // cache the scroll position of the current page when leaving the route
    // By default, keepScroll is required. If keepScroll is not required, add the noNeedKeepScroll:no property
    const innerScrollContainer = [];
    if (route.data['needKeepScroll'] !== 'no') {
      const scrollContain = route.data['scrollContain'] ?? [];
      scrollContain.forEach((item: string) => {
        const el = this.doc.querySelector(item);
        if (el) {
          const postion = this.scrollService.getScrollPosition(el);
          innerScrollContainer.push({ [item]: postion });
        }
      });
      innerScrollContainer.push({
        window: this.scrollService.getScrollPosition(),
      });
    }

    SimpleReuseStrategy.scrollHandlers[key] = { scroll: innerScrollContainer };
    SimpleReuseStrategy.handlers[key] = handle;

    if (handle && handle.componentRef) {
      this.runHook('_onReuseDestroy', handle.componentRef);
    }
  }

  // Whether to allow restore routes
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const key = this.getKey(route);
    return !!key && !!SimpleReuseStrategy.handlers[key];
  }

  // Get the storage route
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    const key = this.getKey(route);
    return !key ? null : SimpleReuseStrategy.handlers[key];
  }

  // Enter the route trigger, whether the route is reused when the same route is used
  shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot
  ): boolean {
    const futureKey = this.getKey(future);
    const currKey = this.getKey(curr);
    if (!!futureKey && SimpleReuseStrategy.handlers[futureKey]) {
      this.runHook(
        '_onReuseInit',
        SimpleReuseStrategy.handlers[futureKey].componentRef
      );
    }
    // Remember whether to reuse the result of the route here, because the route of the future needs to be changed next
    const result = futureKey === currKey;
    // Lazy loading can't read data, drill down to the lowest level route through this method
    while (future.firstChild) {
      future = future.firstChild;
    }
    // Re-acquisition because the future has changed in the while loop above
    const scrollFutureKey = this.getKey(future);
    if (SimpleReuseStrategy.scrollHandlers[scrollFutureKey]) {
      if (scrollFutureKey) {
        SimpleReuseStrategy.scrollHandlers[scrollFutureKey].scroll.forEach(
          (elOptionItem: { [key: string]: [number, number] }) => {
            Object.keys(elOptionItem).forEach((element) => {
              setTimeout(() => {
                this.scrollService.scrollToPosition(
                  this.doc.querySelector(element),
                  elOptionItem[element]
                );
              }, 1);
            });
          }
        );
      }
    }
    return result;
  }

  runHook(method: ReuseHookTypes, comp: ReuseComponentRef): void {
    const compThis = comp.instance;
    if (comp == null || !compThis) {
      return;
    }
    const fn = compThis[method];
    if (typeof fn !== 'function') {
      return;
    }
    (fn as () => void).call(compThis);
  }
}

export type ReuseHookTypes = '_onReuseInit' | '_onReuseDestroy';

export interface ReuseComponentInstance {
  _onReuseInit: () => void;
  _onReuseDestroy: () => void;
}

export interface ReuseComponentRef {
  instance: ReuseComponentInstance;
}
