<nz-row nzJustify="center">
  <nz-col nzSm="24">
    <div class="login-form-wrap">
      <div class="headingDiv">
        <p class="headingDivText">Login</p>
        <span class="subHeading">Welcome to SnapTransparency</span>
      </div>
      <form
        nz-form
        [formGroup]="loginForm"
        class="login-form"
        (ngSubmit)="login()">
        <div *ngIf="showError" nz-row class="infoTxt">
          <ul>
            <li>
              <i
                nz-icon
                style="color: rgb(255, 65, 65)"
                nz-icon
                nzTheme="fill"
                nzType="close-circle"></i>
              <span class="error-headline"
                >Either username or password is incorrect.</span
              >
            </li>
            <li>
              <span class="error-desc">Please check and try again</span>
            </li>
          </ul>
        </div>

        <nz-form-item>
          <nz-form-control [nzErrorTip]="emailErrorTpl">
            <nz-input-group nzPrefixIcon="user">
              <input
                type="text"
                nz-input
                formControlName="username"
                placeholder="Enter your registered Email" />
            </nz-input-group>
            <ng-template #emailErrorTpl let-control>
              <ng-container *ngIf="control.hasError('email')">
                The input is not valid email!
              </ng-container>
              <ng-container *ngIf="control.hasError('required')">
                Please input your email!
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control nzErrorTip="Please enter password.">
            <nz-input-group nzPrefixIcon="lock" [nzSuffix]="suffixTemplate2">
              <input
                [type]="passwordVisible ? 'text' : 'password'"
                nz-input
                formControlName="password"
                placeholder="Enter your password" />
            </nz-input-group>
            <ng-template #suffixTemplate2>
              <i
                nz-icon
                [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
                (click)="passwordVisible = !passwordVisible"></i>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
        <div nz-row class="login-form-margin">
          <div nz-col [nzSpan]="12"></div>
          <div nz-col [nzSpan]="12">
            <a
              class="login-form-forgot"
              nz-typography
              (click)="forgotPassword()"
              >Forgot/Reset password?</a
            >
          </div>
        </div>
        <button
          nz-button
          nzSize="large"
          [nzLoading]="loading"
          class="login-form-button login-form-margin"
          nzType="primary">
          <i nz-icon nzType="login" nzTheme="outline"></i>
          {{ loading ? 'Please Wait... ' : 'Log in' }}
        </button>
      </form>
      <nz-divider></nz-divider>
    </div>
  </nz-col>
  <nz-col>
    <span
      >Need help? Contact
      <a href="mailto:help@snaplifesciences.com" nz-typography
        >help@snaplifesciences.com</a
      ></span
    >
  </nz-col>
</nz-row>
