import { Inject, Injectable, Optional, SkipSelf } from '@angular/core';
import { catchError, map, Observable, of, tap } from 'rxjs';
import { LoginContext } from '../model/authenticate-model';
import { ResetPassword } from '../model/reset-password-model';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { NzSafeAny } from 'ng-zorro-antd/core/types';
import {
  AuthenticateRS,
  RefreshTokenRQ,
  RefreshTokenRS
} from '@snap/shared/model/api';
import { JwtHelperService } from '@auth0/angular-jwt';
//import { Logger } from '@snap/core';
import {
  ClientInfoService,
  UserCredentials,
  UserInfo,
  UserInfoService
} from '@snap/shared/services/store';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { SNAP_ENV } from '@snap/shared/model/di-tokens';
import { IEnvironment } from '@snap/shared/model/interfaces';

//const log = new Logger('AuthService');
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly apiPath;
  constructor(
    @Inject(SNAP_ENV) private environment: IEnvironment,
    private router: Router,
    private httpCore: HttpClient,
    private userInfoService: UserInfoService,
    private message: NzMessageService,
    private clientInfoService: ClientInfoService,
    @Optional() @SkipSelf() parentService: AuthService
  ) {
    if (parentService) {
      throw new Error('AuthService is already loaded.');
    }
    this.apiPath = {
      login: this.environment.API_ENDPOINT.AUTH.LOGIN,
      logout: this.environment.API_ENDPOINT.AUTH.LOGOUT,
      refresh_auth_token: this.environment.API_ENDPOINT.AUTH.REFRESH_TOKEN,
      resetPassword: this.environment.API_ENDPOINT.AUTH.RESET_PASSWORD,
      verificationLink:
        this.environment.API_ENDPOINT.AUTH.EMAIL_PASSWORD_RESET_CODE
    };
    const credentials = this.userInfoService.UserCredentials;
    if (credentials) {
      setTimeout(
        () =>
          this.refreshAccessToken(credentials).subscribe({
            error: (_err: HttpErrorResponse) => {
              this.userInfoService.setUserInfo(null);
              this.stopRefreshTokenTimer();
              this.message.success(
                'Your session has expired, Please login again',
                { nzDuration: 5000 }
              );
              this.router.navigate(['auth', 'login']);
            }
          }),
        0
      );
    }
  }
  get isAuthenticated(): boolean {
    return !!this.userInfoService.UserCredentials;
  }

  get IdToken() {
    const credentials = this.userInfoService.UserCredentials;
    return credentials ? credentials.idToken : '';
  }

  private refreshTokenTimeout = -1;

  refreshAccessToken(credentials: UserCredentials) {
    return this.httpCore
      .post<RefreshTokenRS>(this.apiPath.refresh_auth_token, {
        refreshtoken: credentials.refreshToken
      } as RefreshTokenRQ)
      .pipe(
        tap((response) => {
          if (response.data && response.data.length > 0) {
            this.userInfoService.setCredentials({
              authToken: response.data[0].access_Token,
              idToken: response.data[0].id_Token,
              refreshToken: credentials.refreshToken,
              rememberme: credentials.rememberme
            });
          }
          this.startRefreshTokenTimer();
          return response;
        })
      );
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  private startRefreshTokenTimer() {
    const credentials = this.userInfoService.UserCredentials;
    if (credentials) {
      const helper = new JwtHelperService();
      const expirationDate = helper.getTokenExpirationDate(
        credentials.authToken
      );
      if (expirationDate) {
        const expires = expirationDate.valueOf();
        let timeout = expires - new Date().valueOf() - 60 * 1000;
        if (timeout < 0) timeout = 5000;
        //log.debug('AUTH REFRESH TOKEN TIMEOUT', timeout);
        this.refreshTokenTimeout = window.setTimeout(() => {
          this.refreshAccessToken(credentials).subscribe({
            error: (_err: HttpErrorResponse) => {
              this.userInfoService.setUserInfo(null);
              this.stopRefreshTokenTimer();
              this.message.success(
                'Your session has expired, Please login again',
                { nzDuration: 5000 }
              );
              this.router.navigate(['auth', 'login']);
            }
          });
        }, timeout);
      }
    } else throw Error('Access Token is Empty');
  }

  login(loginContext: LoginContext): Observable<boolean> {
    const loginInfo = {
      username: loginContext.username,
      password: loginContext.password,
      clientId: this.clientInfoService.ClientInfo.clientId
    };
    return this.httpCore
      .post<AuthenticateRS>(this.apiPath.login, loginInfo)
      .pipe(
        map((response: AuthenticateRS) => {
          this.userInfoService;
          const userInfo: UserInfo = {
            //TODO: Remove toString later once api starts sending userid as string instead of number
            userId: response.data.userInfo.userID.toString(),
            emailId: response.data.userInfo.emailId,
            giveName: response.data.userInfo.firstName,
            role: response.data.userInfo.role,
            userName: response.data.userInfo.userName,
            credentials: {
              authToken: response.data.access_Token,
              refreshToken: response.data.refresh_Token,
              idToken: response.data.id_Token,
              rememberme: loginContext.remeberMe
            },
            context: null
          };
          this.userInfoService.setUserInfo(userInfo);
          this.startRefreshTokenTimer();
          return true;
        })
      );
  }

  logout(): Observable<boolean> {
    return this.httpCore
      .post(this.apiPath.logout, {
        accessToken: this.userInfoService.UserCredentials?.authToken ?? ''
      })
      .pipe(
        tap((_) => {
          this.userInfoService.setUserInfo(null);
          this.stopRefreshTokenTimer();
        }),
        map((_) => true),
        catchError(() => of(false))
      );
  }

  resetUserPassword(data: ResetPassword): Observable<NzSafeAny> {
    const resetData = {
      email: data.email,
      password: data.password,
      confirmationCode: data.confirmationCode,
      clientId: this.clientInfoService.ClientInfo.clientId
    };
    return this.httpCore.post(this.apiPath.resetPassword, resetData);
  }

  verificationLink(username?: string) {
    return this.httpCore.post(this.apiPath.verificationLink, {
      email: username,
      clientId: this.clientInfoService.ClientInfo.clientId
    });
  }
}
