export const CLIENT_VIEW = 'clients:view';
export const CLIENT_ADD = 'clients:add';
export const CLIENT_EDIT = 'clients:edit';
export const CLIENT_ENABLE_DISABLE = 'clients:enable-disable';
export const CLIENT_DELETE = 'clients:delete';
export const USER_VIEW = 'users:view';
export const USER_ADD = 'users:add';
export const USER_EDIT = 'users:edit';
export const USER_ENABLE_DISABLE = 'users:enable-disable';
export const USER_PASSWD_RESET = 'users:password reset';
export const USER_DELETE = 'users:delete';
export const STATE_VIEW = 'states:view';
export const STATE_ADD = 'states:add';
export const STATE_EDIT = 'states:edit';
export const STATE_ADD_NEW_VERSION = 'states:add-new-version';
export const STATE_APPROVE = 'states:approve';
export const STATE_ENABLE_DISABLE = 'states:enable-disable';
export const STATE_DELETE = 'states:delete';
export const BILLS_VIEW = 'leg-bills:view';
export const BILLS_ADD = 'leg-bills:add';
export const BILLS_EDIT = 'leg-bills:edit';
export const BILLS_ADD_NEW_VERSION = 'leg-bills:add-new-version';
export const BILLS_APPROVE = 'leg-bills:approve';
export const BILLS_ENABLE_DISABLE = 'leg-bills:enable-disable';
export const BILLS_DELETE = 'leg-bills:delete';
export const RULES_VIEW = 'leg-rules:view';
export const RULES_ADD = 'leg-rules:add';
export const RULES_EDIT = 'leg-rules:edit';
export const RULES_ADD_NEW_VERSION = 'leg-rules:add-new-version';
export const RULES_APPROVE = 'leg-rules:approve';
export const RULES_ENABLE_DISABLE = 'leg-rules:enable-disable';
export const RULES_DELETE = 'leg-rules:delete';
export const MANAGE_ROLES = 'manage:roles';
export const MANAGE_PERMISSIONS = 'manage:permissions';
export const DRUG_LIST = 'drugs:list';
export const DRUG_ADD = 'drugs:add';
export const DRUG_EDIT = 'drugs:edit';
export const DRUG_ENABLE_DISABLE = 'drugs:enable-disable';
export const DRUG_DELETE = 'drugs:delete';
export const DRUG_VIEW = 'drugs:view';
export const DRUGPRICEHISTORY = 'drug-price-history:view';
export const DRUGPRICEPLANNER_VIEW = 'drug-price-planner:view';
export const DRUGPRICEPLANNER_ADD = 'drug-price-planner:add';
export const DRUGPRICEPLANNER_EDIT = 'drug-price-planner:edit';
export const DRUGPRICEPLANNER_ADD_NEW_VERSION =
  'drug-price-planner:add-new-version';
export const DRUGPRICEPLANNER_APPROVE = 'drug-price-planner:approve';
export const DRUGPRICEPLANNER_ENABLE_DISABLE =
  'drug-price-planner:enable-disable';
export const DRUGPRICEPLANNER_DELETE = 'drug-price-planner:delete';

export const CLIENT_DASHBOARD_VIEW = 'client-dashboard:view';
export const CLIENT_STATE_VIEW = 'client-state:view';
export const CLIENT_PROFILE_VIEW = 'client-profile:view';
export const CLIENT_PROFILE_EDIT = 'client-profile:edit';

export const FILING_DASHBOARD_VIEW = 'filing:view';
export const FILING_EDIT = 'filing:edit';
export const FILING_APPROVE = 'filing:approve';
export const FILING_SUBMIT = 'filing:submit';
