<nz-card>
  <div>
    <ng-template #suffixIconButton>
      <span
        nz-icon
        style="cursor: pointer"
        nzType="search"
        nzTheme="outline"
        (click)="loadDashboard()"></span>
    </ng-template>
    <ng-template #suffix>
      <ng-template #indicatorTemplate
        ><span nz-icon nzType="loading"></span
      ></ng-template>
      <nz-spin
        *ngIf="isDrugListLoading"
        nzSimple
        [nzIndicator]="indicatorTemplate"></nz-spin>
    </ng-template>
    <nz-input-group
      [style.width]="'30vw'"
      nzCompact
      [nzSuffix]="suffix"
      [nzAddOnAfter]="suffixIconButton">
      <!-- <input
        placeholder="Enter drug name, NDC or HCPCS code"
        [(ngModel)]="searchText"
        nz-input
        (keyup)="onDrugsSearch($event)"
        [nzAutocomplete]="auto" /> -->
      <input
        (keyup.enter)="loadDashboard()"
        [disabled]="isLoading"
        placeholder="Enter drug name, NDC or HCPCS code"
        [(ngModel)]="searchText"
        nz-input />
    </nz-input-group>

    <nz-autocomplete #auto>
      <nz-auto-option
        *ngFor="let option of drugOptionsList"
        [nzLabel]="option.name"
        [nzValue]="option.value">
        {{ option.name }}
      </nz-auto-option>
    </nz-autocomplete>
  </div>
  <nz-spin [nzSpinning]="isLoading" class="loader"></nz-spin>
  <div #drugsContainer></div>
</nz-card>
