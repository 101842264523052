import { Pipe, PipeTransform } from '@angular/core';
import { GlobalDateFormat } from '@snap/shared/model/interfaces';
import { formatDate } from '@snap/shared/utils';

@Pipe({ name: 'snapDate' })
export class SnapDateFormat implements PipeTransform {
  transform(value: GlobalDateFormat | null) {
    if (value) {
      return formatDate(value);
    } else {
      return '';
    }
  }
}
