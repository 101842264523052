import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import CryptoJS from 'crypto-js';
import { silentEvent } from 'ng-zorro-antd/core/util';
import { Guid } from 'guid-typescript';
import { NzSafeAny } from 'ng-zorro-antd/core/types';
import { endOfDay, startOfDay } from 'date-fns';

/*Get a random integer between 1 and 100 this.randomNum(1,101)*/
const fnGetRandomNum = function getRandomNum(m: number, n: number) {
  return Math.floor(Math.random() * (m - n) + n);
};

const fnGetFile = function getFile(
  url: string,
  isBlob = false
): Promise<NzSafeAny> {
  return new Promise((resolve, reject) => {
    const client = new XMLHttpRequest();
    client.responseType = isBlob ? 'blob' : '';
    client.onreadystatechange = () => {
      if (client.readyState !== 4) {
        return;
      }
      if (client.status === 200) {
        const urlArr = client.responseURL.split('/');
        resolve({
          data: client.response,
          url: urlArr[urlArr.length - 1]
        });
      } else {
        reject(new Error(client.statusText));
      }
    };
    client.open('GET', url);
    client.send();
  });
};

const fnCheckForm = function checkForm(form: UntypedFormGroup): boolean {
  Object.keys(form.controls).forEach((key) => {
    form.controls[key].markAsDirty();
    form.controls[key].updateValueAndValidity();
  });
  return !form.invalid;
};

// clear formArray
const fnClearFormArray = function clearFormArray(formArray: UntypedFormArray) {
  while (formArray.length !== 0) {
    formArray.removeAt(0);
  }
};

const fnStopMouseEvent = function stopMouseEvent(e: MouseEvent): void {
  silentEvent(e);
  // e.stopPropagation();
  // e.preventDefault();
};

// Array object deduplication
const fnRemoveDouble = function removeDouble<T>(
  list: NzSafeAny[],
  col: NzSafeAny
): T {
  const obj: NzSafeAny = {};
  return list.reduce((cur, next) => {
    obj[next[col]] ? '' : (obj[next[col]] = true && cur.push(next));
    return cur;
  }, []);
};

// Get the last/following string of the route
const fnFormatePath = function formatePath(path: string): string {
  const newpath = path.replace(/\/[0-9]+/g, '');
  const paramIndex = newpath
    .substring(newpath.lastIndexOf('/') + 1)
    .indexOf('?');
  if (paramIndex > -1) {
    const tempPath = newpath.substring(newpath.lastIndexOf('/') + 1);
    return tempPath.substring(0, paramIndex);
  } else {
    return newpath.substring(newpath.lastIndexOf('/') + 1);
  }
};

// Get the route without parameters
const fnGetPathWithoutParam = function getPathWithoutParam(
  path: string
): string {
  const paramIndex = path.indexOf('?');
  if (paramIndex > -1) {
    return path.substring(0, paramIndex);
  }
  return path;
};

// return uuid
const fnGetUUID = function getUUID(): string {
  return Guid.create().toString();
};

const fnGetBase64 = function getBase64(
  file: File
): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

// encrypt
const fnEncrypt = function encrypt(word: NzSafeAny, keyStr: string) {
  return CryptoJS.AES.encrypt(JSON.stringify(word), keyStr).toString();
};

// decrypt
const fnDecrypt = function decrypt(word: NzSafeAny, keyStr: string) {
  const bytes = CryptoJS.AES.decrypt(word, keyStr);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

/*import {endOfDay, startOfDay} from 'date-fns';*/
const fnStartOfDay = function StartOfDay(time: number) {
  return startOfDay(time).getTime();
};

const fnEndOfDay = function EndOfDay(time: number) {
  return endOfDay(time).getTime();
};

export {
  fnDecrypt,
  fnEncrypt,
  fnGetBase64,
  fnGetPathWithoutParam,
  fnGetFile,
  fnClearFormArray,
  fnCheckForm,
  fnStopMouseEvent,
  fnFormatePath,
  fnRemoveDouble,
  fnGetRandomNum,
  fnStartOfDay,
  fnEndOfDay,
  fnGetUUID
};
