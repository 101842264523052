import { HttpRequest, HttpHandler } from '@angular/common/http';
import { ApiEndpoint } from '@snap/shared/model';
import { GetBillListRS } from '@snap/shared/model/api';
import { stringFormat } from '@snap/shared/utils';
import { Guid } from 'guid-typescript';
import { NzSafeAny } from 'ng-zorro-antd/core/types';
import { ok } from './util';
import { delay } from 'rxjs';

export class BillEndPt {
  private billEnabled: GetBillListRS = {
    requestId: Guid.create(),
    transactionId: Guid.create(),
    pageInfo: {
      total: 3,
      pageIndex: 1,
      pageSize: 10
    },
    data: [
      {
        billId: '1',
        name: 'bill1',
        stateCode: 'CA',
        stateName: 'california',
        billStatus: 'PROPOSED',
        applicabilityPeriodStartDate: '2021',
        applicabilityPeriodEndDate: '2022',
        fileUrl: 'abc.com',
        isActive: true
      },
      {
        billId: '2',
        name: 'bill2',
        stateCode: 'CA',
        stateName: 'california',
        billStatus: 'PROPOSED',
        applicabilityPeriodStartDate: '2021',
        applicabilityPeriodEndDate: '2022',
        fileUrl: 'abc.com',
        isActive: true
      },
      {
        billId: '3',
        stateCode: 'CA',
        stateName: 'california',
        name: 'bill3',
        billStatus: 'PROPOSED',
        applicabilityPeriodStartDate: '2021',
        applicabilityPeriodEndDate: '2022',
        fileUrl: 'abc.com',
        isActive: true
      }
    ],
    metadata: {
      total: 5,
      enabledCount: 3,
      disabledCount: 2
    },
    messages: []
  };
  private billDisabled = {
    requestId: Guid.create(),
    transactionId: Guid.create(),
    pageInfo: {
      total: 3,
      pageIndex: 1,
      pageSize: 10
    },
    data: [],
    billsSummary: {
      numEnabledPostfilter: 3
    },
    messages: []
  };
  private bills = {
    requestId: Guid.create(),
    transactionId: Guid.create(),
    pageInfo: {
      total: 3,
      pageIndex: 1,
      pageSize: 10
    },
    data: [
      {
        billId: '1',
        name: 'bill1',
        billStatus: 'PROPOSED',
        applicabilityPeriodStartDate: 2021,
        applicabilityPeriodEndDate: 2022,
        fileUrl: 'abc.com',
        isActive: true
      },
      {
        billId: '2',
        name: 'bill2',
        billStatus: 'PROPOSED',
        applicabilityPeriodStartDate: 2021,
        applicabilityPeriodEndDate: 2022,
        fileUrl: 'abc.com',
        isActive: true
      },
      {
        billId: '3',
        name: 'bill3',
        billStatus: 'PROPOSED',
        applicabilityPeriodStartDate: 2021,
        applicabilityPeriodEndDate: 2022,
        fileUrl: 'abc.com',
        isActive: true
      }
    ],
    billsSummary: {
      numEnabledPostfilter: 3
    },
    messages: []
  };

  constructor(
    private apiEndPt: ApiEndpoint,
    private request: HttpRequest<NzSafeAny>,
    private next: HttpHandler
  ) {}

  handleRoute() {
    const endpoint = this.apiEndPt;
    const { url, method } = this.request;
    switch (true) {
      case url.includes(stringFormat(endpoint.BILL.toString(), '')) &&
        method === 'GET':
        // return this.getBill();
        return this.next.handle(this.request);
      case url.endsWith(stringFormat(endpoint.BILL.toString(), 'list')) &&
        method === 'POST':
        // this.getBillsList(this.request.body.filter.status);
        return this.next.handle(this.request);
      case url.includes(stringFormat(endpoint.BILL.toString(), '')) &&
        method === 'POST':
        // return this.addBill();
        return this.next.handle(this.request);
      case url.endsWith(stringFormat(endpoint.BILL.toString(), '')) &&
        method === 'PUT':
        return this.next.handle(this.request);
      default:
        return this.next.handle(this.request);
    }
  }
  getBillsList(filter?: string) {
    if (filter === 'ENABLED') {
      return ok(this.billEnabled);
    } else if (filter === 'DISABLED') {
      return ok(this.billDisabled);
    } else {
      return ok(this.bills);
    }
  }
  getBill() {
    return ok({
      requestId: Guid.create(),
      transactionId: Guid.create(),
      data: [
        {
          id: '1',
          name: 'bill1',
          stateName: 'Arizona',
          billStatus: 'PROPOSED',
          presentedInYear: '2021',
          applicabilityPeriodStartDate: 1640979061,
          applicabilityPeriodEndDate: 1672425000,
          description: 'This is testing description.',
          originalUrl: 'www.sample.com',
          fileUrl: 'www.smaplefile.com',
          fileName: 'Bill1.pdf',
          createdOn: 1640979061,
          updatedOn: 1640979061
        }
      ]
    });
  }

  addBill() {
    const { body } = this.request;
    return ok({
      data: [body]
    }).pipe(delay(1000));
  }
}
