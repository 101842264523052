import { Component, Inject, OnInit } from '@angular/core';

import { Logger } from '@snap/core';
import { fromEvent, map, merge, Observable, of } from 'rxjs';
import { RouterOutlet } from '@angular/router';
import { fadeRouteAnimation } from '@snap/shared/model/animations';
import { SNAP_ENV } from '@snap/shared/model/di-tokens';
import { IEnvironment } from '@snap/shared/model/interfaces';
@Component({
  selector: 'snap-root',
  templateUrl: './app.component.html',
  animations: [fadeRouteAnimation],
})
export class AppComponent implements OnInit {
  onlineOffline: Observable<boolean>;

  constructor(@Inject(SNAP_ENV) private environment: IEnvironment) {
    this.onlineOffline = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(map(() => true)),
      fromEvent(window, 'offline').pipe(map(() => false))
    );
  }

  ngOnInit(): void {
    // Setup logger
    if (this.environment.production) {
      Logger.enableProductionMode();
    }
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet?.activatedRouteData?.['key'];
  }
}
