import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
//import { Logger } from '@snap/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
//const log = new Logger('ResetPassword');

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'snap-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.less']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  passwordVisible = false;
  confirmPasswordVisible = false;
  password?: string;
  resetPasswordForm!: UntypedFormGroup;
  routeType = '';
  routeSubscription: Subscription;
  resetKeyIdentifier = '';
  currentState!: string;
  timeLeft = 60;
  loading = false;
  username = '';
  interval!: ReturnType<typeof setTimeout>;
  customErrMsg = '';

  constructor(
    private authService: AuthService,
    private notificationService: NzNotificationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder
  ) {
    this.username = this.router?.getCurrentNavigation()?.extras.state?.['data'];
    //log.info('username', this.username);
    this.resetPasswordForm = this.fb.group({
      otp: [
        '',
        [Validators.required, Validators.minLength(6), Validators.maxLength(6)]
      ],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(7),
          Validators.maxLength(100)
        ]
      ],
      confirmPassword: ['', [Validators.required, this.confirmValidator]]
    });
    this.routeSubscription = this.activatedRoute.data.subscribe((routeData) => {
      if (routeData && routeData['firstPasswordSetup']) {
        this.routeType = 'firstPasswordSetup';
      }
    });
  }

  ngOnInit(): void {
    if (this.username && this.username.length > 0) {
      this.startTimer();
    } else if (this.routeType === 'firstPasswordSetup') {
      this.otpControl?.setValidators(null);
      this.otpControl?.updateValueAndValidity();
    } else {
      this.router.navigate(['..'], { relativeTo: this.activatedRoute });
    }
  }

  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        clearInterval(this.interval);
      }
    }, 1000);
  }

  getErrorColor(validator: string, controlName: string): string {
    const control = this.resetPasswordForm.get(controlName);
    if (control?.pristine) return '';
    if (this.resetPasswordForm.getError(validator, controlName)) return 'red';
    else if (control?.value) return 'green';
    else return '';
  }

  validateConfirmPassword(): void {
    setTimeout(() =>
      this.resetPasswordForm.controls[
        'confirmPassword'
      ].updateValueAndValidity()
    );
  }

  confirmValidator = (
    control: UntypedFormControl
  ): { [s: string]: boolean } => {
    if (!control.value) {
      return { error: true, required: true };
    } else if (
      control.value !== this.resetPasswordForm.controls['password'].value
    ) {
      return { confirm: true, error: true };
    }
    return {};
  };

  resetUserPassword() {
    if (this.resetPasswordForm.valid) {
      this.loading = true;
      this.customErrMsg = '';
      ////log.debug('submit', this.resetPasswordForm.value);

      //TODO: PICK UP USER ID FROM AUTH TOKEN
      const data = {
        email: this.username,
        password: this.resetPasswordForm.controls['password'].value,
        confirmationCode: this.otpControl?.value
      };
      this.authService.resetUserPassword(data).subscribe({
        next: () => {
          //next:(response)=>{}
          //log.debug('success' + response);
          this.loading = false;
          const toastTitle =
            this.routeType === 'firstPasswordSetup'
              ? 'Password Setup'
              : 'Password Reset';
          const toastContent =
            this.routeType === 'firstPasswordSetup'
              ? 'Password set successfully.'
              : 'Password changed successfully.';
          this.notificationService
            .success(toastTitle, toastContent, {
              nzPlacement: 'bottomRight',
              nzDuration: 2000
            })
            .onClose.subscribe(() => {
              this.router.navigate(['auth', 'login']);
            });
        },
        error: (err) => {
          this.loading = false;
          if (
            err.error &&
            err.error.messages &&
            err.error.messages.length > 0 &&
            err.error.messages[0].code === '400'
          ) {
            this.otpControl?.setErrors({
              customError: true
            });
            this.customErrMsg = err.error.messages[0].message;
          } else {
            //log.debug(err.message);
            this.notificationService.error(
              'Something went wrong!',
              'Please try again.',
              {
                nzPlacement: 'bottomRight',
                nzDuration: 5000
              }
            );
          }
        }
      });
    } else {
      Object.values(this.resetPasswordForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  resendOTP(): void {
    this.authService.verificationLink(this.username).subscribe({
      next: () => {
        this.timeLeft = 60;
        this.startTimer();
        this.notificationService.success(
          'OTP sent successfully!',
          'Please check your mailbox.',
          {
            nzPlacement: 'bottomRight',
            nzDuration: 5000
          }
        );
      },
      error: () => {
        //log.info('error occured while sending the verification link');
        this.notificationService.error(
          'Something went wrong!',
          'Please try again.',
          {
            nzPlacement: 'bottomRight',
            nzDuration: 5000
          }
        );
      }
    });
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
  }

  get otpControl(): AbstractControl | null {
    return this.resetPasswordForm.get('otp');
  }

  onCodeChanged(code: string) {
    this.otpControl?.setValue(code);
    this.otpControl?.markAsTouched();
    this.otpControl?.markAsDirty();
  }

  onCodeCompleted(code: string) {
    this.otpControl?.setValue(code);
    this.otpControl?.markAsTouched();
  }
}
