import {
  GetRulesListRS,
  GetRuleRS,
  GetRuleActionsListRS,
  GetFilingListRS
} from '@snap/shared/model/api';
import { Guid } from 'guid-typescript';

export const mockRuleTypes = {
  GENERIC: Guid.parse('9a42a877-1000-4b2d-813f-05eeee829b18'),
  NEW_DRUG_LAUNCH: Guid.parse('e7ce90b1-4e34-4463-8857-dcca8ee4fd3f'),
  ON_DEMAND_FILING: Guid.parse('4f2ad3b1-bd8f-4c23-8571-cc054195b0a9'),
  PERIODIC_FILING: Guid.parse('23ee725a-87c3-47c7-80e1-b36763451439'),
  PRICE_CHANGE: Guid.parse('4ea666e5-d000-4f90-b5b5-64110eb05e25')
};

export const mockFiling: GetFilingListRS = {
  requestId: Guid.create(),
  transactionId: Guid.create(),
  data: [
    {
      id: Guid.create(),
      name: 'Field 1',
      rule: 'WAC',
      dataType: 'String',
      summary: 'This is the description that has to be shown to user.',
      isTradeSecret: true,
      isMandatory: true,
      possibleDataValues: '1,2,3'
    },
    {
      id: Guid.create(),
      name: 'Field 2',
      rule: 'LabelerId',
      dataType: 'Number',
      summary: 'This is the description that has to be shown to user.',
      isTradeSecret: true,
      isMandatory: true,
      possibleDataValues: '1,2,3'
    },
    {
      id: Guid.create(),
      name: 'Field 3',
      rule: 'NDC',
      dataType: 'String',
      summary: 'This is the description that has to be shown to user.',
      isTradeSecret: true,
      isMandatory: true,
      possibleDataValues: '1,2,3'
    },
    {
      id: Guid.create(),
      name: 'Field 4',
      rule: 'CompanyName',
      dataType: 'String',
      summary: 'This is the description that has to be shown to user.',
      isTradeSecret: true,
      isMandatory: true,
      possibleDataValues: '1,2,3'
    }
  ],
  messages: []
};

export const mockRule: GetRuleRS = {
  requestId: Guid.create(),
  transactionId: Guid.create(),
  data: [
    {
      id: Guid.create(),
      stateCode: 'Oregon',
      ruleTypeId: mockRuleTypes.PRICE_CHANGE,
      ruleName: 'Oregon Price Change Rule',
      ruleType: {
        id: mockRuleTypes.GENERIC,
        name: 'Generic'
      },
      description:
        'This rule is derived from drug price transparency law for state of Oregon.',
      applicableStartDate: '2020-11-11T00:00:00',
      applicableEndDate: '2020-11-11T00:00:00',
      isActive: true,
      billId: Guid.create(),
      applicabilityCriteriaEnglish:
        'If the drug is BRANDED and Price Increase is greater than $10 and Average Monthly treatment cost is greater than $100',
      penaltyInformation:
        '$2,500 per day maximum for first 30 days and $5,000 per day maximum after 30 days',
      applicabilityCriteriaTimelineCondition:
        'IF Drug.BrandedType = BRANDED AND Drug.DrugPrice.IncreasePercentage > 10 AND Drug.DrugPrice.AverageMonthlyTreatmentDollarValue > $100'
    }
  ],
  messages: []
};

export const rulesAction: GetRuleActionsListRS = {
  requestId: Guid.create(),
  transactionId: Guid.create(),
  data: [
    {
      actionId: Guid.create(),
      actionType: 'Filing',
      timeLineCondition: '01-01-2022 ~ 23-03-2022',
      actionConditionEnglishVersion:
        'This is the description that has to be shown to user.',
      uploadProcessFileFormat: 'string',
      uploadProcessHelpText: 'string',
      emailProcessFileFormat: 'string',
      emailProcessHelpText: 'string',
      multiDrugSubmission: true,
      perDayValue: 0,
      maxCap: 0,
      CreateFilingTemplatePayloads: [
        {
          id: Guid.create(),
          name: 'Field 1',
          rule: 'WAC',
          dataType: 'String',
          summary: 'This is the description that has to be shown to user.',
          isTradeSecret: true,
          isMandatory: true,
          possibleDataValues: '1,2,3'
        },
        {
          id: Guid.create(),
          name: 'Field 2',
          rule: 'LabelerId',
          dataType: 'Number',
          summary: 'This is the description that has to be shown to user.',
          isTradeSecret: true,
          isMandatory: true,
          possibleDataValues: '1,2,3'
        },
        {
          id: Guid.create(),
          name: 'Field 3',
          rule: 'NDC',
          dataType: 'String',
          summary: 'This is the description that has to be shown to user.',
          isTradeSecret: true,
          isMandatory: true,
          possibleDataValues: '1,2,3'
        },
        {
          id: Guid.create(),
          name: 'Field 4',
          rule: 'CompanyName',
          dataType: 'String',
          summary: 'This is the description that has to be shown to user.',
          isTradeSecret: true,
          isMandatory: true,
          possibleDataValues: '1,2,3'
        }
      ]
    }
  ],
  messages: []
};

export const mockRules: GetRulesListRS = {
  requestId: Guid.create(),
  transactionId: Guid.create(),
  pageInfo: {
    total: 7,
    pageIndex: 1,
    pageSize: 10
  },
  data: [
    {
      id: Guid.create(),
      stateCode: 'Oregon',
      ruleTypeId: mockRuleTypes.PRICE_CHANGE,
      ruleName: 'Oregon Price Change Rule',
      description:
        'This rule is derived from drug price transparency law for state of Oregon.',
      applicableStartDate: '2020-11-11T00:00:00',
      applicableEndDate: '2020-11-11T00:00:00',
      isActive: true
    },
    {
      id: Guid.create(),
      stateCode: 'California',
      ruleTypeId: mockRuleTypes.PRICE_CHANGE,
      ruleName: 'California Price Change Rule',
      description:
        'This rule is derived from drug price transparency law for state of California.',
      applicableStartDate: '2020-11-11T00:00:00',
      applicableEndDate: '2020-11-11T00:00:00',
      isActive: true
    },
    {
      id: Guid.create(),
      stateCode: 'California',
      ruleTypeId: mockRuleTypes.NEW_DRUG_LAUNCH,
      ruleName: 'California New Drug Launch Rule',
      description:
        'This rule is derived from drug price transparency law for state of California.',
      applicableStartDate: '2020-11-11T00:00:00',
      applicableEndDate: '2020-11-11T00:00:00',
      isActive: true
    },
    {
      id: Guid.create(),
      stateCode: 'North Dakota',
      ruleTypeId: mockRuleTypes.PRICE_CHANGE,
      ruleName: 'North Dakota Price Change Rule',
      description:
        'This rule is derived from drug price transparency law for state of North Dakota.',
      applicableStartDate: '2020-11-11T00:00:00',
      applicableEndDate: '2020-11-11T00:00:00',
      isActive: true
    },
    {
      id: Guid.create(),
      stateCode: 'North Dakota',
      ruleTypeId: mockRuleTypes.NEW_DRUG_LAUNCH,
      ruleName: 'North Dakota New Drug Launch Rule',
      description:
        'This rule is derived from drug price transparency law for state of North Dakota.',
      applicableStartDate: '2020-11-11T00:00:00',
      applicableEndDate: '2020-11-11T00:00:00',
      isActive: true
    },
    {
      id: Guid.create(),
      stateCode: 'North Dakota',
      ruleTypeId: mockRuleTypes.PERIODIC_FILING,
      ruleName: 'North Dakota Periodic Rule',
      description:
        'This rule is derived from drug price transparency law for state of North Dakota.',
      applicableStartDate: '2020-11-11T00:00:00',
      applicableEndDate: '2020-11-11T00:00:00',
      isActive: true
    },
    {
      id: Guid.create(),
      stateCode: 'Utah',
      ruleTypeId: mockRuleTypes.PRICE_CHANGE,
      ruleName: 'Utah Price Change Rule',
      description:
        'This rule is derived from drug price transparency law for state of Utah.',
      applicableStartDate: '2020-11-11T00:00:00',
      applicableEndDate: '2020-11-11T00:00:00',
      isActive: true
    }
  ],
  metadata: {
    enabledCount: 7,
    disabledCount: 0,
    total: 7
  },
  messages: []
};

export const disabledRules: GetRulesListRS = {
  requestId: Guid.create(),
  transactionId: Guid.create(),
  pageInfo: {
    total: 0,
    pageIndex: 1,
    pageSize: 10
  },
  data: [],
  metadata: {
    enabledCount: 7,
    disabledCount: 0,
    total: 7
  },
  messages: []
};

export const enabledRules: GetRulesListRS = {
  requestId: Guid.create(),
  transactionId: Guid.create(),
  pageInfo: {
    total: 7,
    pageIndex: 1,
    pageSize: 10
  },
  data: [
    {
      id: Guid.create(),
      stateCode: 'Oregon',
      ruleTypeId: mockRuleTypes.PRICE_CHANGE,
      ruleName: 'Oregon Price Change Rule',
      description:
        'This rule is derived from drug price transparency law for state of Oregon.',
      applicableStartDate: '2020-11-11T00:00:00',
      applicableEndDate: '2020-11-11T00:00:00',
      isActive: true
    },
    {
      id: Guid.create(),
      stateCode: 'California',
      ruleTypeId: mockRuleTypes.PRICE_CHANGE,
      ruleName: 'California Price Change Rule',
      description:
        'This rule is derived from drug price transparency law for state of California.',
      applicableStartDate: '2020-11-11T00:00:00',
      applicableEndDate: '2020-11-11T00:00:00',
      isActive: true
    },
    {
      id: Guid.create(),
      stateCode: 'California',
      ruleTypeId: mockRuleTypes.NEW_DRUG_LAUNCH,
      ruleName: 'California New Drug Launch Rule',
      description:
        'This rule is derived from drug price transparency law for state of California.',
      applicableStartDate: '2020-11-11T00:00:00',
      applicableEndDate: '2020-11-11T00:00:00',
      isActive: true
    },
    {
      id: Guid.create(),
      stateCode: 'North Dakota',
      ruleTypeId: mockRuleTypes.PRICE_CHANGE,
      ruleName: 'North Dakota Price Change Rule',
      description:
        'This rule is derived from drug price transparency law for state of North Dakota.',
      applicableStartDate: '2020-11-11T00:00:00',
      applicableEndDate: '2020-11-11T00:00:00',
      isActive: true
    },
    {
      id: Guid.create(),
      stateCode: 'North Dakota',
      ruleTypeId: mockRuleTypes.NEW_DRUG_LAUNCH,
      ruleName: 'North Dakota New Drug Launch Rule',
      description:
        'This rule is derived from drug price transparency law for state of North Dakota.',
      applicableStartDate: '2020-11-11T00:00:00',
      applicableEndDate: '2020-11-11T00:00:00',
      isActive: true
    },
    {
      id: Guid.create(),
      stateCode: 'North Dakota',
      ruleTypeId: mockRuleTypes.PERIODIC_FILING,
      ruleName: 'North Dakota Periodic Rule',
      description:
        'This rule is derived from drug price transparency law for state of North Dakota.',
      applicableStartDate: '2020-11-11T00:00:00',
      applicableEndDate: '2020-11-11T00:00:00',
      isActive: true
    },
    {
      id: Guid.create(),
      stateCode: 'Utah',
      ruleTypeId: mockRuleTypes.PRICE_CHANGE,
      ruleName: 'Utah Price Change Rule',
      description:
        'This rule is derived from drug price transparency law for state of Utah.',
      applicableStartDate: '2020-11-11T00:00:00',
      applicableEndDate: '2020-11-11T00:00:00',
      isActive: true
    }
  ],
  metadata: {
    enabledCount: 7,
    disabledCount: 0,
    total: 7
  },
  messages: []
};

export const rulesMocks = Object.freeze({
  rulesEnabled: enabledRules,
  rulesDisabled: disabledRules,
  rules: mockRules,
  rule: mockRule,
  rulesAction: rulesAction,
  mockFiling: mockFiling
});
