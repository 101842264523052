<nz-page-header nzTitle="Pricing Insights"></nz-page-header>

<div class="page-wrap">
  <nz-tabset
    nzType="editable-card"
    nzHideAdd
    [nzSize]="'large'"
    [(nzSelectedIndex)]="selectedIndex"
    (nzClose)="closeTab($event)">
    <nz-tab [nzTitle]="'Drugs'">
      <ng-template nz-tab>
        <snap-awsqs-drugs></snap-awsqs-drugs>
      </ng-template>
    </nz-tab>
    <nz-tab [nzTitle]="'Services'">
      <ng-template nz-tab>
        <snap-awsqs-services
          (loadTab)="addDynamicTab($event)"></snap-awsqs-services>
      </ng-template>
    </nz-tab>
    <nz-tab [nzTitle]="'Devices'">
      <ng-template nz-tab>
        <snap-awsqs-devices></snap-awsqs-devices>
      </ng-template>
    </nz-tab>
    <nz-tab *ngFor="let tab of dynamicTab" nzClosable [nzTitle]="tab.title">
      <ng-template nz-tab *ngIf="tab.title.includes('MPFS')">
        <snap-awsqs-mpfs></snap-awsqs-mpfs>
      </ng-template>
      <ng-template nz-tab *ngIf="tab.title.includes('IPPS')">
        <snap-awsqs-ipps></snap-awsqs-ipps>
      </ng-template>
    </nz-tab>
  </nz-tabset>
</div>
