import users from './auth-user.json';
import states from './state-endpt.json';
export interface IUser {
  id: number;
  username: string;
  email: string;
  role: string;
  isActive: boolean;
  password: string;
  refreshTokens: string[];
}

export interface IStateInfo {
  id: number;
  name: string;
  website: string;
  isActive: boolean;
  filingMethod: string[];
}

class Store {
  public readonly AuthUsers: IUser[] = users;
  public readonly StateUsers: IStateInfo[] = states;
  
}
export const fakeStore = new Store();
