import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { withCache } from '@ngneat/cashew';
import { SNAP_ENV } from '@snap/shared/model/di-tokens';
import {
  FilingDSL,
  FilingStatusType,
  IEnvironment,
  KeyValueArray,
  RuleType
} from '@snap/shared/model/interfaces';
import { Guid } from 'guid-typescript';
import { NzSafeAny } from 'ng-zorro-antd/core/types';
import { map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DropdownDataFetchService {
  BRAND_INDICATOR: KeyValueArray<string, string> = [
    { name: 'Branded', value: 'BRANDED' },
    { name: 'Generic', value: 'GENERIC' },
    { name: 'Biosimilar', value: 'BIOSIMILAR' }
  ];

  DRUG_TYPE: KeyValueArray<string, string> = [
    { name: 'Chemical', value: 'CHEMICAL' },
    { name: 'Biological', value: 'BIOLOGICAL' }
  ];

  MEASURE_UNIT: KeyValueArray<string, string> = [
    { name: 'AHF: Anti-hemophilia factor', value: 'AHF' },
    { name: 'CAP: Capsule', value: 'CAP' },
    { name: 'SUP: Suppository', value: 'SUP' },
    { name: 'GM: Gram', value: 'GM' },
    { name: 'ML: Milliliter', value: 'ML' },
    { name: 'TAB: Tablet', value: 'TAB' },
    { name: 'TDP: Transdermal patch', value: 'TDP' },
    { name: 'EA: Each', value: 'EA' }
  ];

  FDA_APPLICATION_TYPE: KeyValueArray<string, string> = [
    { name: 'NDA', value: 'NDA' },
    { name: 'BLA', value: 'BLA' },
    { name: 'ANDA', value: 'ANDA' }
  ];

  Primary_Disease_Type: KeyValueArray<string, string> = [
    { name: 'Diabetes', value: 'DIABETES' },
    { name: 'Asthma', value: 'ASTHMA' },
    { name: 'Cancer', value: 'CANCER' },
    { name: 'Other', value: 'OTHER' }
  ];

  THERAPEUTIC_CATEGORY: KeyValueArray<string, string> = [
    { name: 'Analgesics', value: 'ANALGESICS' },
    { name: 'Anesthetics', value: 'ANESTHETICS' },
    { name: 'Antibacterials', value: 'ANTIBACTERIALS' },
    { name: 'Anticonvulsants', value: 'ANTICONVULSANTS' },
    { name: 'Antidementia Agents', value: 'ANTIDEMENTIA_AGENTS' },
    { name: 'Antidepressants', value: 'ANTIDEPRESSANTS' },
    {
      name: 'Antidotes, Deterrents, and Toxicologic Agents',
      value: 'ANTIDOTES_DETERRENTS_AND_TOXICOLOGIC_AGENTS'
    },
    { name: 'Antiemetics', value: 'ANTIEMETICS' },
    { name: 'Antifungals', value: 'ANTIFUNGALS' },
    { name: 'Antigout Agents', value: 'ANTIGOUT_AGENTS' },
    { name: 'Anti-inflammatory Agents', value: 'ANTI-INFLAMMATORY_AGENTS' },
    { name: 'Antimigraine Agents', value: 'ANTIMIGRAINE_AGENTS' },
    { name: 'Antimyasthenic Agents', value: 'ANTIMYASTHENIC_AGENTS' },
    { name: 'Antimycobacterials', value: 'ANTIMYCOBACTERIALS' },
    { name: 'Antineoplastics', value: 'ANTINEOPLASTICS' },
    { name: 'Antiparasitics', value: 'ANTIPARASITICS' },
    { name: 'Antiparkinson Agents', value: 'ANTIPARKINSON_AGENTS' },
    { name: 'Antipsychotics', value: 'ANTIPSYCHOTICS' },
    { name: 'Antispasticity Agents', value: 'ANTISPASTICITY_AGENTS' },
    { name: 'Antivirals', value: 'ANTIVIRALS' },
    { name: 'Anxiolytics', value: 'ANXIOLYTICS' },
    { name: 'Bipolar Agents', value: 'BIPOLAR_AGENTS' },
    { name: 'Blood Glucose Regulators', value: 'BLOOD_GLUCOSE_REGULATORS' },
    {
      name: 'Blood Products/Modifiers/Volume Expanders',
      value: 'BLOOD_PRODUCTS/MODIFIERS/VOLUME_EXPANDERS'
    },
    { name: 'Cardiovascular Agents', value: 'CARDIOVASCULAR_AGENTS' },
    {
      name: 'Central Nervous System Agents',
      value: 'CENTRAL_NERVOUS_SYSTEM_AGENTS'
    },
    { name: 'Dental and Oral Agents', value: 'DENTAL_AND_ORAL_AGENTS' },
    { name: 'Dermatological Agents', value: 'DERMATOLOGICAL_AGENTS' },
    {
      name: 'Enzyme Replacements/Modifiers',
      value: 'ENZYME_REPLACEMENTS/MODIFIERS'
    },
    { name: 'Gastrointestinal Agents', value: 'GASTROINTESTINAL_AGENTS' },
    { name: 'Genitourinary Agents', value: 'GENITOURINARY_AGENTS' },
    {
      name: 'Hormonal Agents, Stimulant/ Replacement/ Modifying (Adrenal)',
      value: 'HORMONAL_AGENTS_STIMULANT/_REPLACEMENT/_MODIFYING_(ADRENAL)'
    },
    {
      name: 'Hormonal Agents, Stimulant/Replacement/Modifying (Pituitary)',
      value: 'HORMONAL_AGENTS_STIMULANT/REPLACEMENT/MODIFYING_(PITUITARY)'
    },
    {
      name: 'Hormonal Agents, Stimulant/Replacement/Modifying (Prostaglandins)',
      value: 'HORMONAL_AGENTS_STIMULANT/REPLACEMENT/MODIFYING_(PROSTAGLANDINS)'
    },
    {
      name: 'Hormonal Agents, Stimulant/Replacement/Modifying (Sex Hormones/Modifiers)',
      value:
        'HORMONAL_AGENTS_STIMULANT/REPLACEMENT/MODIFYING_(SEX_HORMONES/MODIFIERS)'
    },
    {
      name: 'Hormonal Agents, Stimulant/Replacement/Modifying (Thyroid)',
      value: 'HORMONAL_AGENTS_STIMULANT/REPLACEMENT/MODIFYING_(THYROID)'
    },
    {
      name: 'Hormonal Agents, Suppressant (Adrenal)',
      value: 'HORMONAL_AGENTS_SUPPRESSANT_(ADRENAL)'
    },
    {
      name: 'Hormonal Agents, Suppressant (Parathyroid)',
      value: 'HORMONAL_AGENTS_SUPPRESSANT_(PARATHYROID)'
    },
    {
      name: 'Hormonal Agents, Suppressant (Pituitary)',
      value: 'HORMONAL_AGENTS_SUPPRESSANT_(PITUITARY)'
    },
    {
      name: 'Hormonal Agents, Suppressant (Sex Hormones/Modifiers)',
      value: 'HORMONAL_AGENTS_SUPPRESSANT_(SEX_HORMONES/MODIFIERS)'
    },
    {
      name: 'Hormonal Agents, Suppressant (Thyroid)',
      value: 'HORMONAL_AGENTS_SUPPRESSANT_(THYROID)'
    },
    { name: 'Immunological Agents', value: 'IMMUNOLOGICAL_AGENTS' },
    {
      name: 'Inflammatory Bowel Disease Agents',
      value: 'INFLAMMATORY_BOWEL_DISEASE_AGENTS'
    },
    {
      name: 'Metabolic Bone Disease Agents',
      value: 'METABOLIC_BONE_DISEASE_AGENTS'
    },
    { name: 'Ophthalmic Agents', value: 'OPHTHALMIC_AGENTS' },
    { name: 'Otic Agents', value: 'OTIC_AGENTS' },
    { name: 'Respiratory Tract Agents', value: 'RESPIRATORY_TRACT_AGENTS' },
    { name: 'Sedatives/Hypnotics', value: 'SEDATIVES/HYPNOTICS' },
    { name: 'Skeletal Muscle Relaxants', value: 'SKELETAL_MUSCLE_RELAXANTS' },
    {
      name: 'Therapeutic Nutrients/Minerals/Electrolytes',
      value: 'THERAPEUTIC_NUTRIENTS/MINERALS/ELECTROLYTES'
    }
  ];

  private readonly apiPath;
  constructor(
    @Inject(SNAP_ENV) private environment: IEnvironment,
    private httpCore: HttpClient
  ) {
    this.apiPath = {
      ruleTypes: environment.API_ENDPOINT.RULES.TYPES,
      filingStatusTypes: environment.API_ENDPOINT.FILING.STATUS_TYPES,
      filingDSL: environment.API_ENDPOINT.FILINGDSL.FILING_DSL
    };
  }

  getBrandIndicatorDDL(): Observable<KeyValueArray<string, string>> {
    return of(this.BRAND_INDICATOR);
  }

  getDrugTypeDDL(): Observable<KeyValueArray<string, string>> {
    return of(this.DRUG_TYPE);
  }

  getMeasureUnitDDL(): Observable<KeyValueArray<string, string>> {
    return of(this.MEASURE_UNIT);
  }

  getFdaAppTypeDDL(): Observable<KeyValueArray<string, string>> {
    return of(this.FDA_APPLICATION_TYPE);
  }

  getPrimaryDiseaseTypeDDL(): Observable<KeyValueArray<string, string>> {
    return of(this.Primary_Disease_Type);
  }

  getTherapeuticCategoryDDL(): Observable<KeyValueArray<string, string>> {
    return of(this.THERAPEUTIC_CATEGORY);
  }

  getRuleTypes(): Observable<Array<RuleType>> {
    return this.httpCore
      .get(this.apiPath.ruleTypes, {
        context: withCache({
          cache: true,
          ttl: 300000,
          version: 'v1',
          key: 'ruleTypes'
        })
      })
      .pipe(
        map(
          (resp: NzSafeAny): Array<RuleType> =>
            resp.data.map(
              (x: NzSafeAny) => <RuleType>{ id: Guid.parse(x.id), name: x.name }
            )
        )
      );
  }

  getFilingDSL(): Observable<Array<FilingDSL>> {
    return this.httpCore.get(this.apiPath.filingDSL.toString()).pipe(
      map(
        (resp: NzSafeAny): Array<FilingStatusType> =>
          resp.data.map(
            (x: NzSafeAny) =>
              <FilingDSL>{
                id: Guid.parse(x.id),
                name: x.name
              }
          )
      )
    );
  }

  getFilingStatusTypes(): Observable<Array<FilingStatusType>> {
    return this.httpCore
      .get(this.apiPath.filingStatusTypes, {
        context: withCache({
          cache: true,
          ttl: 300000,
          version: 'v1',
          key: 'filingStatusTypes'
        })
      })
      .pipe(
        map(
          (resp: NzSafeAny): Array<FilingStatusType> =>
            resp.data.map(
              (x: NzSafeAny) =>
                <FilingStatusType>{
                  id: Guid.parse(x.id),
                  name: x.name,
                  code: x.code
                }
            )
        )
      );
  }
}
