<!-- <div class="container">
  <div class="leftPane">
    <div class="leftPane-body">
      <div nz-row>
        <span class="clientName">Client Name</span>
      </div>
      <snap-login-form></snap-login-form>
    </div>
    <div class="leftPane-footer">
      <nz-space
        [nzSplit]="spaceSplit"
        nzAlign="center"
        nzSize="large"
        class="fullwidth footerLink">
        <ng-template #spaceSplit>
          <nz-divider nzType="vertical"></nz-divider>
        </ng-template>

        <a *nzSpaceItem nz-typography>Some Link</a>
        <a *nzSpaceItem nz-typography>Some Link</a>
      </nz-space>
    </div>
  </div>
  <div nz-col class="rightPane">
    <snap-marketing-content></snap-marketing-content>
  </div>
</div> -->

<nz-row class="container" nzAlign="middle" nzGutter="16" nzJustify="center">
  <nz-col nzXXl="17" nzXl="15" nzMd="0" nzSm="0" nzXs="0"></nz-col>
  <nz-col nzXXl="7" nzXl="9" nzMd="16" nzSm="24" nzXs="24">
    <nz-card cssOverride nzBorderless>
      <div class="title">
        <img class="logo" src="/assets/imgs/logo.svg" />
        <span>SnapTransparency</span>
      </div>
      <snap-login-form></snap-login-form>
    </nz-card>
  </nz-col>
</nz-row>
