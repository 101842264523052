import { Inject, Injectable, Optional, SkipSelf } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IAppConfiguration, IEnvironment } from '@snap/shared/model/interfaces';

import { lastValueFrom } from 'rxjs';
import { ClientBasicInfoRS } from '@snap/shared/model/api';
//import { Logger } from '@snap/core';
import { SNAP_ENV } from '@snap/shared/model/di-tokens';
//const log = new Logger('AppConfigService');
@Injectable({
  providedIn: 'platform'
})
export class AppConfigService implements IAppConfiguration {
  private readonly configUrl;
  private configPrivate;
  private isInitialized: boolean | null = null;
  currDate: Date = new Date();

  constructor(
    @Inject(SNAP_ENV) private environment: IEnvironment,
    private httpClient: HttpClient,
    @Optional() @SkipSelf() parentService: AppConfigService
  ) {
    if (parentService) {
      throw new Error('AppConfigService is already loaded.');
    }
    this.configUrl = this.environment.REMOTE_CONFIG_URL;
    this.configPrivate = this.environment;
  }

  getClientInfo(): Promise<ClientBasicInfoRS> {
    const apiPath = this.environment.API_ENDPOINT.CLIENT.BASIC_INFO;
    return lastValueFrom(
      this.httpClient.get<ClientBasicInfoRS>(apiPath, {
        params: new HttpParams().set('domain', location.hostname)
      })
    );
  }

  async init(configUrl = this.configUrl): Promise<boolean> {
    this.configPrivate = { ...this.environment };
    if (this.isInitialized == null) {
      //log.info('INIT', this.currDate.toString());

      try {
        const clientInfo = await this.getClientInfo();
        this.isInitialized = true;
        localStorage.setItem(
          'CLIENT_INFO',
          JSON.stringify(clientInfo.data[0].client)
        );
      } catch {
        this.isInitialized = false;
      }

      //log.info('LOAD', this.currDate.toString());
      try {
        const regex = new RegExp(
          'https:\\/\\/(?<Domain>[\\w@][\\w.:@]+)\\/?[\\w\\.?=%&=\\-@/$,]*',
          'gm'
        );
        if (regex.test(configUrl)) {
          const remoteConfig = await lastValueFrom(
            this.httpClient.get<IEnvironment>(configUrl)
          );
          this.configPrivate = { ...this.configPrivate, ...remoteConfig };
        }
      } catch {
        //log.error(`Unable to load remote config url ${configUrl}`);
      }
    }
    this.isInitialized = true;
    return this.isInitialized;
  }

  get config() {
    return this.configPrivate;
  }
}
