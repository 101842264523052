import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'snap-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotFoundComponent {
  backUrl: string | string[] = '/';
  constructor(private router: Router) {
    const currentNav = this.router.getCurrentNavigation();
    if (
      currentNav &&
      currentNav.previousNavigation &&
      currentNav.previousNavigation.initialUrl
    ) {
      this.backUrl = currentNav.previousNavigation.initialUrl.toString();
    }
  }
}
