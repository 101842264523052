import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { LoginContext } from '../../model/authenticate-model';
//import { Logger } from '@snap/core';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Title } from '@angular/platform-browser';
import { finalize } from 'rxjs';
import {
  clientMenus,
  menus,
  MenuStoreService,
  UserInfoService
} from '@snap/shared/services/store';

//const log = new Logger('Login');

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'snap-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.less']
})
export class LoginFormComponent implements OnInit {
  loading = false;
  showError = false;
  passwordVisible = false;
  password?: string;
  loginForm!: UntypedFormGroup;
  @Output() public loginClicked = new EventEmitter<LoginContext>();

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private titleServe: Title,
    private userInfoService: UserInfoService,
    private menuStoreService: MenuStoreService
  ) {}
  ngOnInit(): void {
    this.titleServe.setTitle(
      this.activatedRoute.snapshot.data['title'] + ' - SNAP'
    );
    this.loginForm = this.fb.group({
      username: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required]],
      remember: [true]
    });
  }

  login() {
    if (this.loginForm.valid) {
      this.loading = true;
      this.authService
        .login({
          username: this.loginForm.value.username,
          password: this.loginForm.value.password,
          remeberMe: this.loginForm.value.remember
        })
        .pipe(finalize(() => (this.loading = false)))
        .subscribe({
          next: () => {
            //log.debug('Login Successful');
            this.showError = false;
            if (this.userInfoService.UserInfo?.role !== 'SNAP_ADMIN') {
              this.menuStoreService.setMenuArrayStore(clientMenus);
              this.router.navigate(['client']);
            } else {
              this.menuStoreService.setMenuArrayStore(menus);
              this.router.navigate(['admin']);
            }
          },
          error: () => {
            this.showError = true;
            //log.debug('Login Failed');
          }
        });
    } else {
      Object.values(this.loginForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }
  forgotPassword() {
    if (this.loginForm.controls['username'].valid) {
      this.authService
        .verificationLink(this.loginForm.controls['username'].value)
        .subscribe({
          next: () => {
            this.router.navigateByUrl('/auth/reset-password', {
              state: { data: this.loginForm.value.username }
            });
          },
          error: () => {
            //log.info('error occured while sending the verification link');
          }
        });
    } else {
      if (this.loginForm.controls) {
        this.loginForm.controls['username'].markAsDirty();
        this.loginForm.controls['username'].updateValueAndValidity({
          onlySelf: true
        });
      }
    }
  }
}
