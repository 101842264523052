import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
//import { Logger } from '../services/logger.service';
import { NzSafeAny } from 'ng-zorro-antd/core/types';

//const log = new Logger('ErrorHandlerInterceptor');

/**
 * Adds a default error handler to all requests.
 */
@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<NzSafeAny>,
    next: HttpHandler
  ): Observable<HttpEvent<NzSafeAny>> {
    return next.handle(req).pipe(catchError(this.errorHandler));
  }

  public errorHandler(errorRes: HttpErrorResponse) {
    //const {
      //error: { status, error, message },
    //} = errorRes;
    // Do messaging and error handling here
    // this.snackBar.open(
    //   `Error ! ${message}`,
    //   '',
    //   ErrorInterceptor.snackBarConfig
    // );
   // log.error(
     // `Backend Error ! status: ${status}, error: ${error}, message: ${message}`
    //);

    return throwError(() => errorRes);
  }
}
