export enum FilingMechanism {
  ONLINEPORTAL = 'OnlinePortal',
  UPLOADPORTAL = 'UploadPortal',
  EMAIL = 'Email'
}

export function getStringFilingMechanism(mechanisms: FilingMechanism[]) {
  let filingMech = '';
  if (mechanisms.length) {
    mechanisms.forEach((element) => {
      switch (element) {
        case FilingMechanism.ONLINEPORTAL:
          filingMech += 'Online Portal ';
          break;
        case FilingMechanism.UPLOADPORTAL:
          filingMech += 'Upload Portal ';
          break;
        case FilingMechanism.EMAIL:
          filingMech += 'Email ';
          break;
      }
    });
  }
  return filingMech || '';
}
