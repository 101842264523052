import {
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { KeyValue } from '@snap/shared/model/interfaces';
import { DestroyService } from '@snap/shared/services';
import { NzSafeAny } from 'ng-zorro-antd/core/types';
import { BehaviorSubject, takeUntil } from 'rxjs';
import { SnapSharedService } from '../../../../services';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DynamicTab, ViewServiceDetails } from '../../model/PriceGenieURL';

@Component({
  selector: 'snap-awsqs-services',
  templateUrl: './awsqs-services.component.html',
  styleUrls: ['./awsqs-services.component.less'],
  viewProviders: [DestroyService]
})
export class AwsqsServicesComponent implements OnInit, OnDestroy {
  @ViewChild('serviceContainer', { static: true })
  serviceContainer!: ElementRef<HTMLDivElement>;
  DynamicTab = DynamicTab;
  @Output() loadTab = new EventEmitter<ViewServiceDetails>();

  searchChange$ = new BehaviorSubject('');
  drugOptionsList: KeyValue<string, string>[] = [];
  isDrugListLoading = false;
  dashboard: NzSafeAny;
  isLoading = false;
  searchText = '';
  url = '';
  showMPFSButton = false;
  showIPPSButton = false;
  constructor(
    private service: SnapSharedService,
    private destroy$: DestroyService,
    private messageService: NzMessageService
  ) {}

  ngOnInit(): void {
    // const drugsOptionList$: Observable<KeyValue<string, string>[]> =
    //   this.searchChange$
    //     .asObservable()
    //     .pipe(debounceTime(500))
    //     .pipe(switchMap(this.getDrugAutocompleteList));
    // drugsOptionList$.subscribe((data) => {
    //   this.drugOptionsList = data;
    //   this.isDrugListLoading = false;
    // });
    if (this.service.serviceSearchText.value) {
      this.searchText = this.service.serviceSearchText.value;
      this.loadDashboard();
    }
  }

  // getDrugAutocompleteList = (
  //   _name: string
  // ): Observable<KeyValue<string, string>[]> =>
  //   of([
  //     {
  //       name: 'Test',
  //       value: 'Test'
  //     }
  //   ]);
  // this.drugService.getDrugsListDDL(1, 10, name).pipe(
  //   catchError(() => of([])),
  //   map((res: KeyValue<string, string>[]) => res)
  // );

  // onDrugsSearch(event: NzSafeAny): void {
  //   if (event.currentTarget.value.length > 0) {
  //     this.isDrugListLoading = true;
  //     this.searchChange$.next(event.currentTarget.value);
  //   }
  // }

  loadDashboard() {
    if (this.searchText) {
      this.isLoading = true;
      if (this.dashboard && this.url) {
        this.dashboard.setParameters({ service: this.searchText });
        this.isLoading = false;
        if (Number(this.searchText) && this.searchText.length <= 3) {
          this.showIPPSButton = true;
          this.showMPFSButton = false;
          window.scrollTo(0, document.body.scrollHeight);
        } else {
          this.showMPFSButton = true;
          this.showIPPSButton = false;
        }
      } else {
        this.service
          .getPriceGenieUrl()
          .pipe(takeUntil(this.destroy$))
          .subscribe((embeddedURL) => {
            const url = new URL(embeddedURL.serviceDashboardId);
            this.url = url.href;
            const options = {
              url: embeddedURL.serviceDashboardId,
              container: this.serviceContainer.nativeElement,
              parameters: {
                service: ''
              },
              scrolling: 'yes',
              width: '100%',
              height: 'AutoFit',
              loadingHeight: '700px',
              printEnabled: true,
              resetDisabled: true,
              undoRedoDisabled: true,
              iframeResizeOnSheetChange: false,
              footerPaddingEnabled: true
            };
            this.dashboard = QuickSightEmbedding.embedDashboard(options);
            this.dashboard.on('error', (payload: NzSafeAny) => {
              this.messageService.error(`Error Occured : ${payload.errorCode}`);
            });
            this.dashboard.on('load', (_payload: NzSafeAny) => {
              this.dashboard.setParameters({ service: this.searchText });
              this.isLoading = false;

              if (Number(this.searchText) && this.searchText.length <= 3) {
                window.scrollTo(0, document.body.scrollHeight);
                this.showIPPSButton = true;
                this.showMPFSButton = false;
              } else {
                this.showMPFSButton = true;
                this.showIPPSButton = false;
              }
            });
          });
      }
    }
  }

  viewDetailsFor(tab: DynamicTab): void {
    this.loadTab.emit({
      tab: tab,
      searchText: this.searchText
    });
  }

  ngOnDestroy(): void {
    this.service.serviceSearchText.next(this.searchText);
  }
}
