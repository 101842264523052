<nz-input-group
  [nzSuffix]="inputClearTpl"
  [nzAddOnAfter]="suffixIconButton"
  nzSize="default"
  nzSearch>
  <input
    type="text"
    nz-input
    #searchText
    placeholder="Search Text"
    (change)="changeHandler(searchText.value)" />
</nz-input-group>

<ng-template #suffixIconButton>
  <button
    nz-button
    nzType="default"
    nzSearch
    class="searchcBtn"
    (click)="search(searchText.value)">
    <i nz-icon nzType="search"></i>
  </button>
</ng-template>

<ng-template #inputClearTpl>
  <i
    nz-icon
    nzTheme="fill"
    nzType="close-circle"
    *ngIf="searchText.value"
    (click)="clearSearchText()"></i>
</ng-template>
