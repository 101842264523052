import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  GetPreSignedS3UrlRS,
  GetPriceGenieURLRS,
  SupersetDashboardData,
  SupersetDashboardDataRQ,
  SupersetDashboardDataRS
} from '@snap/shared/model/api';
import { SNAP_ENV } from '@snap/shared/model/di-tokens';
import { IEnvironment } from '@snap/shared/model/interfaces';
import { NzSafeAny } from 'ng-zorro-antd/core/types';
import { Observable, map, BehaviorSubject, switchMap } from 'rxjs';
import { PriceGenieURL } from '../components/awsqs-price-genie/model/PriceGenieURL';

@Injectable({
  providedIn: 'root'
})
export class SnapSharedService {
  private readonly apiPath;
  drugsSearchText = new BehaviorSubject<string>('');
  deviceSearchText = new BehaviorSubject<string>('');
  serviceSearchText = new BehaviorSubject<string>('');

  constructor(
    @Inject(SNAP_ENV) private environment: IEnvironment,
    private httpCore: HttpClient
  ) {
    this.apiPath = {
      getPriceGenieUrl: this.environment.API_ENDPOINT.AUX.PRICE_GENIE_URL,
      getS3PreSignedUrl:
        this.environment.API_ENDPOINT.S3_UPLOAD.S3_PRESIGNED_URL,
      getSuperSetParams: this.environment.API_ENDPOINT.AUX.SUPERSET_PARAMS
    };
  }

  getPriceGenieUrl(): Observable<PriceGenieURL> {
    return this.httpCore
      .get<GetPriceGenieURLRS>(this.apiPath.getPriceGenieUrl)
      .pipe(map((resp: GetPriceGenieURLRS) => resp.data[0].embedUrls));
  }

  getSupersetDashboardParams(
    request: SupersetDashboardDataRQ
  ): Observable<SupersetDashboardData> {
    return this.httpCore
      .post<SupersetDashboardDataRS>(this.apiPath.getSuperSetParams, request)
      .pipe(map((resp: SupersetDashboardDataRS) => resp.data[0]));
  }

  uploadToS3(file: NzSafeAny, contentType: string): Observable<NzSafeAny> {
    const headers = new HttpHeaders({
      'Content-Type': contentType
    });

    return this.httpCore
      .post<NzSafeAny>(this.apiPath.getS3PreSignedUrl, {
        contentType: contentType,
        fileName: file.name
      })
      .pipe(
        switchMap((res: GetPreSignedS3UrlRS) => {
          const url = res.data[0].signedS3Link;
          const req = new HttpRequest('PUT', url, file, {
            headers: headers
          });
          return this.httpCore.request(req);
        })
      );
  }
}
