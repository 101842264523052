import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { NzSafeAny } from 'ng-zorro-antd/core/types';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';

@Directive({
  selector: '[snapAuth]',
})
export class AuthDirective {
  @Input()
  set snapAuth(authCode: string[] | undefined) {
    if (Array.isArray(authCode) && authCode.length > 0) {
      const bExists = authCode.some(
        (x) => !!this.permissionSrv.getPermission(x) || !! this.roleSrv.hasOnlyRoles(x)
      );
      this.show(bExists);
    } else {
      this.show(true);
    }
  }

  constructor(
    private permissionSrv: NgxPermissionsService,
    private roleSrv: NgxRolesService,
    private templateRef: TemplateRef<NzSafeAny>,
    private viewContainerRef: ViewContainerRef
  ) {}

  private show(hasAuth: boolean): void {
    hasAuth
      ? this.viewContainerRef.createEmbeddedView(this.templateRef)
      : this.viewContainerRef.clear();
  }
}
