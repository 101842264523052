import { Component } from '@angular/core';

@Component({
  selector: 'snap-superset-pricegenie',
  templateUrl: './superset-pricegenie.component.html',
  styleUrls: ['./superset-pricegenie.component.less']
})
export class SupersetPricegenieComponent {
  dashboardName = 'Pricing Insights';
}
