<!-- <router-outlet></router-outlet> -->
<ng-container
  *ngIf="
    onlineOffline | async;
    then thenTemplate;
    else elseTemplate
  "></ng-container>
<ng-template #thenTemplate>
  <div [@fadeRouteAnimation]="prepareRoute(outlet)">
    <router-outlet #outlet="outlet"></router-outlet>
  </div>
</ng-template>
<ng-template #elseTemplate>
  <snap-no-internet></snap-no-internet>
</ng-template>
