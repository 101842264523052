/** Is it a number */
export function isNum(value: string | number): boolean {
  return /^((-?\d+\.\d+)|(-?\d+)|(-?\.\d+))$/.test(value.toString());
}

/** Is it an integer */
export function isInt(value: string | number): boolean {
  return (
    isNum(value) &&
    parseInt(value.toString(), 10).toString() === value.toString()
  );
}

/** Is it a decimal */
export function isDecimal(value: string | number): boolean {
  return isNum(value) && !isInt(value);
}

/** Is it an ID card */
export function isIdCard(value: string): boolean {
  return /(^\d{15}$)|(^\d{17}([0-9]|X)$)/i.test(value);
}

/** Is the phone number */
export function isMobile(value: string): boolean {
  return /^\(?([0-9]{3})\)?[-.\s]?([0-9]{3})[-.\s]?([0-9]{4})$/.test(value);
}

/** Is it a phone number */
export function isTelPhone(value: string): boolean {
  return /^(0\d{2,3}-?)?\d{7,8}$/.test(value);
}

/** Is the email address */
export function isEmail(value: string): boolean {
  return /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/.test(value);
}

/** Password consists of 6 to 20 uppercase and lowercase letters */
export function isPasswordPass(value: string): boolean {
  const regTure = /^[^\s]{6,20}$/;
  const regFalse = /^\d+$/;
  return regTure.test(value) && !regFalse.test(value);
}

/** Whether the URL address */
export function isUrl(url: string): boolean {
  const regex =
    /(((?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\\.-]+)+(?:|[-;:&=\\+\\$,\\w]+@)[A -Za-z0-9.-_]+)((?:\/[\\+~%\\.\\w\\-_]*)?\\??(?:[-\\ +=&;%@.\\w_]*)#?(?:[\\w]*))?)$/gm;
  return regex.test(url);
}

export function isMultiEmail(emailsString: string): boolean {
  const regex =
    /^ *\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)* *(?:; *\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)* *)*$/gm;

  return regex.test(emailsString);
}

export function isNdcCode(ndcString: string) {
  const regex = /\d{5}-\d{4}-\d{2}/g;
  return regex.test(ndcString);
}
