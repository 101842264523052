<div class="container">
  <div>
    <div nz-row>
      <div nz-col nzFlex="45px" class="snapIcon"></div>
    </div>
    <div nz-row class="paneHeading">
      <span>Are you SNAP ready?</span>
    </div>
    <div nz-row class="paneDetail">
      <span>Systematic</span>
      <span>Notifications</span>
      <span>About</span>
      <span>Policy</span>
    </div>
  </div>
  <div>
    <div class="paneFooter">
      <span>Facing issues?</span>
      <span>
        Reach out to us at
        <a href="mailto:support@snapdragonls.com" class="file-link-color"
          >support@snapdragonls.com</a
        ></span
      >
    </div>
  </div>
</div>
