import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@snap/auth';
//import {QuicklinkStrategy} from "ngx-quicklink";
import { AppGuard } from '@snap/core';
import {
  AccessDeniedComponent,
  LandingPageComponent,
  NotFoundComponent,
} from '@snap/shared/lib';

const routes: Routes = [
  {
    path: '404',
    component: NotFoundComponent,
    data: { key: 'not-found', title: 'Not Found' },
  },
  {
    path: '403',
    component: AccessDeniedComponent,
    data: { key: 'access-denied', title: 'Access Denied' },
  },
  {
    path: 'auth',
    loadChildren: () => import('@snap/auth').then((m) => m.AuthModule),
    canLoad: [AppGuard],
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('@snap/layout/default').then((m) => m.LayoutAdminRoutingModule),
    canLoad: [AppGuard],
    canActivateChild: [AuthGuard]
  },
  {
    path: 'client',
    loadChildren: () =>
      import('@snap/layout/default').then((m) => m.LayoutClientRoutingModule),
    canLoad: [AppGuard],
    canActivateChild: [AuthGuard]
  },
  {
    path: '',
    component: LandingPageComponent,
    data: { key: 'landing-page', title: 'Welcome' },
  },
  { path: '**', redirectTo: '404', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      //preloadingStrategy: QuicklinkStrategy,
      scrollPositionRestoration: 'top',
      //initialNavigation: 'enabled',
      // initialNavigation: 'enabledBlocking',
      //useHash: true
      //enableTracing:true
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
