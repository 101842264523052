import { HttpRequest, HttpHandler } from '@angular/common/http';
import { ApiEndpoint } from '@snap/shared/model';
import { stringFormat } from '@snap/shared/utils';
import { NzSafeAny } from 'ng-zorro-antd/core/types';
import { ok } from './util';
import { delay } from 'rxjs';
import { rulesAction, rulesMocks } from './rules-endpt-mocks';
import {
  GetFilingListRS,
  GetRuleActionsListRS,
  GetRuleRS,
  GetRulesListRS,
  IRule
} from '@snap/shared/model/api';

export class RulesEndPt {
  private readonly rules: GetRulesListRS = rulesMocks.rules;
  private readonly disabledRules: GetRulesListRS = rulesMocks.rulesDisabled;
  private readonly enabledRules: GetRulesListRS = rulesMocks.rulesEnabled;
  private readonly rule: GetRuleRS = rulesMocks.rule;
  private readonly rulesAction: GetRuleActionsListRS = rulesMocks.rulesAction;
  private readonly mockFiling: GetFilingListRS = rulesMocks.mockFiling;

  constructor(
    private apiEndPt: ApiEndpoint,
    private request: HttpRequest<NzSafeAny>,
    private next: HttpHandler
  ) {}

  handleRoute() {
    const endpoint = this.apiEndPt;
    const { url, method } = this.request;
    switch (true) {
      // case url.endsWith(stringFormat(endpoint.RULES.toString(), 'list')) &&
      //   method === 'POST':
      //   return this.getRulesList(
      //     this.request.body.filters.isActive,
      //     this.request.body.filters.Name,
      //     this.request.body.page.pageNumber,
      //     this.request.body.page.pageSize
      //   );
      // case url.endsWith(
      //   stringFormat(endpoint.RULES.toString(), 'actions/list')
      // ) && method === 'POST':
      //   return this.getActionsList();
      case url.endsWith(
        stringFormat(endpoint.RULES.toString(), 'filing/list')
      ) && method === 'POST':
        return this.getFilingList();
      // case url.endsWith(stringFormat(endpoint.RULES.toString(), '123')) &&
      //   method === 'GET':
      //   return this.getRule();
      // case url.endsWith(stringFormat(endpoint.RULES.toString(), 'add')) &&
      //   method === 'POST':
      //   return this.addRule();
      // case url.endsWith(
      //   stringFormat(endpoint.RULES.toString(), '123/add-action')
      // ) && method === 'POST':
      //   return this.addAction();
      case url.endsWith(
        stringFormat(endpoint.RULES.toString(), 'filing/1/1')
      ) && method === 'GET':
        return this.getFiling();
      // case url.includes(
      //   stringFormat(endpoint.RULES.toString(), '3565a4f8-06a3-40fc-9e4a-3aa371b791e4/action/1')
      // ) && method === 'GET':
      //   return this.getRuleAction();
      // return this.next.handle(this.request);
      //return this.next.handle(this.request);
      // case url.includes(stringFormat(endpoint.RULES.toString(), '')) &&
      //   method === 'PUT':
      //   return this.updateRule();
      // return this.next.handle(this.request);
      default:
        return this.next.handle(this.request);
    }
  }

  addRule() {
    const { body } = this.request;
    return ok({
      data: [body]
    }).pipe(delay(1000));
  }

  addAction() {
    const { body } = this.request;
    return ok({
      data: [body]
    }).pipe(delay(1000));
  }

  getFiling() {
    return ok(this.mockFiling.data[0]).pipe(delay(1000));
  }

  getFilingList() {
    return ok(this.mockFiling).pipe(delay(1000));
  }

  getRuleAction() {
    return ok(rulesAction.data[0]).pipe(delay(1000));
  }

  getActionsList() {
    return ok(this.rulesAction).pipe(delay(1000));
  }

  getRulesList(
    filter: string,
    searchText: string,
    pageIndex: number,
    pageSize: number
  ) {
    const start: number = (pageIndex - 1) * pageSize;
    const end: number = start + pageSize;
    if (filter) {
      return this.getOperatedResults(
        searchText,
        pageIndex,
        pageSize,
        this.enabledRules,
        start,
        end
      );
    } else if (filter === 'DISABLED') {
      return this.getOperatedResults(
        searchText,
        pageIndex,
        pageSize,
        this.disabledRules,
        start,
        end
      );
    } else {
      return this.getOperatedResults(
        searchText,
        pageIndex,
        pageSize,
        this.rules,
        start,
        end
      );
    }
  }

  getOperatedResults = (
    searchText: string,
    pageIndex: number,
    pageSize: number,
    response: GetRulesListRS,
    start: number,
    end: number
  ) => {
    const filteredResult: GetRulesListRS = this.filterResult(
      JSON.parse(JSON.stringify(response)),
      searchText
    );
    filteredResult.pageInfo = {
      total: filteredResult.data.length,
      pageIndex: pageIndex,
      pageSize: pageSize
    };
    filteredResult.data = filteredResult.data.slice(start, end);
    return ok(filteredResult).pipe(delay(1000));
  };

  filterResult = (response: GetRulesListRS, searchText: string) => {
    const res: GetRulesListRS = JSON.parse(JSON.stringify(response));
    let rules: IRule[] = JSON.parse(JSON.stringify(response.data));
    if (searchText) {
      rules = rules?.filter((rule: IRule) => {
        return rule.ruleName.includes(searchText);
      });
      res.data = rules;
      return res;
    } else {
      return response;
    }
  };

  getRule() {
    return ok(this.rule).pipe(delay(1000));
  }

  updateRule() {
    return ok({
      message: 'successfully updated the rule'
    }).pipe(delay(1000));
  }
}
