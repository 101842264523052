import { STATES } from './states';
export { STATES };

import { ROLES } from './security/roles';
export { ROLES };

//ENUMS
export * from './enums';

//export type LogLevel = 'debug' | 'info' | 'warn' | 'error';
/* define constants */

// request header token pre
export const TOKENPRE = 'Bearer';
export const TOKENKEY = 'Authorization';
export const ISNIGHTKEY = 'IsNightKey';
export const THEMEOPTIONSKEY = 'ThemeOptionsKey';
export const PAGE_SIZE = 2000;
// The left menu becomes the maximum width of the over mode
export const SIDECOLLAPSEDMAXWIDTH = 700;
// The top menu becomes the maximum width of the over mode
export const TOPCOLLAPSEDMAXWIDTH = 1130;
export const NDC_CODE_SIZE = 11;
export const NDC_CODE_DASH_LOCATIONS = [5, 9];
export const DATE_FORMAT_MM_DD_YYYY = 'MM/dd/yyyy';

export * as PERMISSIONS from './security/perm-constants';
export * from './security/roles-permissions';
