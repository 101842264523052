<div class="container">
  <div class="leftPane">
    <div>
      <snap-forgot-password></snap-forgot-password>
    </div>
    <div style="align-self: center">
      <nz-space
        [nzSplit]="spaceSplit"
        nzAlign="center"
        nzSize="large"
        class="fullwidth footerLink">
        <ng-template #spaceSplit>
          <nz-divider nzType="vertical"></nz-divider>
        </ng-template>

        <a *nzSpaceItem nz-typography>Some Link</a>
        <a *nzSpaceItem nz-typography>Some Link</a>
      </nz-space>
    </div>
  </div>
  <div nz-col class="rightPane">
    <snap-marketing-content></snap-marketing-content>
  </div>
</div>
