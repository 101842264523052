//type OptionsFlags<Type> = {
//    [Property in keyof Type]: string;
//};
//let s : OptionsFlags<FeatureFlags> = new FeatureFlags();

class ClientAPI {
  constructor(private apiPrefix: string) {}
  toString() {
    return `${this.apiPrefix}/api/backend/client/{0}`;
  }
  get BASIC_INFO() {
    return `${this.apiPrefix}/api/login/client/basic-info`;
  }
}

class AuthAPI {
  constructor(private apiPrefix: string) {}
  get LOGIN(): string {
    return `${this.apiPrefix}/api/login/user/authenticate`;
  }
  get LOGOUT() {
    return `${this.apiPrefix}/api/backend/user/logout`;
  }
  get REFRESH_TOKEN() {
    return `${this.apiPrefix}/api/backend/user/refreshtoken`;
  }
  get RESET_PASSWORD() {
    return `${this.apiPrefix}/api/login/user/password/resetpassword`;
  }
  get EMAIL_PASSWORD_RESET_CODE() {
    return `${this.apiPrefix}/api/login/user/password/forgotpassword`;
  }
}

class UserAPI {
  constructor(private apiPrefix: string) {}
  toString() {
    return `${this.apiPrefix}/api/backend/user/{0}`;
  }
  get BASIC_INFO() {
    return `${this.apiPrefix}/api/login/client/basic-info`;
  }
}

class BillAPI {
  constructor(private apiPrefix: string) {}
  toString() {
    return `${this.apiPrefix}/api/backend/legislationBill/{0}`;
  }
}

class RULESAPI {
  constructor(private apiPrefix: string) {}
  toString() {
    return `${this.apiPrefix}/api/backend/LegislationRules/{0}`;
  }
  get TYPES() {
    return `${this.apiPrefix}/api/backend/ruletype/list`;
  }
}

class StateAPI {
  constructor(private apiPrefix: string) {}
  toString() {
    return `${this.apiPrefix}/api/backend/states/{0}`;
  }
}

class ClientDashboardAPI {
  constructor(private apiPrefix: string) {}
  toString() {
    return `${this.apiPrefix}/api/backend/dashboard/{0}`;
  }
}

class DrugsAPI {
  constructor(private apiPrefix: string) {}
  toString() {
    return `${this.apiPrefix}/api/backend/drug/{0}`;
  }
}

class DrugPriceAPI {
  constructor(private apiPrefix: string) {}
  toString() {
    return `${this.apiPrefix}/api/backend/drugprice/{0}`;
  }
  get CHECK_COMPLIANCE() {
    return `${this.apiPrefix}/api/backend/compliance/evaluation-check`;
  }
}

class AuxAPI {
  constructor(private apiPrefix: string) {}
  get LEGISLATION_RULES_BY_STATE() {
    return `${this.apiPrefix}/api/backend/LegislationRules/laws`;
  }

  get COMPLIANCE_STATS() {
    return `${this.apiPrefix}/api/backend/states/ComplianceDashboardCounts`;
  }

  get PRICE_GENIE_URL() {
    return `${this.apiPrefix}/api/backend/quicksight/pricegenie`;
  }

  get SUPERSET_PARAMS() {
    return `${this.apiPrefix}/api/backend/superset`;
  }
}

class FilingAPI {
  constructor(private apiPrefix: string) {}
  toString() {
    return `${this.apiPrefix}/api/backend/filing/{0}`;
  }

  get STATUS_TYPES() {
    return `${this.apiPrefix}/api/backend/workflowstatus/list`;
  }

  get DRUG_FILING_RISK_STATUS() {
    return `${this.apiPrefix}/api/backend/filing/status/counts/{0}`;
  }

  get DRUG_STATE_FILING_STATUS() {
    return `${this.apiPrefix}/api/backend/filing/status/{0}`;
  }

  get DRUG_STATE_FILING_MAP() {
    return `${this.apiPrefix}/api/backend/filing/state-map/{0}`;
  }
}

class FilingDSL {
  constructor(private apiPrefix: string) {}
  toString() {
    return `${this.apiPrefix}/api/backend/FilingDSL/{0}`;
  }
  get FILING_DSL() {
    return `${this.apiPrefix}/api/backend/FilingDSL/list`;
  }
}

class NDC {
  constructor(private apiPrefix: string) {}
  toString() {
    return `${this.apiPrefix}/api/ndc/{0}`;
  }
}

class S3_UPLOAD {
  constructor(private apiPrefix: string) {}
  get S3_PRESIGNED_URL() {
    return `${this.apiPrefix}/api/backend/s3/signedUrl`;
  }
}

export class ApiEndpoint {
  private apiPrefix = '';
  public readonly CLIENT: ClientAPI;
  public readonly AUTH: AuthAPI;
  public readonly USER: UserAPI;
  public readonly STATE: StateAPI;
  public readonly BILL: BillAPI;
  public readonly RULES: RULESAPI;
  public readonly CLIENTDASHBOARD: ClientDashboardAPI;
  public readonly DRUGS: DrugsAPI;
  public readonly DRUGPRICE: DrugPriceAPI;
  public readonly AUX: AuxAPI;
  public readonly FILING: FilingAPI;
  public readonly FILINGDSL: FilingDSL;
  public readonly NDC: NDC;
  public readonly S3_UPLOAD: S3_UPLOAD;
  constructor(private basePath: string, private prefix: string) {
    this.apiPrefix = `${this.basePath}/${this.prefix}`;
    this.CLIENT = new ClientAPI(this.apiPrefix);
    this.AUTH = new AuthAPI(this.apiPrefix);
    this.USER = new UserAPI(this.apiPrefix);
    this.STATE = new StateAPI(this.apiPrefix);
    this.BILL = new BillAPI(this.apiPrefix);
    this.RULES = new RULESAPI(this.apiPrefix);
    this.CLIENTDASHBOARD = new ClientDashboardAPI(this.apiPrefix);
    this.DRUGS = new DrugsAPI(this.apiPrefix);
    this.DRUGPRICE = new DrugPriceAPI(this.apiPrefix);
    this.AUX = new AuxAPI(this.apiPrefix);
    this.FILING = new FilingAPI(this.apiPrefix);
    this.FILINGDSL = new FilingDSL(this.apiPrefix);
    this.NDC = new NDC(this.apiPrefix);
    this.S3_UPLOAD = new S3_UPLOAD(this.apiPrefix);
  }
}
