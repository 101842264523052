import { HttpRequest, HttpHandler } from '@angular/common/http';
import { ApiEndpoint } from '@snap/shared/model';
import { AuthenticateRS, RefreshTokenRS } from '@snap/shared/model/api';
import { Guid } from 'guid-typescript';
import { NzSafeAny } from 'ng-zorro-antd/core/types';
import { fakeStore } from './store';
import { ok, error, unauthorized, isLoggedIn } from './util';

export class AuthEndPt {
  constructor(
    private apiEndPt: ApiEndpoint,
    private request: HttpRequest<NzSafeAny>,
    private next: HttpHandler
  ) {}

  handleRoute() {
    const endpoint = this.apiEndPt;
    const { url, method } = this.request;
    switch (true) {
      case url.endsWith(endpoint.AUTH.LOGIN) && method === 'POST':
        //return this.authenticate();
        return this.next.handle(this.request);
      case url.endsWith(endpoint.AUTH.LOGOUT) && method === 'POST':
        // return this.revokeToken();
        return this.next.handle(this.request);
      case url.endsWith(endpoint.AUTH.REFRESH_TOKEN) && method === 'POST':
        // return this.refreshToken();
        return this.next.handle(this.request);
      case url.endsWith(endpoint.AUTH.RESET_PASSWORD) && method === 'POST':
        return this.next.handle(this.request);
      case url.endsWith(endpoint.AUTH.EMAIL_PASSWORD_RESET_CODE) &&
        method === 'POST':
        return this.next.handle(this.request);
      default:
        return this.next.handle(this.request);
    }
  }

  authenticate() {
    const { username, password } = this.request.body;
    const users = fakeStore.AuthUsers;
    const user = users.find(
      (x) => x.username === username && x.password === password
    );

    if (!user) return error('Username or password is incorrect');

    // add refresh token to user
    user.refreshTokens.push(this.generateRefreshToken());

    const tkns = this.generateJwtToken();
    const res: AuthenticateRS = {
      requestId: Guid.create(),
      transactionId: Guid.create(),
      data: {
        access_Token: tkns[0],
        refresh_Token: tkns[1],
        id_Token: tkns[2],
        userInfo: {
          userID: Guid.create().toString(),
          userName: 'vishal.n@snapdragonls.com',
          emailId: 'vishal.n@snapdragonls.com',
          firstName: 'Vishal',
          lastName: 'Vishal',
          role: 'CLIENT_ADMIN'
        }
      },
      messages: [
        {
          code: 0,
          message: ''
        }
      ]
    };
    return ok({ res });
  }

  resetPassword() {
    const { id, password } = this.request.body;
    const users = fakeStore.AuthUsers;
    const user = users.filter((u: NzSafeAny) => u.id == id)[0];
    if (user) {
      user.password = password;
      return ok({ success: true, message: 'Password Change Successfully' });
    } else {
      return unauthorized();
    }
  }

  refreshToken() {
    const refreshToken = this.getRefreshToken();

    if (!refreshToken) return unauthorized();
    const users = fakeStore.AuthUsers;
    const user = users.find((x) => x.refreshTokens.includes(refreshToken));

    if (!user) return unauthorized();

    // replace old refresh token with a new one and save
    user.refreshTokens = user.refreshTokens.filter((x) => x !== refreshToken);
    user.refreshTokens.push(this.generateRefreshToken());
    // eslint-disable-next-line no-console
    console.log('REFRESH TOKEN CALLED');

    return ok({
      requestId: Guid.create(),
      transactionId: Guid.create(),
      data: [
        {
          access_Token: this.generateJwtToken()[0],
          id_Token: this.generateRefreshToken()[1]
        }
      ],
      messages: []
    } as RefreshTokenRS);
  }

  revokeToken() {
    if (!isLoggedIn(this.request.headers)) return unauthorized();

    const refreshToken = this.getRefreshToken();
    const users = fakeStore.AuthUsers;
    const user = users.find((x) => x.refreshTokens.includes(refreshToken));
    if (user) {
      // revoke token and save
      user.refreshTokens = user.refreshTokens.filter((x) => x !== refreshToken);
    }
    return ok();
  }

  generateRefreshToken() {
    return '-|REFRESH_TOKEN|-';
    const token = new Date().getTime().toString();

    // add token cookie that expires in 7 days
    const expires = new Date(
      Date.now() + 7 * 24 * 60 * 60 * 1000
    ).toUTCString();
    document.cookie = `RefreshToken=${token}; expires=${expires}; path=/`;

    return token;
  }

  getRefreshToken(): NzSafeAny {
    return '-|REFRESH_TOKEN|-';

    // get refresh token from cookie
    return (
      document.cookie.split(';').find((x) => x.includes('RefreshToken')) || '='
    ).split('=')[1];
  }

  generateJwtToken() {
    const accessToken =
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJodHRwOi8vWU9VUl9ET01BSU4vIiwic3ViIjoiYXV0aDB8MTIzNDU2IiwiYXVkIjoiWU9VUl9DTElFTlRfSUQiLCJleHAiOjE2NTI3MDAzMzUsImlhdCI6MTMxMTI4MDk3MCwibmFtZSI6IkphbmUgRG9lIiwiZ2l2ZW5fbmFtZSI6IkphbmUiLCJmYW1pbHlfbmFtZSI6IkRvZSIsImdlbmRlciI6ImZlbWFsZSIsImJpcnRoZGF0ZSI6IjAwMDAtMTAtMzEiLCJlbWFpbCI6ImphbmVkb2VAZXhhbXBsZS5jb20iLCJwaWN0dXJlIjoiaHR0cDovL2V4YW1wbGUuY29tL2phbmVkb2UvbWUuanBnIn0.iy0ggerQhT6L1SEx1N0M_-A996sQizS0cw30r5-HiFE';

    return [accessToken, accessToken, accessToken];
  }
}
