import { Injectable, Optional, SkipSelf } from '@angular/core';
import { CanActivateChild, Router, UrlTree } from '@angular/router';
//import { Logger } from '@snap/core';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
//const log = new Logger('AuthenticationGuard');
@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivateChild {
  constructor(
    private router: Router,
    private authService: AuthService,
    @Optional() @SkipSelf() parentGuard: AuthGuard
  ) {
    if (parentGuard) {
      throw new Error('AuthGuard is already loaded.');
    }
  }

  canActivateChild():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (!this.authService.isAuthenticated) {
      //log.debug('Not authenticated, redirecting and adding redirect url...');
      this.router.navigate(['auth', 'login']);
      return false;
    }
    return true;
  }
}
