export interface ViewServiceDetails {
  tab: DynamicTab;
  searchText: string;
}

export enum DynamicTab {
  MPFS = 'MPFS',
  IPPS = 'IPPS'
}

export interface PriceGenieURL {
  deviceDashboardId: string;
  serviceDashboardId: string;
  ippsDashboardId: string;
  mpfsDashboardId: string;
  drugDashboardId: string;
}
