import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DestroyService } from '@snap/shared/services';
import { NzSafeAny } from 'ng-zorro-antd/core/types';
import { takeUntil } from 'rxjs';
import { SnapSharedService } from '../../../../services';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'snap-awsqs-ipps',
  templateUrl: './awsqs-ipps.component.html',
  styleUrls: ['./awsqs-ipps.component.less'],
  viewProviders: [DestroyService]
})
export class AwsqsIppsComponent implements OnInit {
  @ViewChild('ippsContainer', { static: true })
  ippsContainer!: ElementRef<HTMLDivElement>;

  dashboard: NzSafeAny;
  searchText = '';
  url = '';
  isLoading = false;

  constructor(
    private service: SnapSharedService,
    private destroy$: DestroyService,
    private messageService: NzMessageService
  ) {}

  ngOnInit(): void {
    if (this.service.serviceSearchText.value) {
      this.searchText = this.service.serviceSearchText.value;
      this.loadDashboard();
    }
  }

  loadDashboard() {
    if (this.searchText) {
      this.isLoading = true;
      if (this.dashboard && this.url) {
        this.dashboard.setParameters({ IPPS: this.searchText });
        this.isLoading = false;
      } else {
        this.service
          .getPriceGenieUrl()
          .pipe(takeUntil(this.destroy$))
          .subscribe((embeddedURL) => {
            const url = new URL(embeddedURL.ippsDashboardId);
            this.url = url.href;
            const options = {
              url: embeddedURL.ippsDashboardId,
              container: this.ippsContainer.nativeElement,
              parameters: {
                IPPS: ''
              },
              scrolling: 'yes',
              width: '100%',
              height: 'AutoFit',
              loadingHeight: '700px',
              printEnabled: true,
              resetDisabled: true,
              undoRedoDisabled: true,
              iframeResizeOnSheetChange: false,
              statePersistenceEnabled: true,
              footerPaddingEnabled: true
            };
            this.dashboard = QuickSightEmbedding.embedDashboard(options);
            this.dashboard.on('error', (payload: NzSafeAny) => {
              this.messageService.error(`Error Occured : ${payload.errorCode}`);
            });
            this.dashboard.on('load', (_payload: NzSafeAny) => {
              this.dashboard.setParameters({ IPPS: this.searchText });
              this.isLoading = false;
            });
          });
      }
    }
  }
}
