import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { NzSafeAny } from 'ng-zorro-antd/core/types';
import {
  isDecimal,
  isIdCard,
  isInt,
  isMobile,
  isMultiEmail,
  isNum,
  isUrl,
  isNdcCode
} from './validate';

/** A set of daily validators */
export class _Validators {
  private static isEmptyInputValue(value: NzSafeAny): boolean {
    /**
     * Check if the object is a string or array before evaluating the length attribute.
     * This avoids falsely rejecting objects that contain a custom length attribute.
     * For example, the object {id: 1, length: 0, width: 0} should not be returned as empty.
     */
    return (
      value == null ||
      ((typeof value === 'string' || Array.isArray(value)) &&
        value.length === 0)
    );
  }

  /** Is it a number */
  static num(control: AbstractControl): ValidationErrors | null {
    return _Validators.isEmptyInputValue(control.value) || isNum(control.value)
      ? null
      : { num: true };
  }

  /** Is it an integer */
  static int(control: AbstractControl): ValidationErrors | null {
    return _Validators.isEmptyInputValue(control.value) || isInt(control.value)
      ? null
      : { int: true };
  }

  /** Is it a decimal */
  static decimal(control: AbstractControl): ValidationErrors | null {
    return _Validators.isEmptyInputValue(control.value) ||
      isDecimal(control.value)
      ? null
      : { decimal: true };
  }

  /** Is it an ID card */
  static idCard(control: AbstractControl): ValidationErrors | null {
    return _Validators.isEmptyInputValue(control.value) ||
      isIdCard(control.value)
      ? null
      : { idCard: true };
  }

  /** Is the phone number */
  static mobile(control: AbstractControl): ValidationErrors | null {
    return _Validators.isEmptyInputValue(control.value) ||
      isMobile(control.value)
      ? null
      : { mobile: true };
  }

  /** Whether the URL address */
  static url(control: AbstractControl): ValidationErrors | null {
    return _Validators.isEmptyInputValue(control.value) || isUrl(control.value)
      ? null
      : { url: true };
  }

  /** Wheather string is valid email separated by ; */
  static multipleEmails(control: AbstractControl): ValidationErrors | null {
    return _Validators.isEmptyInputValue(control.value) ||
      isMultiEmail(control.value)
      ? null
      : { multiEmail: true };
  }

  static ndcCode(control: AbstractControl): ValidationErrors | null {
    return _Validators.isEmptyInputValue(control.value) ||
      isNdcCode(control.value)
      ? null
      : { ndcCode: true };
  }

  static conditionalValidator(
    condtion: (control: AbstractControl) => boolean,
    validator: ValidatorFn | null
  ): ValidatorFn {
    return (control: AbstractControl) => {
      return condtion(control) && validator != null ? validator(control) : null;
    };
  }
}
