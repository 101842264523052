import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DestroyService } from '@snap/shared/services';
import { finalize, takeUntil } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { embedDashboard } from '@superset-ui/embedded-sdk';
import { SnapSharedService } from '../../services';
import {
  SupersetDashboardData,
  SupersetDashboardDataRQ
} from '@snap/shared/model/api';

@Component({
  selector: 'snap-superset-dashboard[dashboardName]',
  templateUrl: './superset-dashboard.component.html',
  styleUrls: ['./superset-dashboard.component.less']
})
export class SupersetDashboardComponent implements OnInit {
  @ViewChild('superset', { static: true })
  drugsContainer!: ElementRef<HTMLDivElement>;

  @Input() dashboardName!: string;

  isLoading = false;
  constructor(
    private service: SnapSharedService,
    private destroy$: DestroyService,
    private messageService: NzMessageService
  ) {}

  ngOnInit(): void {
    this.loadDashboard();
  }

  loadDashboard() {
    const request: SupersetDashboardDataRQ = {
      resource: {
        name: this.dashboardName,
        type: 'Dashboard'
      },
      user: {
        first_name: 'test',
        last_name: 'user',
        username: 'test'
      },
      rlsfilter: {}
    };

    this.isLoading = true;

    this.service
      .getSupersetDashboardParams(request)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((res: SupersetDashboardData) => {
        embedDashboard({
          id: res.dashboardId, // given by the Superset embedding UI
          supersetDomain: res.supersetDomain,
          mountPoint: this.drugsContainer.nativeElement, // any html element that can contain an iframe
          fetchGuestToken: async () => res.token,
          dashboardUiConfig: {} // dashboard UI config: hideTitle, hideTab, hideChartControls (optional)
        }).then(() => {
          const parent = this.drugsContainer.nativeElement;
          const iframe = <HTMLIFrameElement>parent.children[0];
          iframe.style.height = '100vh';
          iframe.style.width = '100%';
        });
      });
  }
}
