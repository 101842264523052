export enum PT_LAW_STATUS {
  ACTIVE = 'Active',
  PROPOSED = 'Proposed'
}

export enum ACTION_TYPE {
  FILING = 'Filing',
  NOTICE = 'Notice'
}

export function getString_PT_LAW_STATUS(pt: PT_LAW_STATUS) {
  switch (pt) {
    case PT_LAW_STATUS.ACTIVE:
      return 'Active';
    case PT_LAW_STATUS.PROPOSED:
      return 'Proposed';
    default:
      return 'None';
  }
}

export * from './filingFormat';
export * from './riskType';
export * from './FilingStatus';
export * from './filingMechanism';
