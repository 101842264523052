<div class="container">
  <nz-result
    nzStatus="404"
    nzTitle="404"
    nzSubTitle="Sorry, the page you visited does not exist.">
    <div nz-result-extra>
      <a nz-button nzType="primary" [routerLink]="backUrl">{{
        backUrl === '/' ? 'Go Home' : 'Go Back'
      }}</a>
    </div>
  </nz-result>
</div>
