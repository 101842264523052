import { Guid } from 'guid-typescript';

/*
 * Generic interface
 * */
export interface KeyValue<T, K> {
  name: T;
  value: K;
}

export type KeyValueArray<T, K> = Array<KeyValue<T, K>>;

export type GlobalDateFormat = string; //CurrentDateFormat is UTC

export interface State {
  name: string;
  coordinates: [number, number];
  code: string;
}

export type RuleType = {
  id: Guid;
  name: string;
};

export type FilingStatusType = {
  id: Guid;
  name: string;
  code: string;
};

export type FilingDSL = {
  id: Guid;
  name: string;
};

export interface AutoSuggestType {
  label: string;
  value: string;
  groupLabel: string;
}

// // select drop down
// export interface OptionsInterface {
//   value: number | string;
//   label: string;
// }

// // list search
// export interface SearchCommonVO<T> {
//   pageNum: number;
//   pageSize: number;
//   filters?: T;
// }

// // pagination
// export interface PageInfo<T> {
//   pageNum: number;
//   pageSize: number;
//   size?: number;
//   orderBy?: string;
//   startRow?: number;
//   endRow?: number;
//   total: number;
//   pages?: number;
//   list: Array<T>;
//   firstPage?: number;
//   prePage?: number;
//   nextPage?: number;
//   lastPage?: number;
//   isFirstPage?: boolean;
//   isLastPage?: boolean;
//   hasPreviousPage?: boolean;
//   hasNextPage?: boolean;
//   navigatePages?: number;
//   navigatepageNums?: number[];
// }
