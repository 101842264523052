import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SimpleReuseStrategy } from './simple-reuse-strategy';
import { BehaviorSubject, Observable } from 'rxjs';
import { fnFormatePath, fnGetPathWithoutParam } from '@snap/shared/utils';

export interface TabModel {
  title: string;
  path: string;
  relatedLink: string[];
}

/*
 * Services for tab operations
 * */
@Injectable({
  providedIn: 'root',
})
export class TabService {
  private tabArray$ = new BehaviorSubject<TabModel[]>([]);
  private tabArray: TabModel[] = [];
  private currSelectedIndexTab = 0;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  getTabArray$(): Observable<TabModel[]> {
    return this.tabArray$.asObservable();
  }

  setTabArray$(tabArray: TabModel[]): void {
    this.tabArray$.next(tabArray);
  }

  setTabsSourceData() {
    this.setTabArray$(this.tabArray);
  }

  clearTabs(): void {
    this.tabArray = [];
    this.setTabsSourceData();
  }

  addTab(param: TabModel, isNewTabDetailPage = false): void {
    this.tabArray.forEach((tab) => {
      // The submenu of the route, for example, the title of the user form route needs to be the same as the title of the route of the user form details component
      if (tab.title === param.title && !isNewTabDetailPage) {
        tab.path = param.path;
      }
    });

    if (!this.tabArray.find((value) => value.path === param.path)) {
      this.tabArray.push(param);
    }
    this.setTabsSourceData();
  }

  getTabArray(): TabModel[] {
    return this.tabArray;
  }

  changeTabTitle(title: string): void {
    this.tabArray[this.getCurrentTabIndex()].title = title;
    this.setTabArray$(this.tabArray);
  }

  // Right click to remove all tabs on the right
  delRightTab(tabPath: string, index: number): void {
    const temp = this.tabArray.filter((item, tabindex) => {
      return tabindex > index;
    });
    // Remove the right tab from the right click
    this.tabArray.length = index + 1;
    temp.forEach(({ path, relatedLink }) => {
      // The relatedLink array saves related routes, and solves the bug of "click the close button on which page to clear the saved state" when there are details pages in the route to jump to the route
      const linkArray = [...relatedLink, this.getPathKey(path)];
      linkArray.forEach((item) => {
        SimpleReuseStrategy.deleteRouteSnapshot(item);
      });
    });
    if (index < this.currSelectedIndexTab) {
      SimpleReuseStrategy.waitDelete = this.getPathKey(
        ''
        //TODO:CHECK THIS WHAT IT DOES
        //this.activatedRoute['_routerState'].snapshot.url
      );
      this.router.navigateByUrl(this.tabArray[index].path);
    }
    this.setTabsSourceData();
  }

  // Right click to remove all tabs on the left
  /*
   * @params index The tab index where the current mouse right click is located
   * */
  delLeftTab(tabPath: string, index: number): void {
    // tab to delete
    const temp = this.tabArray.filter((item, tabindex) => {
      return tabindex < index;
    });

    // Process the index relationship first
    if (this.currSelectedIndexTab === index) {
      this.currSelectedIndexTab = 0;
    } else if (this.currSelectedIndexTab < index) {
      // If the tab index of the mouse click is greater than the current index, you need to put the path of the current page in waitDelete
      SimpleReuseStrategy.waitDelete = this.getPathKey(
        this.tabArray[this.currSelectedIndexTab].path
      );
      this.currSelectedIndexTab = 0;
    } else if (this.currSelectedIndexTab > index) {
      this.currSelectedIndexTab = this.currSelectedIndexTab - temp.length;
    }
    // remaining tabs
    this.tabArray = this.tabArray.splice(temp.length);
    temp.forEach(({ path, relatedLink }) => {
      // The relatedLink array saves related routes, and solves the bug of "click the close button on which page to clear the saved state" when there are details pages in the route to jump to the route
      const linkArray = [...relatedLink, this.getPathKey(path)];
      linkArray.forEach((item) => {
        SimpleReuseStrategy.deleteRouteSnapshot(item);
      });
    });
    this.setTabsSourceData();
    this.router.navigateByUrl(this.tabArray[this.currSelectedIndexTab].path);
  }

  // Right click to remove other tabs
  delOtharTab(path: string, index: number): void {
    for (let i = 0; i < this.tabArray.length; i++) {
      if (this.tabArray[i].path !== path) {
        this.tabArray.splice(i, 1);
        i--;
      }
    }
    const tempPath = fnFormatePath(path);
    for (const i in SimpleReuseStrategy.handlers) {
      if (i !== tempPath) {
        SimpleReuseStrategy.deleteRouteSnapshot(i);
      }
    }
    if (index !== this.currSelectedIndexTab) {
      SimpleReuseStrategy.waitDelete = fnFormatePath(
        ''
        //TODO:CHECK THIS WHAT IT DOES
        //this.activatedRoute['_routerState'].snapshot.url
      );
    }
    this.router.navigateByUrl(path);
    this.setTabsSourceData();
  }

  getPathKey(path: string): string {
    const tempPath = fnFormatePath(path);
    const pathParam = this.router.parseUrl(path).queryParams;
    let pathParamString = '';
    if (Object.keys(pathParam).length > 0) {
      pathParamString = JSON.stringify(this.router.parseUrl(path).queryParams);
    }
    return tempPath + pathParamString;
  }

  // Click the x icon on the tab label to delete the tab action, right click to delete the current tab action
  delTab(tab: TabModel, index: number): void {
    const pathKey = this.getPathKey(tab.path);
    // remove the currently selected tab
    if (index === this.currSelectedIndexTab) {
      this.tabArray.splice(index, 1);
      this.currSelectedIndexTab = index - 1 < 0 ? 0 : index - 1;
      this.router.navigateByUrl(this.tabArray[this.currSelectedIndexTab].path);
      // Cache the current path in reuse-strategy.ts, if it is the current path, do not cache the current route
      SimpleReuseStrategy.waitDelete = pathKey;
    } else if (index < this.currSelectedIndexTab) {
      this.tabArray.splice(index, 1);
      this.currSelectedIndexTab = this.currSelectedIndexTab - 1;
    } else if (index > this.currSelectedIndexTab) {
      // remove the tab to the right of the current tab
      this.tabArray.splice(index, 1);
    }
    // This operation solves the problem of saving the state of two pages, such as the details page in the list page, the list page and the details page, which can only be removed.
    // The bug in the status of the tab that is closed on the current page
    const beDeltabArray = [...tab.relatedLink, pathKey];
    beDeltabArray.forEach((item) =>
      SimpleReuseStrategy.deleteRouteSnapshot(item)
    );
    this.setTabsSourceData();
  }

  findIndex(path: string): number {
    const current = this.tabArray.findIndex((tabItem) => {
      return path === tabItem.path;
    });
    this.currSelectedIndexTab = current;
    return current;
  }

  refresh(): void {
    const sourceUrl = this.router.url;
    // Only the current tab will be refreshed, if it involves the details in the tab page, the page will not be refreshed
    const currentRoute = fnGetPathWithoutParam(sourceUrl);
    const queryParams = this.router.parseUrl(sourceUrl).queryParams;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      SimpleReuseStrategy.deleteRouteSnapshot(this.getPathKey(sourceUrl));
      this.router.navigate([currentRoute], { queryParams });
    });
  }

  getCurrentTabIndex(): number {
    return this.currSelectedIndexTab;
  }
}
