<div class="container">
  <nz-result
    nzStatus="403"
    nzTitle="403"
    nzSubTitle="Sorry, you are not authorized to access this page.">
    <div nz-result-extra>
      <a nz-button nzType="primary" [routerLink]="backUrl">{{
        backUrl === '/' ? 'Go Home' : 'Go Back'
      }}</a>
    </div>
  </nz-result>
</div>
