import { HttpRequest, HttpHandler } from '@angular/common/http';
import { ApiEndpoint } from '@snap/shared/model';
import { NzSafeAny } from 'ng-zorro-antd/core/types';
import { ok } from './util';
import { delay } from 'rxjs';
import { Guid } from 'guid-typescript';
import {
  AddDocumentRS,
  AddNoteRS,
  GetDocumentsList,
  GetDynamicFilingData,
  GetNotesList,
  GetStateFilingInfoListRS,
  StateFilingRiskStatusRS
} from '@snap/shared/model/api';
import { ACTION_TYPE, RISK_TYPE } from '@snap/shared/model/constants';

const mockRuleTypes = {
  GENERIC: Guid.parse('9a42a877-1000-4b2d-813f-05eeee829b18'),
  NEW_DRUG_LAUNCH: Guid.parse('e7ce90b1-4e34-4463-8857-dcca8ee4fd3f'),
  ON_DEMAND_FILING: Guid.parse('4f2ad3b1-bd8f-4c23-8571-cc054195b0a9'),
  PERIODIC_FILING: Guid.parse('23ee725a-87c3-47c7-80e1-b36763451439'),
  PRICE_CHANGE: Guid.parse('4ea666e5-d000-4f90-b5b5-64110eb05e25')
};

const mockFilingStatusTypes = {
  APPROVAL_PENDING: Guid.parse('d890b2dc-2943-407c-bab5-97158a349099'),
  APPROVED: Guid.parse('1b8ca296-4ec4-4566-9511-844aa4ce3b23'),
  IN_PROGRESS: Guid.parse('33f3a022-6420-48f1-a993-1bb1bcdaf32d'),
  UPCOMING: Guid.parse('5a3d3b7a-df44-431c-b2cf-7ddb56f6d48d'),
  DUE_PASSED: Guid.parse('fa082f4a-cefe-45d5-a0ec-b33c97975037'),
  REJECTED: Guid.parse('5e518b38-4506-4bb6-b1cd-ea2f3f34367d')
};

export class FilingEndPt {
  filingList: GetStateFilingInfoListRS = {
    requestId: Guid.create(),
    transactionId: Guid.create(),
    messages: [],
    data: [
      {
        id: Guid.create(),
        deadLine: '2020-11-11T00:00:00',
        drugNames: ['Pantoprazole'],
        filingType: mockRuleTypes.GENERIC,
        ndc: ['127356671'],
        stateCode: 'NM',
        statusId: mockFilingStatusTypes.APPROVAL_PENDING,
        filingRiskStatus: RISK_TYPE.HIGH,
        actionId: Guid.create(),
        actionType: ACTION_TYPE.FILING,
        actualPenalty: 0.0,
        applicabilityText: 'test'
      },
      {
        id: Guid.create(),
        deadLine: '2020-11-11T00:00:00',
        drugNames: ['Buprenorphine'],
        filingType: mockRuleTypes.NEW_DRUG_LAUNCH,
        ndc: ['213213848'],
        stateCode: 'WA',
        statusId: mockFilingStatusTypes.APPROVED,
        filingRiskStatus: RISK_TYPE.LOW,
        actionId: Guid.create(),
        actionType: ACTION_TYPE.FILING,
        actualPenalty: 0.0,
        applicabilityText: 'test'
      },
      {
        id: Guid.create(),
        deadLine: '2020-11-11T00:00:00',
        drugNames: [
          'Buprenorphine',
          'Buprenorphine',
          'Buprenorphine',
          'Buprenorphine'
        ],
        filingType: mockRuleTypes.ON_DEMAND_FILING,
        ndc: ['213213848, 213213848, 213213848, 213213848'],
        stateCode: 'WA',
        statusId: mockFilingStatusTypes.UPCOMING,
        filingRiskStatus: RISK_TYPE.MODERATE,
        actionId: Guid.create(),
        actionType: ACTION_TYPE.NOTICE,
        actualPenalty: 0.0,
        applicabilityText: 'test'
      },
      {
        id: Guid.create(),
        deadLine: '2020-11-11T00:00:00',
        drugNames: ['All'],
        filingType: mockRuleTypes.PERIODIC_FILING,
        ndc: ['All'],
        stateCode: 'WA',
        statusId: mockFilingStatusTypes.IN_PROGRESS,
        filingRiskStatus: RISK_TYPE.LOW,
        actionId: Guid.create(),
        actionType: ACTION_TYPE.FILING,
        actualPenalty: 0.0,
        applicabilityText: 'test'
      }
    ],
    metadata: {
      totalCount: 4,
      upomingCount: 1,
      inProgressCount: 1,
      approvalPendingCount: 1,
      approvedCount: 1,
      duePassedCount: 0
    },
    pageInfo: {
      pageIndex: 1,
      pageSize: 4,
      total: 22
    }
  };

  dynamicFiling: GetDynamicFilingData = {
    messages: [],
    transactionId: Guid.create(),
    requestId: Guid.create(),
    header: {
      stateCode: 'TX',
      statusId: mockFilingStatusTypes.IN_PROGRESS,
      dueDate: '2020-11-11T00:00:00',
      actionId: Guid.create(),
      ruleTypeId: Guid.create(),
      actionType: ACTION_TYPE.FILING,
      applicabilityText: 'test',
      actualPenalty: '123'
    },
    data: [
      {
        filingId: Guid.create(),
        filingJson: '{}',
        ndcId: Guid.create(),
        violatedRuleId: Guid.create(),
        ndc: '1234567',
        tradeName: 'Test Trade Name',
        isFilingJsonUserModified: false
      }
    ]
  };

  documentsList: GetDocumentsList = {
    messages: [],
    transactionId: Guid.create(),
    requestId: Guid.create(),
    metadata: {
      total: 4,
      enabledCount: 1,
      disabledCount: 1
    },
    pageInfo: {
      pageIndex: 1,
      pageSize: 4,
      total: 22
    },
    data: [
      {
        filingId: Guid.create(),
        id: Guid.create(),
        Comment: '',
        name: 'Document 1',
        docLink: 'http://localhost:4200/assets/imgs/logo.svg',
        uploadedBy: 'Client 1',
        uploadedOn: '2020-11-11T00:00:00',
        role: 'Admin',
        username: 'UserName'
      },
      {
        filingId: Guid.create(),
        id: Guid.create(),
        Comment: '',
        name: 'Document 2',
        docLink: 'http://localhost:4200/assets/imgs/logo.svg',
        uploadedBy: 'Client 2',
        uploadedOn: '2020-11-11T00:00:00',
        role: 'Admin',
        username: 'UserName'
      },
      {
        filingId: Guid.create(),
        id: Guid.create(),
        Comment: '',
        name: 'Document 3',
        docLink: 'http://localhost:4200/assets/imgs/logo.svg',
        uploadedBy: 'Client 3',
        uploadedOn: '2020-11-11T00:00:00',
        username: 'userName',
        role: 'Admin'
      },
      {
        filingId: Guid.create(),
        id: Guid.create(),
        Comment: '',
        name: 'Document 4',
        docLink: 'http://localhost:4200/assets/imgs/logo.svg',
        uploadedBy: 'Client 4',
        uploadedOn: '2020-11-11T00:00:00',
        role: 'Admin',
        username: 'userName'
      }
    ]
  };

  notesList: GetNotesList = {
    messages: [],
    transactionId: Guid.create(),
    requestId: Guid.create(),
    metadata: {
      total: 4,
      enabledCount: 1,
      disabledCount: 1
    },
    pageInfo: {
      pageIndex: 1,
      pageSize: 4,
      total: 22
    },
    data: [
      {
        note: 'this is sample note 1',
        updatedBy: 'User 1',
        createdOn: '2020-11-11T00:00:00',
        filingId: Guid.create(),
        updatedByUserName: 'UserName'
      },
      {
        note: 'this is sample note 2',
        updatedBy: 'User 2',
        createdOn: '2020-11-11T00:00:00',
        filingId: Guid.create(),
        updatedByUserName: 'UserName'
      },
      {
        note: 'this is sample note 3',
        updatedBy: 'User 3',
        createdOn: '2020-11-11T00:00:00',
        filingId: Guid.create(),
        updatedByUserName: 'UserName'
      },
      {
        note: 'this is sample note 4',
        updatedBy: 'User 4',
        createdOn: '2020-11-11T00:00:00',
        filingId: Guid.create(),
        updatedByUserName: 'UserName'
      }
    ]
  };

  newDocument: AddDocumentRS = {
    messages: [],
    transactionId: Guid.create(),
    requestId: Guid.create(),
    data: []
  };

  newNote: AddNoteRS = {
    messages: [],
    transactionId: Guid.create(),
    requestId: Guid.create(),
    data: []
  };

  constructor(
    private apiEndPt: ApiEndpoint,
    private request: HttpRequest<NzSafeAny>,
    private next: HttpHandler
  ) {}

  getFilingRiskStatus() {
    return ok(<StateFilingRiskStatusRS>{
      messages: [],
      requestId: Guid.create(),
      transactionId: Guid.create(),
      data: [
        {
          high: 3,
          moderate: 2,
          low: 2
        }
      ]
    }).pipe(delay(1000));
  }

  handleRoute() {
    //const { url, method } = this.request;
    switch (true) {
      // case url.endsWith('attachment/list') && method === 'POST':
      //   return this.getDocuments();
      // case url.endsWith('attachment') && method === 'POST':
      //   return this.addDocument();

      // case url.endsWith('note/list') && method === 'POST':
      //   return this.getNotes();
      // case url.endsWith('note') && method === 'POST':
      //   return this.addNote();

      // case url.startsWith(stringFormat(endpoint.FILING.toString(), '')) &&
      //   method === 'GET':
      //   return this.getDynamicFiling();
      // case url.endsWith(stringFormat(endpoint.FILING.toString(), 'list')) &&
      //   method === 'POST':
      //   return this.getFiling();
      // case url.endsWith(
      //   stringFormat(
      //     endpoint.FILING.toString(),
      //     '990130fa-320c-6389-44ca-e5851cd86618'
      //   )
      // ) && method === 'GET':
      //   return this.getDynamicFiling();
      //return this.next.handle(this.request);
      default:
        return this.next.handle(this.request);
    }
  }

  getDynamicFiling() {
    return ok(this.dynamicFiling).pipe(delay(1000));
  }

  getFiling() {
    return ok(this.filingList).pipe(delay(1000));
  }

  getDocuments() {
    return ok(this.documentsList).pipe(delay(1000));
  }

  getNotes() {
    return ok(this.notesList).pipe(delay(1000));
  }

  addDocument() {
    return ok(this.newDocument).pipe(delay(1000));
  }

  addNote() {
    return ok(this.newNote).pipe(delay(1000));
  }
}
