import { HttpRequest, HttpHandler } from '@angular/common/http';
import { ApiEndpoint } from '@snap/shared/model';
import { stringFormat } from '@snap/shared/utils';
import { Guid } from 'guid-typescript';
import { NzSafeAny } from 'ng-zorro-antd/core/types';
import { ok } from './util';
import { delay } from 'rxjs';
import {
  AddTodoRS,
  GetFilingStatusListRS,
  GetStatePriceTransparencyRequirementListRS,
  GetStateTransparencyLawStatus,
  GetStatsRS,
  TodoByMonth
} from '@snap/shared/model/api';
import { RISK_TYPE } from '@snap/shared/model/constants';

const statsRs: GetStatsRS = {
  requestId: Guid.create(),
  transactionId: Guid.create(),
  data: [
    {
      statusId: Guid.create(),
      statusName: 'Not Started',
      count: 3,
      totalPenalty: 0
    },
    {
      statusId: Guid.create(),
      statusName: 'Passed Due',
      count: 0,
      totalPenalty: 0
    }
  ],
  messages: []
};

const getStateTransparencyLawStatus: GetStateTransparencyLawStatus = {
  messages: [],
  requestId: Guid.create(),
  transactionId: Guid.create(),
  states: [
    {
      name: 'Alabama',
      hasLaw: true,
      isLawProposed: false,
      rulesApplicable: ['Periodic Filing']
    },
    {
      name: 'Alaska',
      hasLaw: false,
      isLawProposed: false,
      rulesApplicable: ['Periodic Filing']
    },
    {
      name: 'Arizona',
      hasLaw: false,
      isLawProposed: false,
      rulesApplicable: ['Periodic Filing']
    },
    {
      name: 'Arkansas',
      hasLaw: false,
      isLawProposed: false,
      rulesApplicable: ['Periodic Filing']
    },
    {
      name: 'California',
      hasLaw: true,
      isLawProposed: false,
      rulesApplicable: ['Periodic Filing']
    },
    {
      name: 'Colorado',
      hasLaw: true,
      isLawProposed: false,
      rulesApplicable: ['Periodic Filing']
    },
    {
      name: 'Connecticut',
      hasLaw: true,
      isLawProposed: false,
      rulesApplicable: ['Periodic Filing']
    },
    {
      name: 'Delaware',
      hasLaw: false,
      isLawProposed: false,
      rulesApplicable: ['Periodic Filing']
    },
    {
      name: 'District of Columbia',
      hasLaw: false,
      isLawProposed: false,
      rulesApplicable: ['Periodic Filing']
    },
    {
      name: 'Florida',
      hasLaw: false,
      isLawProposed: false,
      rulesApplicable: ['Periodic Filing']
    },
    {
      name: 'Georgia',
      hasLaw: false,
      isLawProposed: true,
      rulesApplicable: ['Price Change Filing']
    },
    {
      name: 'Hawaii',
      hasLaw: false,
      isLawProposed: false,
      rulesApplicable: ['Periodic Filing']
    },
    {
      name: 'Idaho',
      hasLaw: false,
      isLawProposed: false,
      rulesApplicable: ['Periodic Filing']
    },
    {
      name: 'Illinois',
      hasLaw: false,
      isLawProposed: false,
      rulesApplicable: ['Periodic Filing']
    },
    {
      name: 'Indiana',
      hasLaw: false,
      isLawProposed: false,
      rulesApplicable: ['Periodic Filing']
    },
    {
      name: 'Iowa',
      hasLaw: false,
      isLawProposed: false,
      rulesApplicable: ['Periodic Filing']
    },
    {
      name: 'Kansas',
      hasLaw: false,
      isLawProposed: false,
      rulesApplicable: ['Periodic Filing']
    },
    {
      name: 'Kentucky',
      hasLaw: false,
      isLawProposed: false,
      rulesApplicable: ['Periodic Filing']
    },
    {
      name: 'Louisiana',
      hasLaw: true,
      isLawProposed: false,
      rulesApplicable: ['Periodic Filing']
    },
    {
      name: 'Maine',
      hasLaw: true,
      isLawProposed: false,
      rulesApplicable: ['Periodic Filing']
    },
    {
      name: 'Maryland',
      hasLaw: false,
      isLawProposed: false,
      rulesApplicable: ['Periodic Filing']
    },
    {
      name: 'Massachusetts',
      hasLaw: false,
      isLawProposed: false,
      rulesApplicable: ['Periodic Filing']
    },
    {
      name: 'Michigan',
      hasLaw: false,
      isLawProposed: false,
      rulesApplicable: ['Periodic Filing']
    },
    {
      name: 'Minnesota',
      hasLaw: false,
      isLawProposed: false,
      rulesApplicable: ['Periodic Filing']
    },
    {
      name: 'Mississippi',
      hasLaw: false,
      isLawProposed: false,
      rulesApplicable: ['Periodic Filing']
    },
    {
      name: 'Missouri',
      hasLaw: false,
      isLawProposed: false,
      rulesApplicable: ['Periodic Filing']
    },
    {
      name: 'Montana',
      hasLaw: false,
      isLawProposed: false,
      rulesApplicable: ['Periodic Filing']
    },
    {
      name: 'Nebraska',
      hasLaw: false,
      isLawProposed: false,
      rulesApplicable: ['Periodic Filing']
    },
    {
      name: 'Nevada',
      hasLaw: false,
      isLawProposed: false,
      rulesApplicable: ['Periodic Filing']
    },
    {
      name: 'New Hampshire',
      hasLaw: false,
      isLawProposed: false,
      rulesApplicable: ['Periodic Filing']
    },
    {
      name: 'New Jersey',
      hasLaw: false,
      isLawProposed: false,
      rulesApplicable: ['Periodic Filing']
    },
    {
      name: 'New Mexico',
      hasLaw: false,
      isLawProposed: false,
      rulesApplicable: ['Periodic Filing']
    },
    {
      name: 'New York',
      hasLaw: false,
      isLawProposed: false,
      rulesApplicable: ['Periodic Filing']
    },
    {
      name: 'North Carolina',
      hasLaw: true,
      isLawProposed: false,
      rulesApplicable: ['Periodic Filing']
    },
    {
      name: 'North Dakota',
      hasLaw: true,
      isLawProposed: false,
      rulesApplicable: ['Periodic Filing']
    },
    {
      name: 'Ohio',
      hasLaw: false,
      isLawProposed: false,
      rulesApplicable: ['Periodic Filing']
    },
    {
      name: 'Oklahoma',
      hasLaw: false,
      isLawProposed: false,
      rulesApplicable: ['Periodic Filing']
    },
    {
      name: 'Oregon',
      hasLaw: true,
      isLawProposed: false,
      rulesApplicable: ['Periodic Filing']
    },
    {
      name: 'Pennsylvania',
      hasLaw: false,
      isLawProposed: false,
      rulesApplicable: ['Periodic Filing']
    },
    {
      name: 'Rhode Island',
      hasLaw: false,
      isLawProposed: false,
      rulesApplicable: ['Periodic Filing']
    },
    {
      name: 'South Carolina',
      hasLaw: false,
      isLawProposed: true,
      rulesApplicable: ['Price Change Filing']
    },
    {
      name: 'South Dakota',
      hasLaw: false,
      isLawProposed: false,
      rulesApplicable: ['Periodic Filing']
    },
    {
      name: 'Tennessee',
      hasLaw: true,
      isLawProposed: false,
      rulesApplicable: ['Periodic Filing']
    },
    {
      name: 'Texas',
      hasLaw: true,
      isLawProposed: false,
      rulesApplicable: ['Periodic Filing']
    },
    {
      name: 'Utah',
      hasLaw: false,
      isLawProposed: false,
      rulesApplicable: ['Periodic Filing']
    },
    {
      name: 'Vermont',
      hasLaw: false,
      isLawProposed: false,
      rulesApplicable: ['Periodic Filing']
    },
    {
      name: 'Virginia',
      hasLaw: true,
      isLawProposed: false,
      rulesApplicable: ['Periodic Filing']
    },
    {
      name: 'Washington',
      hasLaw: true,
      isLawProposed: false,
      rulesApplicable: ['Periodic Filing']
    },
    {
      name: 'West Virginia',
      hasLaw: true,
      isLawProposed: false,
      rulesApplicable: ['Periodic Filing']
    },
    {
      name: 'Wisconsin',
      hasLaw: false,
      isLawProposed: false,
      rulesApplicable: ['Periodic Filing']
    },
    {
      name: 'Wyoming',
      hasLaw: false,
      isLawProposed: false,
      rulesApplicable: ['Periodic Filing']
    },
    {
      name: 'Puerto Rico',
      hasLaw: false,
      isLawProposed: false,
      rulesApplicable: ['Periodic Filing']
    }
  ]
};

const getFilingStatusList: GetFilingStatusListRS = {
  messages: [],
  requestId: Guid.create(),
  transactionId: Guid.create(),
  data: [
    {
      id: '0777-3105-02',
      name: 'Ecospin',
      risk: RISK_TYPE.LOW,
      ndc: '0777-3105-02',
      pendingStates: 0,
      totalStates: 100
    },
    {
      id: '0853-3105-77',
      name: 'Alemtuzumab',
      risk: RISK_TYPE.LOW,
      ndc: '0853-3105-77',
      pendingStates: 4,
      totalStates: 85
    },
    {
      id: '9953-4444-99',
      name: 'Ampicillin',
      risk: RISK_TYPE.MODERATE,
      ndc: '9953-4444-99',
      pendingStates: 5,
      totalStates: 75
    },
    {
      id: '7474-7848-74',
      name: 'Ceftazidime',
      risk: RISK_TYPE.MODERATE,
      ndc: '7474-7848-74',
      pendingStates: 6,
      totalStates: 70
    },
    {
      id: '8745-7585-86',
      name: 'Dapsone',
      risk: RISK_TYPE.HIGH,
      ndc: '8745-7585-86',
      pendingStates: 9,
      totalStates: 55
    },
    {
      id: '6743-8734-98',
      name: 'Famciclovir',
      risk: RISK_TYPE.HIGH,
      ndc: '6743-8734-98',
      pendingStates: 14,
      totalStates: 25
    },
    {
      id: '7634-7847-63',
      name: 'Imatinib',
      risk: RISK_TYPE.HIGH,
      ndc: '7634-7847-63',
      pendingStates: 15,
      totalStates: 20
    }
  ],
  pageInfo: {
    pageIndex: 1,
    pageSize: 7,
    total: 7
  }
};

const todos: TodoByMonth = {
  messages: [],
  requestId: Guid.create(),
  transactionId: Guid.create(),
  month: 'August',
  todos: {
    '1': [
      {
        id: Guid.create(),
        title: 'Document internally on 1st.',
        description:
          'Brainstorming brings team members diverse experience into play on 1st..',
        priority: 'High',
        datetime: '2020-11-11T00:00:00'
      },
      {
        id: Guid.create(),
        title: 'Document internally on 1st.',
        description:
          'Brainstorming brings team members diverse experience into play on 1st.',
        priority: 'Low',
        datetime: '2020-11-11T00:00:00'
      }
    ],
    '5': [
      {
        id: Guid.create(),
        title: 'Dapsone filing was due on 5th',
        description: 'Dapsone filing due for state of Oregon for 5th.',
        priority: 'Moderate',
        datetime: '2020-11-11T00:00:00'
      },
      {
        id: Guid.create(),
        title: 'Famciclovir filing was due on 5th',
        description: 'Famciclovir filing due for state of Texas for 5th.',
        priority: 'High',
        datetime: '2020-11-11T00:00:00'
      }
    ],
    '30': [
      {
        id: Guid.create(),
        title: 'Ampicillin filing due on 30th',
        description: 'Ampicillin filing due for state of Oregon on 30th.',
        priority: 'Moderate',
        datetime: '2020-11-11T00:00:00'
      },
      {
        id: Guid.create(),
        title: 'Alemtuzumab filing due on 30th',
        description: 'Alemtuzumab filing due for state of Texas on 30th.',
        priority: 'Low',
        datetime: '2020-11-11T00:00:00'
      }
    ]
  }
};

const sptrList: GetStatePriceTransparencyRequirementListRS = {
  messages: [],
  data: [
    {
      state: 'California',
      website: 'https://hcai.ca.gov',
      email: 'support@hcai.ca.gov',
      filingMethod: ['ONLINE']
    },
    {
      state: 'North Dakota',
      website: 'https://www.insurance.nd.gov',
      email: 'drugtransparency@nd.gov',
      filingMethod: ['EMAIL']
    },
    {
      state: 'Utah',
      website: 'https://insurance.utah.gov',
      email: 'admin@insurance.utah.gov',
      filingMethod: ['UPLOAD']
    },
    {
      state: 'Washington',
      website: 'https://www.hca.wa.gov',
      email: 'official@hca.wa.gov',
      filingMethod: ['EMAIL']
    },
    {
      state: 'Texas',
      website: 'https://www.dshs.texas.gov',
      email: 'law@dshs.texas.gov',
      'filingMethod': ['UPLOAD']
    },
    {
      state: 'Oregon',
      website: 'https://dfr.oregon.gov',
      email: 'law@dfr.oregon.gov',
      'filingMethod': ['ONLINE']
    },
    {
      state: 'Nevada',
      website: 'https://www.dshs.nevada.gov',
      email: 'law@dshs.nevada.gov',
      'filingMethod': ['UPLOAD']
    }
  ],
  pageInfo: {
    pageIndex: 1,
    pageSize: 5,
    total: 5
  },
  requestId: Guid.create(),
  transactionId: Guid.create()
};
export class ClientDashboardEndpts {
  constructor(
    private apiEndPt: ApiEndpoint,
    private request: HttpRequest<NzSafeAny>,
    private next: HttpHandler
  ) {}

  handleRoute() {
    const endpoint = this.apiEndPt;
    const { url, method } = this.request;
    switch (true) {
      case url.endsWith(
        stringFormat(endpoint.CLIENTDASHBOARD.toString(), 'stats')
      ) && method === 'GET':
        //return this.getStats();
        return this.next.handle(this.request);

      case url.endsWith(
        stringFormat(
          endpoint.CLIENTDASHBOARD.toString(),
          'getStateTransparencyLawStatus'
        )
      ) && method === 'GET':
        return this.getStateTransparencyLawStatus();
      case url.includes(
        stringFormat(endpoint.CLIENTDASHBOARD.toString(), 'todos')
      ) && method === 'GET':
        return this.getTodosByMonth();
      // case url.endsWith(
      //   stringFormat(endpoint.CLIENTDASHBOARD.toString(), 'getFilingRiskList')
      // ) && method === 'POST':
      //   return this.getFilingRiskList();
      case url.endsWith(
        stringFormat(endpoint.CLIENTDASHBOARD.toString(), 'sptr-list')
      ) && method === 'POST':
        return this.getSPTRList();
      case url.endsWith(
        stringFormat(endpoint.CLIENTDASHBOARD.toString(), 'todos/add')
      ) && method === 'POST':
        return this.addTodo();
      case url.endsWith('update') && method === 'PUT':
        return this.updateTodo();
      case url.endsWith('delete') && method === 'DELETE':
        return this.deleteTodo();
      default:
        return this.next.handle(this.request);
    }
  }

  newTodo: AddTodoRS = {
    messages: [],
    transactionId: Guid.create(),
    requestId: Guid.create(),
    data: []
  };

  getSPTRList() {
    return ok(sptrList).pipe(delay(1000));
  }

  getTodosByMonth() {
    return ok(todos).pipe(delay(1000));
  }

  getFilingRiskList() {
    return ok(getFilingStatusList).pipe(delay(1000));
  }

  getStateTransparencyLawStatus() {
    return ok(getStateTransparencyLawStatus).pipe(delay(1000));
  }

  getStats() {
    return ok(statsRs).pipe(delay(1000));
  }

  addTodo() {
    return ok(this.newTodo).pipe(delay(1000));
  }

  updateTodo() {
    return ok({
      messages: [],
      transactionId: Guid.create(),
      requestId: Guid.create(),
      data: []
    }).pipe(delay(1000));
  }

  deleteTodo() {
    return ok({
      messages: [],
      transactionId: Guid.create(),
      requestId: Guid.create(),
      data: []
    }).pipe(delay(1000));
  }
}
