<div class="container">
  <div nz-row>
    <i nz-icon nzType="arrow-left" nzTheme="outline"></i>
    <a class="backLink" [routerLink]="['/auth/login']">Back to Login</a>
  </div>
  <div nz-row class="headingDiv">
    <h4 class="headingDivText">Check your email</h4>
    <p class="confirmationText">
      We have sent verification link to {{ username }}. Please follow the
      instructions in the email to reset your password.
    </p>
    <p class="receivedLink">Didn’t receive the link?</p>
    <button
      nz-button
      (click)="resendVerificationLink()"
      class="login-form-button login-form-margin"
      nzType="primary">
      Resend Verification Link
    </button>
  </div>
</div>
