import {
  CLIENT_VIEW,
  CLIENT_ADD,
  CLIENT_EDIT,
  CLIENT_ENABLE_DISABLE,
  CLIENT_DELETE,
  USER_VIEW,
  USER_ADD,
  USER_EDIT,
  USER_ENABLE_DISABLE,
  USER_DELETE,
  USER_PASSWD_RESET,
  STATE_ADD,
  STATE_ADD_NEW_VERSION,
  STATE_APPROVE,
  STATE_DELETE,
  STATE_EDIT,
  STATE_ENABLE_DISABLE,
  STATE_VIEW,
  BILLS_ADD,
  BILLS_ADD_NEW_VERSION,
  BILLS_APPROVE,
  BILLS_DELETE,
  BILLS_EDIT,
  BILLS_ENABLE_DISABLE,
  BILLS_VIEW,
  RULES_VIEW,
  RULES_ADD,
  RULES_EDIT,
  RULES_ADD_NEW_VERSION,
  RULES_APPROVE,
  RULES_ENABLE_DISABLE,
  RULES_DELETE,
  MANAGE_PERMISSIONS,
  MANAGE_ROLES,
  DRUG_LIST,
  DRUG_ADD,
  DRUG_EDIT,
  DRUG_ENABLE_DISABLE,
  DRUG_DELETE,
  DRUG_VIEW,
  DRUGPRICEHISTORY,
  DRUGPRICEPLANNER_VIEW,
  DRUGPRICEPLANNER_ADD,
  DRUGPRICEPLANNER_EDIT,
  DRUGPRICEPLANNER_ADD_NEW_VERSION,
  DRUGPRICEPLANNER_APPROVE,
  DRUGPRICEPLANNER_ENABLE_DISABLE,
  DRUGPRICEPLANNER_DELETE,
  CLIENT_DASHBOARD_VIEW,
  CLIENT_PROFILE_VIEW,
  CLIENT_PROFILE_EDIT,
  CLIENT_STATE_VIEW,
  FILING_DASHBOARD_VIEW,
  FILING_EDIT,
  FILING_APPROVE,
  FILING_SUBMIT
} from './perm-constants';

const ROLE_PERMISSIONS = {
  [Symbol.for('SNAP_ADMIN')]: [
    CLIENT_VIEW,
    CLIENT_ADD,
    CLIENT_EDIT,
    CLIENT_ENABLE_DISABLE,
    CLIENT_DELETE,
    USER_VIEW,
    USER_ADD,
    USER_EDIT,
    USER_ENABLE_DISABLE,
    USER_DELETE,
    USER_PASSWD_RESET,
    STATE_ADD,
    STATE_ADD_NEW_VERSION,
    STATE_APPROVE,
    STATE_DELETE,
    STATE_EDIT,
    STATE_ENABLE_DISABLE,
    STATE_VIEW,
    BILLS_ADD,
    BILLS_ADD_NEW_VERSION,
    BILLS_APPROVE,
    BILLS_DELETE,
    BILLS_EDIT,
    BILLS_ENABLE_DISABLE,
    BILLS_VIEW,
    RULES_VIEW,
    RULES_ADD,
    RULES_EDIT,
    RULES_ADD_NEW_VERSION,
    RULES_APPROVE,
    RULES_ENABLE_DISABLE,
    RULES_DELETE,
    MANAGE_PERMISSIONS,
    MANAGE_ROLES,
    FILING_APPROVE,
    FILING_SUBMIT
  ],
  [Symbol.for('EMULATED')]: [
    USER_VIEW,
    USER_ADD,
    USER_EDIT,
    USER_ENABLE_DISABLE,
    USER_PASSWD_RESET,
    USER_DELETE,
    STATE_VIEW,
    BILLS_VIEW,
    RULES_VIEW,
    DRUG_LIST,
    DRUG_ADD,
    DRUG_EDIT,
    DRUG_ENABLE_DISABLE,
    DRUG_DELETE,
    DRUG_VIEW,
    DRUGPRICEHISTORY,
    DRUGPRICEPLANNER_VIEW,
    DRUGPRICEPLANNER_ADD,
    DRUGPRICEPLANNER_EDIT,
    DRUGPRICEPLANNER_ADD_NEW_VERSION,
    DRUGPRICEPLANNER_APPROVE,
    DRUGPRICEPLANNER_ENABLE_DISABLE,
    DRUGPRICEPLANNER_DELETE,
    CLIENT_DASHBOARD_VIEW,
    CLIENT_PROFILE_VIEW,
    CLIENT_PROFILE_EDIT,
    CLIENT_STATE_VIEW,
    FILING_DASHBOARD_VIEW,
    FILING_EDIT,
    FILING_APPROVE,
    FILING_SUBMIT
  ],
  [Symbol.for('CLIENT_ADMIN')]: [
    USER_VIEW,
    USER_ADD,
    USER_EDIT,
    USER_ENABLE_DISABLE,
    USER_PASSWD_RESET,
    USER_DELETE,
    STATE_VIEW,
    BILLS_VIEW,
    RULES_VIEW,
    DRUG_LIST,
    DRUG_ADD,
    DRUG_EDIT,
    DRUG_ENABLE_DISABLE,
    DRUG_DELETE,
    DRUG_VIEW,
    DRUGPRICEHISTORY,
    DRUGPRICEPLANNER_ADD,
    DRUGPRICEPLANNER_ADD_NEW_VERSION,
    DRUGPRICEPLANNER_APPROVE,
    DRUGPRICEPLANNER_DELETE,
    DRUGPRICEPLANNER_EDIT,
    DRUGPRICEPLANNER_ENABLE_DISABLE,
    DRUGPRICEPLANNER_VIEW,
    CLIENT_DASHBOARD_VIEW,
    CLIENT_PROFILE_VIEW,
    CLIENT_PROFILE_EDIT,
    CLIENT_STATE_VIEW,
    FILING_DASHBOARD_VIEW,
    FILING_EDIT,
    FILING_APPROVE,
    FILING_SUBMIT
  ],
  [Symbol.for('CLIENT_LEGAL')]: [
    USER_VIEW,
    STATE_VIEW,
    BILLS_VIEW,
    RULES_VIEW,
    DRUG_LIST,
    DRUG_ADD,
    DRUG_EDIT,
    DRUG_VIEW,
    DRUG_ENABLE_DISABLE,
    DRUGPRICEHISTORY,
    DRUGPRICEPLANNER_VIEW,
    DRUGPRICEPLANNER_ADD,
    DRUGPRICEPLANNER_EDIT,
    DRUGPRICEPLANNER_ADD_NEW_VERSION,
    DRUGPRICEPLANNER_APPROVE,
    DRUGPRICEPLANNER_ENABLE_DISABLE,
    DRUGPRICEPLANNER_DELETE,
    FILING_DASHBOARD_VIEW,
    FILING_EDIT,
    FILING_APPROVE,
    FILING_SUBMIT
  ],
  [Symbol.for('CLIENT_PPO')]: [
    USER_VIEW,
    STATE_VIEW,
    BILLS_VIEW,
    RULES_VIEW,
    DRUG_LIST,
    DRUG_ADD,
    DRUG_EDIT,
    DRUG_ENABLE_DISABLE,
    DRUG_DELETE,
    DRUG_VIEW,
    DRUGPRICEHISTORY,
    DRUGPRICEPLANNER_VIEW,
    DRUGPRICEPLANNER_ADD,
    DRUGPRICEPLANNER_EDIT,
    DRUGPRICEPLANNER_ADD_NEW_VERSION,
    DRUGPRICEPLANNER_ENABLE_DISABLE,
    DRUGPRICEPLANNER_DELETE,
    CLIENT_DASHBOARD_VIEW,
    CLIENT_PROFILE_VIEW,
    CLIENT_PROFILE_EDIT,
    CLIENT_STATE_VIEW,
    FILING_DASHBOARD_VIEW,
    FILING_EDIT
  ],
  [Symbol.for('CLIENT_EMPLOYEE')]: [
    USER_VIEW,
    STATE_VIEW,
    BILLS_VIEW,
    RULES_VIEW,
    DRUG_LIST,
    DRUG_VIEW,
    DRUGPRICEHISTORY,
    DRUGPRICEPLANNER_VIEW,
    DRUGPRICEPLANNER_DELETE,
    CLIENT_DASHBOARD_VIEW,
    CLIENT_PROFILE_VIEW,
    CLIENT_STATE_VIEW,
    FILING_DASHBOARD_VIEW
  ]
};
Object.freeze(ROLE_PERMISSIONS);
export { ROLE_PERMISSIONS };
