export enum MAPPED_TO {
  NDC = 'NDC',
  WAC = 'WAC',
  LabelerId = 'Labeler ID',
  CompanyName = 'Company Name'
}

export enum SPECIFY_TYPE {
  Number = 'decimal',
  String = 'string',
  Date = 'date',
  Boolean = 'bool'
}
