import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { MarketingContentComponent } from './marketing-content/marketing-content.component';
import { NoInternetComponent } from './no-internet/no-internet.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { SharedZorroModule } from '../shared-zorro.module';
import { PageHeaderComponent } from './page-header/page-header.component';
import { SearchComponent } from './search/search.component';
import { RouterModule } from '@angular/router';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { AWSQSPriceGenieComponent } from './awsqs-price-genie/awsqs-price-genie.component';
import { AwsqsDrugsComponent } from './awsqs-price-genie/components/awsqs-drugs/awsqs-drugs.component';
import { AwsqsDevicesComponent } from './awsqs-price-genie/components/awsqs-devices/awsqs-devices.component';
import { AwsqsServicesComponent } from './awsqs-price-genie/components/awsqs-services/awsqs-services.component';
import { FormsModule } from '@angular/forms';
import { AwsqsMpfsComponent } from './awsqs-price-genie/components/awsqs-mpfs/awsqs-mpfs.component';
import { AwsqsIppsComponent } from './awsqs-price-genie/components/awsqs-ipps/awsqs-ipps.component';
import { SupersetPricegenieComponent } from './superset-pricegenie/superset-pricegenie.component';
import { DiscardedDrugUnitsComponent } from './discarded-drug-units/discarded-drug-units.component';
import { SupersetDashboardComponent } from './superset-dashboard/superset-dashboard.component';

const COMPONENTS = [
  LandingPageComponent,
  MarketingContentComponent,
  NoInternetComponent,
  NotFoundComponent,
  AccessDeniedComponent,
  PageHeaderComponent,
  SearchComponent,
  AWSQSPriceGenieComponent,
  SearchComponent,
  AwsqsDrugsComponent,
  AwsqsDevicesComponent,
  AwsqsServicesComponent,
  AwsqsMpfsComponent,
  AwsqsIppsComponent,
  SupersetPricegenieComponent,
  DiscardedDrugUnitsComponent,
  SupersetDashboardComponent
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [CommonModule, RouterModule, SharedZorroModule, FormsModule],
  exports: [...COMPONENTS]
})
export class ComponentsModule {}
