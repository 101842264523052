import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from './components/components.module';
import { DirectivesModule } from './directives/directives.module';
//import { LayoutHeadRightMenuModule } from './layout-head-right-menu/layout-head-right-menu.module';
import { SharedZorroModule } from './shared-zorro.module';
import { EditCanDeactivateGuard } from './guards/edit-can-deactivate.guard';
import { NgxPermissionsModule } from 'ngx-permissions';
import { SnapDateFormat } from './pipes/date-format.pipe';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';

const MODULES = [
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  ComponentsModule,
  DirectivesModule,
  NgxPermissionsModule,
  PdfJsViewerModule
  //LayoutHeadRightMenuModule,
];

@NgModule({
  imports: [...MODULES],
  declarations: [SnapDateFormat],
  exports: [...MODULES, SharedZorroModule, SnapDateFormat],
  providers: [EditCanDeactivateGuard]
})
export class SharedLibModule {}
