export const FILING_STATUS = {
  UPCOMING: 'UPCOMING',
  IN_PROGRESS: 'IN_PROGRESS',
  WAITING: 'WAITING',
  APPROVAL_PENDING: 'APPROVAL_PENDING',
  INTERNAL_APPROVED: 'INTERNAL_APPROVED',
  SUBMITTED: 'SUBMITTED',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  CLOSED: 'CLOSED',
  DUE_PASSED: 'DUE_PASSED'
};

export const GET_FILING_STATUS_COLOR_CODE = (status: string): string => {
  switch (status) {
    case FILING_STATUS.APPROVED:
      return '#adff2f';
    case FILING_STATUS.APPROVAL_PENDING:
      return '#4d46ad';
    case FILING_STATUS.UPCOMING:
      return '#8e8d8d';
    case FILING_STATUS.IN_PROGRESS:
      return '#775DA6';
    case FILING_STATUS.DUE_PASSED:
      return '#ff4d00';
    case FILING_STATUS.SUBMITTED:
      return '#70b6c1';
    case FILING_STATUS.INTERNAL_APPROVED:
      return '#98fb98';
    case FILING_STATUS.CLOSED:
      return '#228b22';
    case FILING_STATUS.REJECTED:
      return '#ff0000';
    case FILING_STATUS.WAITING:
      return '#fbbd45';
    default:
      return '#000000';
  }
};
