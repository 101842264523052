import { Component, Input, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ThemeService } from '@snap/shared/services/store';
import { NzSafeAny } from 'ng-zorro-antd/core/types';

export interface PageHeaderType {
  title: string;
  desc: string | TemplateRef<NzSafeAny>;
  extra: string | TemplateRef<NzSafeAny>;
  breadcrumb: string[];
  footer: string | TemplateRef<NzSafeAny>;
}

@Component({
  selector: 'snap-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.less'],
})
export class PageHeaderComponent {
  @Input() backTpl!: TemplateRef<NzSafeAny> | null;
  @Input() pageHeaderInfo: Partial<PageHeaderType> = {};
  @Input() backUrl: string | string[] = '';
  themesOptions$ = this.themesService.getThemesMode();

  constructor(
    private themesService: ThemeService,
    private router: Router,
    private activateRoute: ActivatedRoute
  ) {}

  back(): void {
    if (Array.isArray(this.backUrl))
      this.router.navigate(this.backUrl, {
        relativeTo: this.activateRoute,
      });
    else this.router.navigateByUrl(this.backUrl);
  }
}
