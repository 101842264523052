import { Component } from '@angular/core';
import { NzSafeAny } from 'ng-zorro-antd/core/types';
import { DynamicTab, ViewServiceDetails } from './model/PriceGenieURL';

@Component({
  selector: 'snap-awsqs-price-genie',
  templateUrl: './awsqs-price-genie.component.html',
  styleUrls: ['./awsqs-price-genie.component.less']
})
export class AWSQSPriceGenieComponent {
  dynamicTab: NzSafeAny[] = [];
  selectedIndex = 0;

  addDynamicTab(serviceDetails: ViewServiceDetails) {
    this.dynamicTab = [];
    if (serviceDetails.tab === DynamicTab.MPFS) {
      this.dynamicTab.push({
        title: 'MPFS ' + serviceDetails.searchText
      });
      this.selectedIndex = 3;
    } else {
      this.dynamicTab.push({
        title: 'IPPS ' + serviceDetails.searchText
      });
      this.selectedIndex = 3;
    }
  }

  closeTab({ index }: { index: number }): void {
    this.dynamicTab.splice(index, 1);
    this.dynamicTab.length = 0;
  }
}
