import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Menu } from '@snap/shared/model/interfaces';
import { fnFlatDataHasParentToTree } from '@snap/shared/utils';
import { PERMISSIONS } from '@snap/shared/model/constants';
import { UserInfoService } from './user-info.service';

export const menus: Menu[] = fnFlatDataHasParentToTree([
  {
    id: 1,
    menuName: 'Accounts',
    fatherId: 0,
    orderNum: 1,
    path: '/admin/accounts',
    menuType: 'C',
    visible: true,
    status: true,
    icon: 'user',
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null,
    code: [PERMISSIONS.CLIENT_VIEW, PERMISSIONS.USER_VIEW]
  },
  {
    id: 2,
    menuName: 'Clients',
    fatherId: 1,
    orderNum: 2,
    path: '/admin/accounts/clients',
    menuType: 'F',
    visible: true,
    status: true,
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null,
    code: [PERMISSIONS.CLIENT_VIEW]
  },
  {
    id: 3,
    menuName: 'Users',
    fatherId: 1,
    orderNum: 3,
    path: '/admin/accounts/users',
    menuType: 'F',
    visible: true,
    status: true,
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null,
    code: [PERMISSIONS.USER_VIEW]
  },
  {
    id: 4,
    menuName: 'Compliance',
    fatherId: 0,
    orderNum: 4,
    path: '/admin/legal',
    menuType: 'C',
    visible: true,
    status: true,
    icon: 'audit',
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null,
    code: [
      PERMISSIONS.STATE_VIEW,
      PERMISSIONS.BILLS_VIEW,
      PERMISSIONS.RULES_VIEW
    ]
  },
  {
    id: 5,
    menuName: 'States',
    fatherId: 4,
    orderNum: 4,
    path: '/admin/legal/states',
    menuType: 'F',
    visible: true,
    status: true,
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null,
    code: [PERMISSIONS.STATE_VIEW]
  },
  {
    id: 6,
    menuName: 'Bills',
    fatherId: 4,
    orderNum: 6,
    path: '/admin/legal/bills',
    menuType: 'F',
    visible: true,
    status: true,
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null,
    code: [PERMISSIONS.BILLS_VIEW]
  },
  {
    id: 7,
    menuName: 'Rules',
    fatherId: 4,
    orderNum: 7,
    path: '/admin/legal/rules',
    menuType: 'F',
    visible: true,
    status: true,
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null,
    code: [PERMISSIONS.RULES_VIEW]
  },
  {
    id: 8,
    menuName: 'Insights',
    fatherId: 0,
    orderNum: 8,
    path: '/admin/insights',
    menuType: 'C',
    visible: true,
    status: true,
    icon: 'bulb',
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null,
    code: []
  },
  {
    id: 9,
    menuName: 'Rebate Penalty Calculator',
    fatherId: 8,
    orderNum: 9,
    path: '/admin/insights/rebate',
    menuType: 'F',
    visible: true,
    status: true,
    icon: 'svg-icon:rebate',
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null,
    code: []
  },
  {
    id: 10,
    menuName: 'Pricing Insights',
    fatherId: 8,
    orderNum: 10,
    path: '/admin/insights/pricing-insights',
    menuType: 'F',
    visible: true,
    status: true,
    icon: 'project',
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null,
    code: []
  },
  {
    id: 11,
    menuName: 'NDC Master Data',
    fatherId: 8,
    orderNum: 11,
    path: '/admin/insights/ndc/ndc-wac-list',
    menuType: 'F',
    visible: true,
    status: true,
    icon: 'database',
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null,
    code: []
  },
  {
    id: 12,
    menuName: 'Discarded Drug Units',
    fatherId: 8,
    orderNum: 12,
    path: '/admin/insights/discarded-drug-units',
    menuType: 'F',
    visible: true,
    status: true,
    icon: 'minus-square',
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null,
    code: []
  }
]);

export const clientMenus: Menu[] = fnFlatDataHasParentToTree([
  {
    id: 1,
    menuName: 'Dashboard',
    fatherId: 0,
    orderNum: 1,
    path: '/client/welcome',
    menuType: 'F',
    visible: true,
    status: true,
    icon: 'svg-icon:dashboard',
    alIcon: '',
    newLinkFlag: false,
    createTime: null,
    updateTime: null,
    code: [PERMISSIONS.CLIENT_DASHBOARD_VIEW]
  },
  {
    id: 2,
    menuName: 'Drugs',
    fatherId: 0,
    orderNum: 2,
    path: '/client/drugs',
    menuType: 'F',
    visible: true,
    status: true,
    icon: 'svg-icon:drugs',
    alIcon: '',
    newLinkFlag: false,
    createTime: null,
    updateTime: null,
    code: [PERMISSIONS.DRUG_LIST]
  },
  {
    id: 3,
    menuName: 'Filing',
    fatherId: 0,
    orderNum: 3,
    path: '/client/filing',
    menuType: 'F',
    visible: true,
    status: true,
    icon: 'svg-icon:filing',
    alIcon: '',
    newLinkFlag: false,
    createTime: null,
    updateTime: null,
    code: []
  },
  {
    id: 4,
    menuName: 'Company Profile',
    fatherId: 0,
    orderNum: 4,
    path: '/client/profile',
    menuType: 'F',
    visible: true,
    status: true,
    icon: 'svg-icon:profile',
    alIcon: '',
    newLinkFlag: false,
    createTime: null,
    updateTime: null,
    code: [PERMISSIONS.CLIENT_PROFILE_VIEW]
  },
  {
    id: 5,
    menuName: 'Compliance Calculator',
    fatherId: 0,
    orderNum: 5,
    path: '/client/price-compliance',
    menuType: 'F',
    visible: true,
    status: true,
    icon: 'svg-icon:compliance-calc',
    alIcon: '',
    newLinkFlag: false,
    createTime: null,
    updateTime: null,
    code: [PERMISSIONS.DRUGPRICEHISTORY]
  },
  {
    id: 6,
    menuName: 'Compliance Requirements',
    fatherId: 0,
    orderNum: 6,
    path: '/client/legal/states',
    menuType: 'F',
    visible: true,
    status: true,
    icon: 'svg-icon:compliance',
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null,
    code: [PERMISSIONS.CLIENT_STATE_VIEW]
  },
  {
    id: 7,
    menuName: 'Team',
    fatherId: 0,
    orderNum: 9,
    path: '/client/accounts/users',
    menuType: 'F',
    visible: true,
    status: true,
    alIcon: null,
    icon: 'svg-icon:team',
    newLinkFlag: false,
    createTime: null,
    updateTime: null,
    code: [PERMISSIONS.USER_VIEW]
  },
  {
    id: 8,
    menuName: 'Insights',
    fatherId: 0,
    orderNum: 8,
    path: '/client/insights',
    menuType: 'C',
    visible: true,
    status: true,
    icon: 'bulb',
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null,
    code: []
  },
  {
    id: 9,
    menuName: 'Rebate Penalty Calculator',
    fatherId: 8,
    orderNum: 9,
    path: '/client/insights/rebate',
    menuType: 'F',
    visible: true,
    status: true,
    icon: 'svg-icon:rebate',
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null,
    code: []
  },
  {
    id: 10,
    menuName: 'Pricing Insights',
    fatherId: 8,
    orderNum: 10,
    path: '/client/insights/pricing-insights',
    menuType: 'F',
    visible: true,
    status: true,
    icon: 'project',
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null,
    code: []
  },
  {
    id: 11,
    menuName: 'NDC Master Data',
    fatherId: 8,
    orderNum: 11,
    path: '/client/insights/ndc/ndc-wac-list',
    menuType: 'F',
    visible: true,
    status: true,
    icon: 'database',
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null,
    code: []
  },
  {
    id: 12,
    menuName: 'Discarded Drug Units',
    fatherId: 8,
    orderNum: 12,
    path: '/client/insights/discarded-drug-units',
    menuType: 'F',
    visible: true,
    status: true,
    icon: 'minus-square',
    alIcon: null,
    newLinkFlag: false,
    createTime: null,
    updateTime: null,
    code: []
  }
]);

@Injectable({
  providedIn: 'root'
})
export class MenuStoreService {
  private menuArray$ = new BehaviorSubject<Menu[]>([]);
  constructor(private userInfoService: UserInfoService) {
    if (
      this.userInfoService.UserInfo?.role === 'SNAP_ADMIN' &&
      !this.userInfoService.UserInfo.context
    ) {
      this.menuArray$.next(menus);
    } else {
      this.menuArray$.next(clientMenus);
    }
  }

  setMenuArrayStore(menuArray: Menu[]): void {
    this.menuArray$.next(menuArray);
  }

  getMenuArrayStore(): Observable<Menu[]> {
    return this.menuArray$.asObservable();
  }
}
