import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Optional, SkipSelf } from '@angular/core';
import * as _ from 'lodash';
import { NgxRolesService } from 'ngx-permissions';
import { ROLE_PERMISSIONS } from '@snap/shared/model/constants';
//import { Logger } from '@snap/core';

//const log = new Logger('UserInfoService');

export interface UserCredentials {
  authToken: string;
  refreshToken: string;
  idToken: string;
  rememberme: boolean;
}
export interface UserInfo {
  userId: string;
  emailId: string;
  userName: string;
  giveName: string;
  role: string;
  credentials: UserCredentials;
  context: UserContext | null;
}

export interface UserContext {
  clientId: string;
  clientName: string;
  role: string;
}

const userInfoKey = 'USER_INFO';

@Injectable({
  providedIn: 'root'
})
export class UserInfoService {
  private userInfo$ = new BehaviorSubject<UserInfo | null>(null);
  constructor(
    private rolesService: NgxRolesService,
    @Optional() @SkipSelf() parentService: UserInfoService
  ) {
    if (parentService != null) {
      throw new Error('UserInfoService is already created.');
    }
    const data =
      sessionStorage.getItem(userInfoKey) || localStorage.getItem(userInfoKey);
    if (data) {
      const userInfo: UserInfo = JSON.parse(data);
      this.setUserInfo(userInfo);
    }
  }

  setUserInfo(userInfo: UserInfo | null, changePermission = true): void {
    if (userInfo != null) {
      localStorage.setItem(userInfoKey, JSON.stringify(userInfo));
      if (changePermission) {
        this.rolesService.flushRolesAndPermissions();
        const role = userInfo.context ? userInfo.context.role : userInfo.role;
        this.rolesService.addRoleWithPermissions(
          role,
          ROLE_PERMISSIONS[Symbol.for(role)]
        );
      }
    } else {
      this.rolesService.flushRolesAndPermissions();
      localStorage.removeItem(userInfoKey);
    }
    this.userInfo$.next(userInfo);
  }

  getUserInfo(): Observable<UserInfo | null> {
    return this.userInfo$.asObservable();
  }

  get UserInfo(): UserInfo | null {
    return this.userInfo$.getValue();
  }

  /*getUserCredentials(): Observable<UserCredentials | null> {
    return this.userInfo$.pipe(map((x) => x?.credentials ?? null));
  }*/

  get UserCredentials(): UserCredentials | null {
    if (this.UserInfo && this.UserInfo.credentials)
      return this.UserInfo.credentials;
    return null;
  }

  setCredentials(credentials: UserCredentials): void {
    if (credentials && this.UserInfo) {
      const newObj: UserInfo = _.clone(this.UserInfo);
      newObj.credentials = credentials;
      //_.merge(newObj.credentials, credentials);
      this.setUserInfo(newObj, false);
    }
  }

  setContext(context: UserContext | null): void {
    if (this.UserInfo) {
      const newObj: UserInfo = _.clone(this.UserInfo);
      newObj.context = context;
      this.setUserInfo(newObj);
    }
  }

  switchContext(clientId: string, clientName: string): boolean {
    //log.info('Switch Context Start', new Date().toString());
    try {
      this.setContext({
        role: 'EMULATED',
        clientId: clientId,
        clientName: clientName
      });

      return true;
    } catch {
      //log.info('Switch Context Error', new Date().toString());
      return false;
    }
  }
}
