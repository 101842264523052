<nz-card>
  <nz-row>
    <nz-col>
      <ng-template #suffixIconButton>
        <span
          nz-icon
          style="cursor: pointer"
          nzType="search"
          nzTheme="outline"
          (click)="loadDashboard()"></span>
      </ng-template>
      <ng-template #suffix>
        <ng-template #indicatorTemplate
          ><span nz-icon nzType="loading"></span
        ></ng-template>
        <nz-spin
          *ngIf="isDrugListLoading"
          nzSimple
          [nzIndicator]="indicatorTemplate"></nz-spin>
      </ng-template>
      <nz-input-group
        [style.width]="'30vw'"
        nzCompact
        [nzSuffix]="suffix"
        [nzAddOnAfter]="suffixIconButton">
        <!-- <input
        [(ngModel)]="searchText"
        placeholder="Enter name, ICD or CPT code"
        nz-input
        (keyup)="onDrugsSearch($event)"
        [nzAutocomplete]="auto" /> -->
        <input
          (keyup.enter)="loadDashboard()"
          [disabled]="isLoading"
          [(ngModel)]="searchText"
          placeholder="Enter name, ICD or CPT code"
          nz-input />
      </nz-input-group>

      <nz-autocomplete #auto>
        <nz-auto-option
          *ngFor="let option of drugOptionsList"
          [nzLabel]="option.name"
          [nzValue]="option.value">
          {{ option.name }}
        </nz-auto-option>
      </nz-autocomplete>
    </nz-col>
    <nz-col class="m-l-10">
      <button
        nz-button
        *ngIf="showMPFSButton"
        nzType="primary"
        nzGhost
        (click)="viewDetailsFor(DynamicTab.MPFS)">
        View MPFS Details
      </button>
      <button
        nz-button
        *ngIf="showIPPSButton"
        nzType="primary"
        nzGhost
        (click)="viewDetailsFor(DynamicTab.IPPS)">
        View IPPS Details
      </button>
    </nz-col>
  </nz-row>
  <nz-spin [nzSpinning]="isLoading" class="loader"></nz-spin>
  <div #serviceContainer></div>
</nz-card>
