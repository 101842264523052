import { Injectable } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { switchMap } from 'rxjs/operators';
import {
  SIDECOLLAPSEDMAXWIDTH,
  TOPCOLLAPSEDMAXWIDTH,
} from '@snap/shared/model/constants';
import { EquipmentWidth, WindowsWidthService, ThemeService } from './store';

/* Monitor screen width service */
@Injectable({
  providedIn: 'root',
})
export class SubWindowWithService {
  subWidthObj: { [key: string]: [EquipmentWidth, [number, number]] } = {
    '(max-width: 575.98px)': [EquipmentWidth.xs, [0, 575.98]],
    '(min-width: 576px) and (max-width: 767.98px)': [
      EquipmentWidth.sm,
      [576, 767.98],
    ],
    '(min-width: 768px) and (max-width: 991.98px)': [
      EquipmentWidth.md,
      [768, 991.98],
    ],
    '(min-width: 992px) and (max-width: 1199.98px)': [
      EquipmentWidth.lg,
      [992, 1199.98],
    ],
    '(min-width: 1200px) and (max-width: 1599.98px)': [
      EquipmentWidth.xl,
      [1200, 1599.98],
    ],
    '(min-width: 1600px)': [EquipmentWidth.xxl, [1600, 9999]],
  };

  constructor(
    private winWidthService: WindowsWidthService,
    private breakpointObserver: BreakpointObserver,
    private themesService: ThemeService
  ) {}

  // Monitor the theme (top, or side) to determine the minimum width of the over mode
  subWidthForTheme(): void {
    this.themesService
      .getThemesMode()
      .pipe(
        switchMap((res) => {
          let maxWidth = '';
          if (res.mode === 'side' || (res.mode === 'mixi' && !res.splitNav)) {
            maxWidth = `(max-width: ${SIDECOLLAPSEDMAXWIDTH}px)`;
          } else if (
            res.mode === 'top' ||
            (res.mode === 'mixi' && res.splitNav)
          ) {
            maxWidth = `(max-width: ${TOPCOLLAPSEDMAXWIDTH}px)`;
          }
          // Can be entered [Breakpoints.Small, Breakpoints.XSmall]
          return this.breakpointObserver.observe([maxWidth]);
        })
      )
      .subscribe((result) => {
        const isOverMode = result.matches;
        this.themesService.setIsOverMode(isOverMode);
        // It is over mode, expand and collapse the left menu
        if (isOverMode) {
          this.themesService.setIsCollapsed(false);
        }
      });
  }

  // Determine which grid node is based on the incoming screen width
  judgeWindowsWidth(width: number): EquipmentWidth {
    let currentPoint: EquipmentWidth;
    Object.values(this.subWidthObj).forEach((item) => {
      if (width >= item[1][0] && width <= item[1][1]) {
        currentPoint = item[0];
      }
    });
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return currentPoint!;
  }

  // Listen to browser width for common grid system
  subWidthForStore(): void {
    this.breakpointObserver
      .observe(Object.keys(this.subWidthObj))
      .subscribe((res) => {
        Object.keys(res.breakpoints).forEach((item) => {
          if (res.breakpoints[item]) {
            this.winWidthService.setWindowWidthStore(this.subWidthObj[item][0]);
          }
        });
      });
  }

  subWindowWidth() {
    this.subWidthForTheme();
    this.subWidthForStore();
    // Set the current node when initializing
    this.winWidthService.setWindowWidthStore(
      this.judgeWindowsWidth(window.innerWidth)
    );
  }
}
