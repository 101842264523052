// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { ApiEndpoint } from '@snap/shared/model';
import { IEnvironment } from '@snap/shared/model/interfaces';
import pkg from '../../../../../../package.json';
export const environment: IEnvironment = {
  production: false,
  REMOTE_CONFIG_URL: '',
  envName: 'dev',
  plugins: [],
  API_ENDPOINT: new ApiEndpoint('', 'dev_v1'),
  domain: 'snaptransparencydev.link',
  appVersion: pkg.version + '-dev'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
