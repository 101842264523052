import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { Observable } from 'rxjs';

export interface CanComponentDeactivate {
  canDeactivate: () => Promise<boolean> | boolean;
}

@Injectable()
export class EditCanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
  modalRef!: NzModalRef<string>;
  constructor(private modal: NzModalService) {}

  canDeactivate(component: CanComponentDeactivate, _currentRoute: ActivatedRouteSnapshot, _currentState: RouterStateSnapshot,
    _nextState?: RouterStateSnapshot | undefined): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (component.canDeactivate()) {
      return new Promise((resolve) => {
        this.modalRef = this.modal.confirm<string>({
          nzTitle: '<b>Do you want to leave without saving?</b>',
          nzContent: 'All changes will be lost once you leave this page.',
          nzOkText: 'Yes',
          nzOkType: 'primary',
          nzOkDanger: true,
          nzOnOk: () => resolve(true),
          nzCancelText: 'No',
          nzOnCancel: () => resolve(false),
        });
      });
    } else {
      return true;
    }
  }
}
