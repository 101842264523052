import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { NzSafeAny } from 'ng-zorro-antd/core/types';
import { of, throwError } from 'rxjs';
import { TOKENKEY, TOKENPRE } from '@snap/shared/model/constants';
//import { Logger } from '@snap/core';

//const logger = new Logger('API MOCK');

function ok(body?: NzSafeAny) {
  //logger.debug(body);
  return of(new HttpResponse({ status: 200, body }));
}

function error(message: NzSafeAny) {
  //logger.debug(message);
  return throwError(() => ({ error: { message } }));
}

function unauthorized() {
  //logger.debug('UnAuthorized');
  return throwError(() => ({
    status: 401,
    error: { message: 'Unauthorized' }
  }));
}

function isLoggedIn(headers: HttpHeaders) {
  //logger.debug('isLoggedIn');
  // check if jwt token is in auth header
  const authHeader = headers.get(`${TOKENKEY}`);
  if (!authHeader?.startsWith(`${TOKENPRE} jwt-token`)) return false;

  // check if token is expired
  const jwtToken = JSON.parse(atob(authHeader.split('.')[1]));
  const tokenExpired = Date.now() > jwtToken.exp * 1000;
  if (tokenExpired) return false;

  return true;
}

export { ok, error, unauthorized, isLoggedIn };
