export enum RISK_TYPE {
  HIGH = 'HIGH',
  MODERATE = 'MODERATE',
  LOW = 'LOW'
}

export const RISK_TYPE_COLOR = (risk: RISK_TYPE): string => {
  switch (risk) {
    case RISK_TYPE.HIGH:
      return '#FF7A84';
    case RISK_TYPE.LOW:
      return '#70B6C1';
    case RISK_TYPE.MODERATE:
      return '#775DA6';
    default:
      return '';
  }
};
