import { NgModule } from '@angular/core';
import { LoginComponent } from './containers/login/login.component';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { Route } from '@angular/router';
import { SharedLibModule } from '@snap/shared/lib';
import { RouterModule } from '@angular/router';
import { ForgetComponent } from './containers/forget/forget.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { AuthService } from './services/auth.service';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { CodeInputModule } from 'angular-code-input';

export const authRoutes: Route[] = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    component: LoginComponent,
    data: { key: 'login', title: 'Login' }
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    data: { key: 'reset-pwd-link', title: 'Reset Password Link' }
  },
  {
    path: 'set-password',
    component: ResetPasswordComponent,
    data: {
      firstPasswordSetup: true,
      key: 'set-pwd',
      title: 'Set Password'
    }
  },
  {
    path: 'forget-password',
    component: ForgetComponent,
    data: { key: 'forgot-pwd', title: 'Forgot Password' }
  }
];

export function jwtOptionsFactory(authService: AuthService) {
  return {
    tokenGetter: () => authService.IdToken,
    disallowedRoutes: [new RegExp('/api/login/', 'gm')]
  };
}

@NgModule({
  declarations: [
    LoginFormComponent,
    LoginComponent,
    ResetPasswordComponent,
    ForgetComponent,
    ForgotPasswordComponent
  ],
  imports: [
    SharedLibModule,
    CodeInputModule.forRoot({ codeLength: 6 }),
    RouterModule.forChild(authRoutes)
  ]
})
export class AuthModule {
  static forRoot() {
    return JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [AuthService]
      }
    });
  }
}
