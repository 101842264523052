import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
//import { Logger } from '@snap/core';
import { AuthService } from '../../services/auth.service';
//const log = new Logger('ForgotPassword');
@Component({
  selector: 'snap-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.less'],
})
export class ForgotPasswordComponent implements OnInit {
  username?: string;
  constructor(private auth: AuthService, private router: Router) {
    const userData = this.router?.getCurrentNavigation()?.extras.state;
    this.username = JSON.stringify(userData?.['data']);
    //log.info('username', this.username);
  }
  ngOnInit(): void {
    this.resendVerificationLink(this.username);
  }
  resendVerificationLink(username?: string): void {
    this.auth.verificationLink(username).subscribe({
      next: () => {
        //log.info('succesfully sent the link');
      },
      error: () => {
        //log.info('error occured while sending the verification link');
      },
    });
  }
}
