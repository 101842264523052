import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'snap-common-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.less'],
})
export class SearchComponent {
  @Output() searchClicked = new EventEmitter<string>();
  @Output() searchTextChanged = new EventEmitter<string>();
  @Output() clear = new EventEmitter<void>();

  @ViewChild('searchText', { static: true })
  searchTextInput!: ElementRef<HTMLInputElement>;
  search(val: string) {
    this.searchClicked.emit(val);
  }

  changeHandler(val: string) {
    this.searchTextChanged.emit(val);
  }

  clearSearchText() {
    this.searchTextInput.nativeElement.value = '';
    this.clear.emit();
  }
}
