import { HttpHandler, HttpRequest } from '@angular/common/http';
import { ApiEndpoint } from '@snap/shared/model';
import { NzSafeAny } from 'ng-zorro-antd/core/types';
import { ok } from './util';

export class ClientEndPt {
  private clientListEnabled = {
    pageInfo: {
      total: 3,
      pageIndex: 1,
      pageSize: 10,
    },
    data: [
      {
        id: '1',
        name: 'John',
        status: 'ENABLED',
        subdomain: 'clientname',
        city: 'LA',
      },
      {
        id: '2',
        name: 'Doe',
        status: 'ENABLED',
        subdomain: 'clientname',
        city: 'LA',
      },
      {
        id: '3',
        name: 'Mike',
        status: 'ENABLED',
        subdomain: 'clientname',
        city: 'LA',
      },
    ],
  };

  private clientListDisabled = {
    pageInfo: {
      total: 2,
      pageIndex: 1,
      pageSize: 10,
    },
    data: [
      {
        id: '1',
        name: 'John1',
        status: 'DISABLED',
        subdomain: 'clientname',
        city: 'LA',
      },
      {
        id: '2',
        name: 'Doe1',
        status: 'DISABLED',
        subdomain: 'clientname',
        city: 'LA',
      },
    ],
  };

  private clientListAll = {
    pageInfo: {
      total: 15,
      pageIndex: 1,
      pageSize: 10,
    },
    data: [
      {
        id: '1',
        name: 'JohnAll',
        status: 'ENABLED',
        subdomain: 'clientname',
        city: 'LA',
      },
      {
        id: '2',
        name: 'DoeAll',
        status: 'ENABLED',
        subdomain: 'clientname',
        city: 'LA',
      },
      {
        id: '3',
        name: 'MikeAll',
        status: 'DISABLED',
        subdomain: 'clientname',
        city: 'LA',
      },
      {
        id: '1',
        name: 'JohnAll',
        status: 'ENABLED',
        subdomain: 'clientname',
        city: 'LA',
      },
      {
        id: '2',
        name: 'DoeAll',
        status: 'ENABLED',
        subdomain: 'clientname',
        city: 'LA',
      },
      {
        id: '3',
        name: 'MikeAll',
        status: 'DISABLED',
        subdomain: 'clientname',
        city: 'LA',
      },
      {
        id: '1',
        name: 'JohnAll',
        status: 'DISABLED',
        subdomain: 'clientname',
        city: 'LA',
      },
      {
        id: '2',
        name: 'DoeAll',
        status: 'ENABLED',
        subdomain: 'clientname',
        city: 'LA',
      },
      {
        id: '3',
        name: 'MikeAll',
        status: 'DISABLED',
        subdomain: 'clientname',
        city: 'LA',
      },
      {
        id: '1',
        name: 'JohnAll',
        status: 'ENABLED',
        subdomain: 'clientname',
        city: 'LA',
      },
      {
        id: '2',
        name: 'DoeAll',
        status: 'ENABLED',
        subdomain: 'clientname',
        city: 'LA',
      },
      {
        id: '3',
        name: 'MikeAll',
        status: 'ENABLED',
        subdomain: 'clientname',
        city: 'LA',
      },
      {
        id: '1',
        name: 'JohnAll',
        status: 'ENABLED',
        subdomain: 'clientname',
        city: 'LA',
      },
      {
        id: '2',
        name: 'DoeAll',
        status: 'ENABLED',
        subdomain: 'clientname',
        city: 'LA',
      },
      {
        id: '3',
        name: 'MikeAll',
        status: 'DISABLED',
        subdomain: 'clientname',
        city: 'LA',
      },
    ],
  };

  private tabCount = {
    data: [
      {
        enabled: 3,
        disabled: 2,
        total: 5,
      },
    ],
  };

  private clientDetail = {
    data: [
      {
        name: 'John Doe',
        subdomain: 'johndoe',
        address: 'Avenue, West',
        zip: '123456',
        city: 'Los Angeles',
        state: 'California',
        country: 'USA',
        contactName: 'John Doe',
        phoneNumber: '1234123412',
        phoneNumberPrefix: '91',
        email: 'john.doe@abc.com',
        taxID: '1234',
        fein: 'subdomain',
        labelerID: '101010',
        uniqueBusinessID: '12348765488',
        waRegistrationNumber: '5678',
      },
    ],
  };

  constructor(
    private apiEndPt: ApiEndpoint,
    private request: HttpRequest<NzSafeAny>,
    private next: HttpHandler
  ) { }

  handleRoute() {
    //const endpoint = this.apiEndPt;
    //const { url, method } = this.request;
    switch (true) {
    /*
      case url.endsWith(stringFormat(endpoint.CLIENT.toString(), 'add')) && method === 'POST':
        return this.addClient();
      case url.endsWith(stringFormat(endpoint.CLIENT.toString(), 'ClientsCountByStatus')) && method === 'GET':
        return this.getTabsCountClient();
      case url.includes(stringFormat(endpoint.CLIENT.toString(), '2')) && method === 'GET':
        return this.getClient();
      case url.endsWith(stringFormat(endpoint.CLIENT.toString(), 'list')) && method === 'POST': {
        return this.getClientList(body.filter.clientStatus);
      }
      case url.includes(stringFormat(endpoint.CLIENT.toString(), 'list')) && method === 'POST': {
        if (url.includes('=')) {
          const filter = url.split('status=');
          return this.getClientList(filter[1]);
        } else {
          return this.getClientList('');
        }
      }
      case url.includes(stringFormat(endpoint.CLIENT.toString(), 'list')) && method === 'GET':
        return this.getClientDetails();
      */
      default:
        return this.next.handle(this.request);
    }
  }

  profile() {
    return ok({
      name: 'John Doe',
      subDomain: 'johndoe',
      address: 'Avenue, West',
      zip: '12345',
      city: 'Los Angeles',
      state: 'California',
      country: 'USA',
      contactName: 'John Doe',
      phoneNumber: '1234123412',
      email: 'john.doe@abc.com',
      taxID: '1234',
      fein: 'subdomain',
      labelerID: '101010',
      uniqueBusinessID: '12348765',
      waRegistrationNumber: '5678',
    });
  }

  save() {
    return ok({
      message: 'save operation successful',
    });
  }

  addClient() {
    return ok({
      message: 'successfully added the client',
    });
  }

  getClient() {
    return ok({
      name: 'sam',
      subdomain: 'client',
      address: 'xyz',
      zip: '123456',
      city: 'aurangabad',
      state: 'newYork',
      country: 'USA',
      contactName: 'abc',
      phoneNumber: '1234567890',
      phoneNumberPrefix: '86',
      email: 'john@gmail.com',
      taxID: '1231231',
      fein: '21312',
      labelerID: '312341234',
      uniqueBusinessID: '21342134',
      waRegistrationNumber: '341234',
    });
  }
  getClientList(filter: string) {
    if (filter === 'ENABLED') {
      return ok(this.clientListEnabled);
    } else if (filter === 'DISABLED') {
      return ok(this.clientListDisabled);
    } else {
      return ok(this.clientListAll);
    }
  }

  getClientDetails() {
    return ok(this.clientDetail);
  }

  getTabsCountClient() {
    return ok(this.tabCount);
  }
}
