import { Component } from '@angular/core';

@Component({
  selector: 'snap-discarded-drug-units',
  templateUrl: './discarded-drug-units.component.html',
  styleUrls: ['./discarded-drug-units.component.less']
})
export class DiscardedDrugUnitsComponent {
  dashboardName = 'Medicare Part B Discarded Drug Units';
}
