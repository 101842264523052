import { HttpRequest, HttpHandler } from '@angular/common/http';
import { ApiEndpoint } from '@snap/shared/model';
import { stringFormat } from '@snap/shared/utils';
import { NzSafeAny } from 'ng-zorro-antd/core/types';
import { fakeStore } from './store';
import { error, ok } from './util';
import { delay, throwError } from 'rxjs';
import { Guid } from 'guid-typescript';

const mockRuleTypes = {
  GENERIC: Guid.parse('9a42a877-1000-4b2d-813f-05eeee829b18'),
  NEW_DRUG_LAUNCH: Guid.parse('e7ce90b1-4e34-4463-8857-dcca8ee4fd3f'),
  ON_DEMAND_FILING: Guid.parse('4f2ad3b1-bd8f-4c23-8571-cc054195b0a9'),
  PERIODIC_FILING: Guid.parse('23ee725a-87c3-47c7-80e1-b36763451439'),
  PRICE_CHANGE: Guid.parse('4ea666e5-d000-4f90-b5b5-64110eb05e25')
};

export class StateEndPt {
  public readonly allForState = {
    messages: [],
    requestId: Guid.create(),
    transactionId: Guid.create(),
    stateData: {
      billDetails: {
        'id': 'e790b5a8-77c4-46e5-a417-1ab2262478d5',
        'name': 'House Bill 4005 (2018)',
        'stateName': 'Oregon',
        'billStatus': 'ACTIVE',
        'presentedInYear': '2018',
        'applicabilityPeriodStartDate': 1520836430,
        'applicabilityPeriodEndDate': 1741761230,
        'description':
          'This rule is derived from drug price transparency law for state of Oregon.',
        'fileName': 'Housebill.pdf',
        'fileUrl':
          'https://snaptransparancy-legislationbill-bucket.s3.amazonaws.com/AL/e790b5a8-77c4-46e5-a417-1ab2262478d5/output.txt?AWSAccessKeyId=ASIA5IVJVYSSTS2WTZC5&Expires=1660757555&x-amz-security-token=IQoJb3JpZ2luX2VjEIn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCICeJRuNDMfBf3F25grHmpKcEKgi2lCWfIDfJSUClBwMGAiB6KQANP16DMqViXImXcdxJMF26YAoc7ZyicdDDq3BdtiqDAwjy%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F8BEAAaDDkxMTk2MjY1Mzg2MSIM3TX07dc1cdkEPE0gKtcCYimkBv7x3r%2Frf4eN0TT7F7Xio3413udW3N7LjLQtwr%2FPzrWJ7XPqR3%2BwNFnMPoicH0v3cHEwvDUtRaP%2Fk3I3f6%2F5%2FphjoMVUQXDFbeAQOHGOyWkcZVbGI6hB5aRwbGA8DhAn%2Fkh%2Fj86KhVShd5fpaguX1juBMnOrznI%2FRq67uDEz7MAjz2qMxS84HySH88t4SZ9M%2FFF0EXDRzjoXcd%2BFGjHkZMJeSXlgjCRymRcGUmpAJb%2FdZU2BfBL18lfsWVH4RZYVqnCKUMvCkHmaKPqV5Mu9Gb6b71L5SrBotB%2BQVtfyJ7ebHVkDQ4DDnXmTynz3F4EzmsAbcgAtKWMHS73x4Re8jDm8%2FR4%2Bm%2B1e58QpDzAVLcvzj4osDnGMmoYa1yuhk52QD6vuPZ%2B%2BY0xHqxBd34I3z0FAvs%2Fan167TFOHBVP98GJJmz3RkpM0BdZCNa6hArHBjSHA8DClsPSXBjqfATpioBVet2yo9wL9L8Vgq4j5cryAtObLixL8YNMesSkhHK3%2FBXwELjojRqwYnRACOFD1bu6lDiumxlo954Y1T0yd4kZVVWmrWcA3ZZ7WYy%2BHjkisMoxiTrPAjbmUTAsJCJCPXBdu1aYY3mEcG1ynH9VoL5ie7Ei7Fb0P8y3s7izCmSE78L88DpelviR5k%2B14Ysi569p2DtLw9OyarBalJQ%3D%3D&Signature=gMNykj2a2e8sbOfIkl%2BZKH7j11c%3D',
        'originalUrl':
          'https://www.oregonlegislature.gov/committees/2019I1-HHC/Reports/HB%204005%20-%20Prescription%20Drug%20Price%20Transparency%20Results%20and%20Recommendations%202019.pdf',
        'createdOn': 1658755877,
        'updatedOn': 0
      },
      rulesList: [
        {
          id: Guid.create(),
          stateCode: 'Oregon',
          ruleTypeId: mockRuleTypes.PRICE_CHANGE,
          ruleName: 'Oregon Price Change Rule',
          description:
            'This rule is derived from drug price transparency law for state of Oregon.',
          applicableStartDate: 1520836430,
          applicableEndDate: 1741761230,
          isActive: true,
          billId: Guid.create(),
          applicabilityCriteriaEnglish:
            'If the drug is BRANDED and Price Increase is greater than $10 and Average Monthly treatment cost is greater than $100',
          penaltyInformation:
            '$2,500 per day maximum for first 30 days and $5,000 per day maximum after 30 days',
          applicabilityCriteriaTimelineCondition:
            'IF Drug.BrandedType = BRANDED AND Drug.DrugPrice.IncreasePercentage > 10 AND Drug.DrugPrice.AverageMonthlyTreatmentDollarValue > $100',
          actions: [
            {
              actionId: Guid.create(),
              multiDrugSubmission: true,
              actionType: 'Filing',
              timeLineCondition: '01-01-2022 ~ 23-03-2022',
              actionConditionEnglishVersion:
                'This is the description that has to be shown to user.',
              uploadProcessFileFormat: 'string',
              uploadProcessHelpText: 'string',
              emailProcessFileFormat: 'string',
              emailProcessHelpText: 'string',

              filingData: [
                {
                  id: Guid.create(),
                  name: 'Field 1',
                  rule: 'WAC',
                  dataType: 'String',
                  summary:
                    'This is the description that has to be shown to user.',
                  isTradeSecret: true,
                  isMandatory: true,
                  possibleDataValues: '1,2,3'
                },
                {
                  id: Guid.create(),
                  name: 'Field 2',
                  rule: 'LabelerId',
                  dataType: 'Number',
                  summary:
                    'This is the description that has to be shown to user.',
                  isTradeSecret: true,
                  isMandatory: true,
                  possibleDataValues: '1,2,3'
                },
                {
                  id: Guid.create(),
                  name: 'Field 3',
                  rule: 'NDC',
                  dataType: 'String',
                  summary:
                    'This is the description that has to be shown to user.',
                  isTradeSecret: true,
                  isMandatory: true,
                  possibleDataValues: '1,2,3'
                },
                {
                  id: Guid.create(),
                  name: 'Field 4',
                  rule: 'CompanyName',
                  dataType: 'String',
                  summary:
                    'This is the description that has to be shown to user.',
                  isTradeSecret: true,
                  isMandatory: true,
                  possibleDataValues: '1,2,3'
                }
              ]
            }
          ]
        }
      ],
      stateDetails: {
        'id': 'OR',
        'name': 'Oregon',
        'ptLawStatus': 'Active',
        'isActive': true,
        'description':
          'This rule is derived from drug price transparency law for state of Oregon.',
        'website': 'https://dfr.oregon.gov',
        'contact': {
          'name': 'Mark Peterson',
          'email': 'DFR.FinancialServicesHelp@oregon.gov',
          'phoneNumber': '503-378-4140',
          'mailingAddress': '350 Winter St. NE Room 410 Salem, OR'
        },
        'filingMechanism': {
          emailAddress: '',
          emailProcessSummary: '',
          onlinePortalUrl:
            'https://dfr.oregon.gov/drugtransparency/Pages/manufacturers.aspx',
          onlineProcessSummary: '',
          uploadPortalUrl: '',
          uploadProcessSummary: '',
          uploadProcessType: null
        }
      }
    }
  };

  constructor(
    private apiEndPt: ApiEndpoint,
    private request: HttpRequest<NzSafeAny>,
    private next: HttpHandler
  ) {}

  handleRoute() {
    const endpoint = this.apiEndPt;
    const { url, method } = this.request;
    switch (true) {
      case url.endsWith(endpoint.AUX.COMPLIANCE_STATS) && method === 'GET':
        return this.next.handle(this.request);
      //case url.endsWith(stringFormat(endpoint.STATE.toString(), 'list')) &&
      //  method === 'GET':
      //  return this.getStates();
      //case url.includes(stringFormat(endpoint.STATE.toString(), '')) &&
      //  method === 'GET':
      //  return this.getState();
      case url.includes(stringFormat(endpoint.STATE.toString(), '')) &&
        method === 'POST':
        // return this.addState();
        return this.next.handle(this.request);
      case url.endsWith(stringFormat(endpoint.STATE.toString(), '')) &&
        method === 'PUT':
        // return this.updateState();
        return this.next.handle(this.request);
      case url.includes(
        stringFormat(endpoint.STATE.toString(), 'fetchAllForState')
      ) && method === 'GET':
        return this.getAllForState();
      //return this.next.handle(this.request);
      default:
        return this.next.handle(this.request);
    }
  }

  getAllForState() {
    return ok(this.allForState).pipe(delay(1000));
  }

  getStates() {
    return ok({
      data: [...fakeStore.StateUsers]
    }).pipe(delay(1000));
  }

  getState() {
    const { url } = this.request;
    const id = Number(url.substring(url.lastIndexOf('/') + 1));
    if (id % 5 == 0) return error('Not Found');
    return ok({
      data: [
        {
          id: id,
          name: 'New York',
          ptLawStatus: 'Active',
          description: 'NEW YORK SNAP LAW',
          contact: {
            name: 'John Doe',
            phoneNumber: '9876543210',
            email: 'john@lun.com',
            mailingAddress: 'ABC AT XYZ 111223'
          },
          filingMechanism: {
            email: 'filing@snap.com',
            portalUrl: 'http://filehere.com',
            filingMethod: 'File Upload'
          }
        }
      ]
    }).pipe(delay(1000));
  }

  addState() {
    const { body } = this.request;
    if (this.request.body.id == 'AL') {
      return throwError(() => ({
        status: 409,
        error: { message: 'Conflict in State Entity' }
      }));
    }

    return ok({
      data: [body]
    }).pipe(delay(1000));
  }

  updateState() {
    return ok({
      message: 'successfully updated the client'
    }).pipe(delay(1000));
  }
}
