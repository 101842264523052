import { NgModule } from '@angular/core';
import { NZ_ICONS, NzIconModule } from 'ng-zorro-antd/icon';
import {
  MenuFoldOutline,
  MenuUnfoldOutline,
  FormOutline,
  DashboardOutline,
  ArrowLeftOutline,
  LockOutline,
  EyeOutline,
  EyeInvisibleOutline,
  UserOutline,
  EyeFill,
  EyeInvisibleFill,
  BellOutline,
  SearchOutline,
  EllipsisOutline,
  SettingOutline,
  LogoutOutline,
  PlusOutline,
  AuditOutline,
  SaveOutline,
  LoginOutline,
  InboxOutline,
  DeleteOutline,
  RollbackOutline,
  MedicineBoxOutline,
  ProfileOutline,
  CaretUpFill,
  CaretDownFill,
  CalculatorOutline,
  CaretRightFill,
  GlobalOutline,
  DollarOutline,
  FileDoneOutline,
  QuestionCircleOutline
} from '@ant-design/icons-angular/icons';
const icons = [
  CaretUpFill,
  CaretDownFill,
  MenuFoldOutline,
  MenuUnfoldOutline,
  DashboardOutline,
  FormOutline,
  ArrowLeftOutline,
  LockOutline,
  EyeOutline,
  EyeInvisibleOutline,
  UserOutline,
  EyeFill,
  EyeInvisibleFill,
  BellOutline,
  SearchOutline,
  EllipsisOutline,
  SettingOutline,
  LogoutOutline,
  PlusOutline,
  AuditOutline,
  SaveOutline,
  LoginOutline,
  InboxOutline,
  DeleteOutline,
  RollbackOutline,
  MedicineBoxOutline,
  ProfileOutline,
  CalculatorOutline,
  CaretRightFill,
  GlobalOutline,
  DollarOutline,
  FileDoneOutline,
  QuestionCircleOutline
];

@NgModule({
  imports: [NzIconModule],
  exports: [NzIconModule],
  providers: [{ provide: NZ_ICONS, useValue: icons }]
})
export class IconsProviderModule {}
